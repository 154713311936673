import type { Api } from '@pflegenavi/shared-frontend/platform';
import { modify } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

export interface MangopayPaymentResolution {
  type: 'upcoming_direct_debit' | 'manual_payment_necessary';
  resolution_date: Date;
}

export enum MangopayPaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
}

export interface MangopayReceiptBatchEntry {
  receipt_batch_entry_id: string;
  status: MangopayPaymentStatus;
  resident_id: string;
  amount: number;
  reason?: string;
  resolution?: MangopayPaymentResolution;
  transfer_datetime?: Date;
}

export interface MangopayReceiptBatchPayment {
  receipt_batch_entries: MangopayReceiptBatchEntry[];
}

export interface GetReceiptBatchPaymentPhoenixResult {
  data: {
    receipt_batch_id: string;
    receipt_batch_payment: MangopayReceiptBatchPayment;
  };
}
export interface Preconditions {
  error: boolean;
  pending_charge_exists: boolean;
  idle: boolean;
  disabled_for_nursing_home: boolean;
  disabled: boolean;
  resident_not_active: boolean;
  stripe_account_deactivated: boolean;
  primary_family_member_missing: boolean;
  direct_debit_missing: boolean;
  amount_too_low: boolean;
  amount_too_high: boolean;
}

export interface PaymentInfo {
  resident_id: string;
  preconditions: Preconditions;
  additional_data: {
    nursing_home_payments_disabled_until: Date | undefined;
    nursing_home_payments_disabled_reason: string | undefined;
  };
  pending_charge:
    | {
        pending_charge_status:
          | 'Initializing'
          | 'DataMissing'
          | 'Pending'
          | 'Success'
          | 'Failed'
          | 'AccountMissing'
          | 'Frozen'
          | 'Upcoming'
          | 'PrimaryFamilyMemberMissing';
        charge_date: Date;
        amount: number;
        currency: 'eur';
        payment_intent_id: string;
        notes: string;
        error: string;
        additional_data: any;
        iban_last_4: string;
        payment_initiation: 'familyMember' | 'system' | 'employee';
        payment_schedule: 'threshold' | 'monthly' | 'manual';
        mangopay_payin_id: string;
        payment_processor: 'mangopay' | 'stripe';
      }
    | undefined;
  payment_settings: {
    payment_initiation: 'system' | 'familyMember' | 'disabled';
    payment_schedule: 'threshold' | 'monthly';
    payment_schedule_day: number;
    payment_schedule_max_amount: number;
    payment_disabled_date: Date;
    source_of_mandate?: 'resident' | 'familyMember' | null;
    direct_debit_status: 'Inactive' | 'Pending' | 'Active';
    iban_last_4: string;
  };
}

export interface PayinRequest {
  params: { resident_id: string };
  body: {
    amount: number;
    note: string;
  };
}

export interface GetAllPaymentInfoResponse {
  data: PaymentInfo[];
}

export interface IPaymentPhoenixApi extends Api {
  getReceiptBatchPaymentPhoenix(dataIn: {
    params: {
      receiptBatchId: string;
    };
  }): Promise<GetReceiptBatchPaymentPhoenixResult>;

  getAllPaymentInfo(dataIn: {
    params: { nursingHomeId: string };
  }): Promise<GetAllPaymentInfoResponse>;

  getPaymentInfo(dataIn: {
    params: { residentId: string };
  }): Promise<PaymentInfo>;

  getPaymentInfoForFamilyMember(dataIn: {
    params: { tenant: string; residentId: string };
  }): Promise<PaymentInfo>;

  createPayin(dataIn: PayinRequest): Promise<void>;
}

export class PaymentPhoenixApi implements IPaymentPhoenixApi {
  baseUrl: string;

  getReceiptBatchPaymentPhoenix: IPaymentPhoenixApi['getReceiptBatchPaymentPhoenix'];
  getPaymentInfo: IPaymentPhoenixApi['getPaymentInfo'];
  getAllPaymentInfo: IPaymentPhoenixApi['getAllPaymentInfo'];
  getPaymentInfoForFamilyMember: IPaymentPhoenixApi['getPaymentInfoForFamilyMember'];
  createPayin: IPaymentPhoenixApi['createPayin'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);
    const familyMemberBaseUrl = getPhoenixApiTenantUrl('family-member', apiUrl);

    this.getReceiptBatchPaymentPhoenix = get({
      authContext,
      url: (params) =>
        `${this.baseUrl}/receipt-batch/${params.receiptBatchId}/payment`,
    });

    this.getPaymentInfo = get({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/payment-info`,
    });

    this.getAllPaymentInfo = get({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/payment-info?nursing_home_id=${params.nursingHomeId}`,
    });

    this.getPaymentInfoForFamilyMember = get({
      authContext,
      url: (params) =>
        `${familyMemberBaseUrl}/${params.tenant}/${params.residentId}/payment-info`,
    });

    this.createPayin = modify({
      authContext,
      url: (params) => {
        return `${this.baseUrl}/resident/${params.resident_id}/payin`;
      },
    });
  }
}
