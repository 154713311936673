import fc from 'fast-check';

export const firstNames = fc.constantFrom(
  'Rosa',
  'Maria',
  'Margarete',
  'Herta',
  'Eberhard',
  'Anna',
  'Terese',
  'Erich',
  'Franz',
  'Leopoldine',
  'Lisa',
  'Franziska',
  'Paul',
  'Ferdinand',
  'Hedwig',
  'Patrick',
  'Josef',
  'Herbert',
  'Heidrun',
  'Hans',
  'Helmut',
  'Edmund'
);
export const lastNames = fc.constantFrom(
  'Weber',
  'Wagner',
  'Szokoll',
  'Steiner',
  'Schneider',
  'Pichler',
  'Prohaska',
  'Schiller',
  'Klimacse',
  'Lackner',
  'Mayer',
  'Moser',
  'Müller',
  'Fischer',
  'Gruber',
  'Hareter',
  'Bauer',
  'Baumgartner',
  'Burgstaller',
  'Fink'
);

export const nursingHomeNames = fc.constantFrom(
  'Caritas St. Lukas',
  'Caritas St. Bernadette',
  'Caritas St. Leopold',
  'Caritas Baden',
  'Caritas Johannes der Täufer',
  'Caritas St. Barbara',
  'Caritas St. Klemens',
  'Caritas Schönbrunn',
  'Caritas St. Elisabeth',
  'Caritas St. Teresa',
  'Caritas St. Martin',
  'Caritas Franz Borgia',
  'Pflegeheim Birkenwiese Dornbirn',
  'Pflegeheim Höchsterstraße Dornbirn',
  'SeneCura Parkresidenz Dornbirn gGmbH',
  'SeneCura Sozialzentrum Hohenems gGmbH',
  'SeneCura Sozialzentrum Herrenried gGmbH',
  'Seniorenhaus Hasenfeld',
  'Seniorenhaus Schützengarten',
  'Sozialzentrum Altach gGmbH'
);

export const city = fc.constantFrom(
  'Wien',
  'Salzburg',
  'Bregenz',
  'Innsbruck',
  'St. Pölten',
  'Eisenstadt',
  'Graz',
  'Klagenfurt',
  'Linz',
  'Berlin',
  'München',
  'Hamburg',
  'Dresden',
  'Leipzig',
  'Stuttgart',
  'Köln',
  'Zürich',
  'Genf',
  'Bern',
  'St. Gallen',
  'Luzern',
  'Basel'
);

export const countryId = fc.constantFrom(
  'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaa040',
  'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaa276',
  'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaa756'
);

export const street = fc.constantFrom(
  ...Array.from(Array(500).keys()).map((k) => `Street ${k + 1}`)
);
