import type Stripe from 'stripe';

export enum StripePayoutState {
  Paid = 'paid',
  Pending = 'pending',
  InTransit = 'in_transit',
  Canceled = 'canceled',
  Failed = 'failed',
}

export interface PayoutDto {
  payoutId: string;
  statementDescriptor: string | null;
  initiationDate: Date;
  transferDate: Date;
  bank: string;
  ibanLast4: string;
  status: StripePayoutState;
  amount: number;
  currency: string | null;
  nursingHomeId: string;
}

export interface GetPayoutsResultEntryDto {
  payoutId: string;
  statementDescriptor: string | null;
  initiationDate: Date;
  transferDate: Date;
  bank: string;
  ibanLast4: string;
  status: StripePayoutState;
  amount: number;
  currency: string | null;
  nursingHomeId: string;
  hasReconciliation: boolean;
}

export interface PayoutReconciliationEntryDto {
  nursingHome: {
    id: string;
    name: string;
  };
  creationDate: Date;
  type: Stripe.BalanceTransaction.Type;
  amount: number;
  currency: string;
  familyMember?: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
  resident?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  payoutId: string;
}

export interface PayoutQueryFilters {
  states?: StripePayoutState[];
}
