import {
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
} from 'class-validator';

export class ManualDepositDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  resident_id!: string;

  @IsOptional()
  @IsNumber({ maxDecimalPlaces: 2 })
  @IsPositive()
  amount?: number;

  @IsOptional()
  @IsInt()
  @IsPositive()
  amountInCent?: number;

  @IsOptional()
  @IsString()
  notes?: string;
}
