import * as fc from 'fast-check';
import type {
  PaymentStateAmountTooHigh,
  PaymentStateAmountTooLow,
  PaymentStateDirectDebitMissing,
  PaymentStateFailed,
  PaymentStateFrozen,
  PaymentStateIdle,
  PaymentStateInitiating,
  PaymentStatePending,
  PaymentStatePrimaryFamilyMemberMissing,
  PaymentStateResidentNotActive,
  PaymentStateStripeAccountDeactivated,
  PaymentStateSuccess,
  PaymentStateUpcoming,
  PaymentStatusResult,
} from '@pflegenavi/shared/api';
import {
  PaymentSchedule,
  PendingChargePaymentStatus,
} from '@pflegenavi/shared/api';
import { genderArb } from './resident';

interface MakePaymentStatusResultArbOpts {
  residentId?: fc.Arbitrary<string>;
  status: fc.Arbitrary<PendingChargePaymentStatus>;
}

export const makePaymentStatusResultArb = (
  opts: MakePaymentStatusResultArbOpts
): fc.Arbitrary<PaymentStatusResult> => {
  // eslint-disable-next-line complexity
  return opts.status.chain<PaymentStatusResult>((status) => {
    const resident = fc.record({
      id: opts.residentId ?? fc.uuid(),
      gender: genderArb,
      firstName: fc.string(),
      lastName: fc.string(),
    });
    const common = {
      nursingHomeId: fc.uuid(),
      familyMemberId: fc.uuid(),
      chargeDate: fc.date(),
      chargeAmount: fc.integer(),
      currency: fc.constant('eur' as const),
      familyMember: fc.record({
        id: fc.uuid(),
        name: fc.string(),
      }),
      resident,
    };
    switch (status) {
      case PendingChargePaymentStatus.Idle:
        return fc.record<PaymentStateIdle>({
          status: fc.constant(status),
          resident,
        });
      case PendingChargePaymentStatus.StripeAccountDeactivated:
        return fc.record<PaymentStateStripeAccountDeactivated>({
          status: fc.constant(status),
          resident,
          info: fc.string(),
          nursingHomeId: fc.uuid(),
          chargeAmount: fc.integer(),
        });

      case PendingChargePaymentStatus.ResidentNotActive:
        return fc.record<PaymentStateResidentNotActive>({
          status: fc.constant(status),
          resident,
        });
      case PendingChargePaymentStatus.PrimaryFamilyMemberMissing:
        return fc.record<PaymentStatePrimaryFamilyMemberMissing>({
          status: fc.constant(status),
          resident,
          chargeAmount: fc.integer(),
        });
      case PendingChargePaymentStatus.DirectDebitMissing:
        return fc.record<PaymentStateDirectDebitMissing>({
          status: fc.constant(status),
          resident,
          info: fc.string(),
          familyMember: fc.record({
            id: fc.uuid(),
          }),
          chargeAmount: fc.integer(),
        });
      case PendingChargePaymentStatus.AmountTooHigh:
        return fc.record<PaymentStateAmountTooHigh>({
          status: fc.constant(status),
          resident,
          chargeAmount: fc.integer(),
          currency: fc.constant('eur'),
          maxAmount: fc.integer({ min: 500, max: 1000 }),
          familyMember: fc.record({
            id: fc.uuid(),
          }),
        });
      case PendingChargePaymentStatus.AmountTooLow:
        return fc.record<PaymentStateAmountTooLow>({
          status: fc.constant(status),
          resident,
          chargeAmount: fc.integer(),
          currency: fc.constant('eur'),
          minAmount: fc.integer({ min: 0, max: 50 }),
          familyMember: fc.record({
            id: fc.uuid(),
          }),
        });
      case PendingChargePaymentStatus.Initiating:
        return fc.record<PaymentStateInitiating>({
          status: fc.constant(status),
          resident,
          nursingHomeId: fc.uuid(),
          familyMember: fc.record({
            id: fc.uuid(),
          }),
          chargeDate: fc.date(),
          chargeAmount: fc.integer(),
          ibanLast4: fc.integer({ min: 1000, max: 9999 }).map((t) => `${t}`),
          schedule: fc.constant(PaymentSchedule.Threshold),
        });
      case PendingChargePaymentStatus.Upcoming:
        return fc.record<PaymentStateUpcoming>({
          status: fc.constant(status),
          ...common,
        });
      case PendingChargePaymentStatus.Pending:
        return fc.record<PaymentStatePending>({
          status: fc.constant(status),
          ...common,
        });
      case PendingChargePaymentStatus.Success:
        return fc.record<PaymentStateSuccess>({
          status: fc.constant(status),
          ...common,
        });
      case PendingChargePaymentStatus.Failed:
        return fc.record<PaymentStateFailed>({
          status: fc.constant(status),
          ...common,
          error: fc.record({
            type: fc.constant('unknown_error'),
          }),
        });
      case PendingChargePaymentStatus.Frozen:
        return fc.record<PaymentStateFrozen>({
          status: fc.constant(status),
          ...common,
          info: fc.string(),
        });
    }
    throw new Error('Value not handled');
  });
};
