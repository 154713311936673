import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { ImageData } from '@pflegenavi/shared/api';
import { endpoints } from '@pflegenavi/shared/api';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getApiBaseUrl,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';

export interface IImageApi extends Api {
  get(imageId: string): Promise<ImageData>;
}

export class ImageApi implements IImageApi {
  baseUrl: string;

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getApiBaseUrl(tenantId, apiUrl);
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }

  async get(imageId: string): Promise<ImageData> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.images}/${endpoints.imagesGet.replace(
        ':imageId',
        imageId
      )}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
    return result.json();
  }
}
