export const transformToStatementDescriptor = (input: string): string => {
  return (
    input
      // Replace all umlats
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/Ä/g, 'Ae')
      .replace(/Ö/g, 'Oe')
      .replace(/Ü/g, 'Ue')
      .replace(/ß/g, 'ss')
      // Replace all special characters
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .substring(0, 22)
  );
};
