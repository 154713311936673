import { createContext } from 'react';
import type { IImageApi } from './api';
import { ImageApi } from './api';
import type { UseQueryResult } from 'react-query';
import type { ImageData } from '@pflegenavi/shared/api';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';

const ApiContext = createContext<IImageApi | undefined>(undefined);
const { useApi: useImageApi, ApiProvider: ImageApiProvider } = makeApiProvider({
  name: 'ImageApi',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => new ImageApi(tenantId, auth, apiUrl),
});

export { useImageApi, ImageApiProvider, ApiContext as ImageApiContext };
export type { IImageApi } from './api';

const IMAGE_KEY = (imageId: string | undefined) => ['image', imageId];

export const useImage = (
  imageId: string | undefined
): UseQueryResult<ImageData> => {
  return useApiQuery(
    // eslint-disable-next-line @grncdr/react-hooks/rules-of-hooks
    () => useImageApi(),
    IMAGE_KEY(imageId),
    async (api) => {
      if (!imageId) {
        throw new Error('No imageId provided');
      }
      return await api.get(imageId);
    },
    {
      enabled: imageId !== undefined,
    }
  );
};
