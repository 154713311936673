import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  defaultHeaders,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';

export interface IReceiptPhoenixApi extends Api {
  submit(dataIn: { body: { receipt_ids: string[] } }): Promise<{
    data: Array<{
      id: string;
      error?: number;
      message?: string;
    }>;
  }>;
  cancel(dataIn: {
    body: { data: { note: string } };
    params: { receipt_id: string };
  }): Promise<void>;
}

export class ReceiptPhoenixApi implements IReceiptPhoenixApi {
  baseUrl: string;

  submit: IReceiptPhoenixApi['submit'];
  cancel: IReceiptPhoenixApi['cancel'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.submit = modify({
      url: `${this.baseUrl}/receipt/submit`,
      authContext,
      method: 'POST',
      headers: defaultHeaders(),
    });

    this.cancel = modify({
      url: (params) => `${this.baseUrl}/receipt/${params.receipt_id}/cancel`,
      authContext,
      method: 'POST',
      headers: defaultHeaders(),
    });
  }
}
