import { addDays, differenceInDays } from 'date-fns';
import { useMemo } from 'react';

interface UseUpcomingPaymentDaysProps {
  chargeDate: Date | undefined;
}

interface UseUpcomingPaymentDaysResult {
  daysLeftToPayment: number;
}

export const useUpcomingPaymentDays = ({
  chargeDate,
}: UseUpcomingPaymentDaysProps): UseUpcomingPaymentDaysResult => {
  const daysLeftToPayment = useMemo(
    () =>
      chargeDate
        ? differenceInDays(addDays(new Date(chargeDate), 1), new Date())
        : 0,
    [chargeDate]
  );

  return {
    daysLeftToPayment,
  };
};
