export enum DirectDebitStatus {
  Inactive = 'Inactive',
  Pending = 'Pending',
  Active = 'Active',
}

export interface DirectDebitStatusDto {
  status: DirectDebitStatus;
  paymentMethod?: PaymentMethodDto;
}

export enum DirectDebitPaymentStatus {
  Pending = 'Pending',
  Error = 'Error',
  Success = 'Success',
}

export interface PaymentMethodDto {
  country: string;
  last4: string;
  email: string;
  name: string;
}
