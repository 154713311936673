import {
  IsArray,
  IsDate,
  IsInt,
  IsNotEmpty,
  IsOptional,
  Max,
  Min,
} from 'class-validator';
import { Type } from 'class-transformer';

interface Metadata {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  totalPages: number;
}

// TODO: This should move to a separate package, while PaginatedResultSet should be hidden in a middleware package.
export interface PaginatedResultSubset<
  T,
  M extends Record<string, any> = Record<string, any>
> {
  data: T[];

  meta: Metadata & M;

  links?: {
    first: string;
    previous: string | null;
    current: string;
    next: string | null;
    last: string | null;
  };
}

export type PaginatedResultEntryType<T> = T extends PaginatedResultSubset<
  infer U,
  any
>
  ? U
  : never;

export class PaginatedResultSet<
  T,
  M extends Record<string, any> = Record<string, any>
> implements PaginatedResultSubset<T, M>
{
  constructor(
    data: T[],
    totalItems: number,
    pageSize: number,
    page: number,
    currentLink?: string,
    metadata?: M
  ) {
    this.data = data;
    this.meta = {
      itemsPerPage: pageSize,
      totalItems: totalItems,
      currentPage: page,
      totalPages: Math.max(Math.ceil(totalItems / pageSize), 1),
      ...(metadata as M),
    };
    if (currentLink) {
      this.computeAndSetLinks(currentLink);
    }
  }

  @IsArray()
  data: T[];

  meta: Metadata & M;

  links?: {
    first: string;
    previous: string | null;
    current: string;
    next: string | null;
    last: string | null;
  };

  computeAndSetLinks(currentLink: string): void {
    const pageParamRegex = /page=([^&]*)/;
    this.links = {
      current: currentLink,
      previous:
        this.meta?.currentPage > 0
          ? currentLink.replace(
              pageParamRegex,
              `page=${this.meta.currentPage - 1}`
            )
          : null,
      next:
        this.meta?.currentPage < this.meta?.totalPages - 1
          ? currentLink.replace(
              pageParamRegex,
              `page=${this.meta.currentPage + 1}`
            )
          : null,
      first: currentLink.replace(pageParamRegex, `page=0`),
      last: this.meta?.totalPages
        ? currentLink.replace(
            pageParamRegex,
            `page=${this.meta.totalPages - 1}`
          )
        : null,
    };
  }
}

export interface IPaginatedQuery {
  /** The page number, starting at 0 */
  page: number;
  /** The number of items per page, should at least be 1 */
  pageSize: number;
}

export class PaginatedQuery implements IPaginatedQuery {
  @IsInt()
  @IsNotEmpty()
  @Min(0)
  page!: number;

  @IsInt()
  @IsNotEmpty()
  @Min(1)
  @Max(50)
  pageSize!: number;
}

export interface SortableQuery {
  sort?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface RangeFilter<T extends number | Date> {
  gte?: T;
  gt?: T;
  lte?: T;
  lt?: T;
  eq?: T;
}

export class RangeIntFilter<T extends number> implements RangeFilter<T> {
  @IsOptional()
  @IsInt()
  @Type(() => Number)
  gte?: T;

  @IsOptional()
  @IsInt()
  @Type(() => Number)
  gt?: T;

  @IsOptional()
  @IsInt()
  @Type(() => Number)
  lte?: T;

  @IsOptional()
  @IsInt()
  @Type(() => Number)
  lt?: T;

  @IsOptional()
  @IsInt()
  @Type(() => Number)
  eq?: T;
}

export class RangeDateFilter<T extends Date> implements RangeFilter<T> {
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  gte?: T;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  gt?: T;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  lte?: T;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  lt?: T;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  eq?: T;
}
