import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getApiBaseUrl,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type {
  FamilyMemberProfileDto,
  FamilyMemberWithIdDto,
  UpdateFamilyMemberDto,
  UpdateUserProfileOptionalInfoDto,
  EmployeeProfileDto,
} from '@pflegenavi/shared/api';
import { endpoints } from '@pflegenavi/shared/api';

export interface IUserProfileApi extends Api {
  get(): Promise<FamilyMemberProfileDto>;

  getEmployeeProfile(): Promise<EmployeeProfileDto>;

  updateUser(data: UpdateUserProfileOptionalInfoDto): Promise<{ id: string }>;

  updateByEmployee(data: UpdateFamilyMemberDto): Promise<FamilyMemberWithIdDto>;

  test404Get(): Promise<void>;
  test500Get(): Promise<void>;
  test400Post(): Promise<void>;
}

export class UserProfileApi implements IUserProfileApi {
  baseUrl: string;

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getApiBaseUrl(tenantId, apiUrl);
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }

  async get(): Promise<FamilyMemberProfileDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.userProfile}/${endpoints.userProfileGet}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
    return result.json();
  }

  async getEmployeeProfile(): Promise<EmployeeProfileDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.userProfileEmployee}/${endpoints.userProfileEmployeeGet}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
    return result.json();
  }

  async updateUser(
    data: UpdateUserProfileOptionalInfoDto
  ): Promise<{ id: string }> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/userProfile/profile`,
      {
        method: 'PUT',
        headers: this.headers,
        body: JSON.stringify(data),
      }
    );
    return result.json();
  }

  async updateByEmployee(
    data: UpdateFamilyMemberDto
  ): Promise<FamilyMemberWithIdDto> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/userProfile/family-member-by-employee`,
      {
        method: 'PUT',
        headers: this.headers,
        body: JSON.stringify(data),
      }
    );
    return result.json();
  }

  // TODO: move to the separate package
  async test404Get(): Promise<void> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/testing-not-existing-point`,
      {
        method: 'GET',
      }
    );
    return result.json();
  }

  // TODO: move to the separate package
  async test500Get(): Promise<void> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/userProfile/test-throw-500`,
      {
        method: 'GET',
      }
    );
    return result.json();
  }

  async test400Post(): Promise<void> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.userProfile}/${endpoints.userProfilePostTestThrow400}`,
      {
        method: 'POST',
      }
    );
    return result.json();
  }
}
