export const scrollIntoViewIfNecessary = (element: Element): void => {
  const elementRect = element.getBoundingClientRect();

  const parentRect = element.parentElement?.getBoundingClientRect();

  if (!parentRect) {
    return;
  }

  if (elementRect.top <= parentRect.top) {
    element.scrollIntoView({ behavior: 'smooth' });
  } else if (elementRect.bottom >= parentRect.bottom) {
    element.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  }
};
