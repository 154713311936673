// eslint-disable-next-line max-classes-per-file
import {
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import type { ResidentState } from '../resident';
import { RangeDateFilter } from '@pflegenavi/shared/utils';

export class GetReportingDto {
  @IsOptional()
  @ValidateNested()
  dateRange?: RangeDateFilter<Date>;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;
}

export class GetSummaryReportDto {
  @IsOptional()
  @ValidateNested()
  dateRange?: RangeDateFilter<Date>;

  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  nursingHomeId!: string;
}

class ReportingResidentDto {
  id?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  state?: ResidentState;
  notes?: string;
}

export interface ReportingRowDto {
  resident: ReportingResidentDto;
  /** @deprecated use the cent value instead */
  amountBeginOfPeriod: number;
  amountBeginOfPeriodInCents: number;
  /** @deprecated use the cent value instead */
  amountEndOfPeriod: number;
  amountEndOfPeriodInCents: number;
  countWithdrawals: number;
  countDeposits: number;
  lowBalance: boolean;
}

export interface ReportingTotal {
  /** @deprecated use the cent value instead */
  balance: number;
  balanceInCents: number;
  /** @deprecated use the cent value instead */
  lastBalance: number;
  lastBalanceInCents: number;
  /** @deprecated use the cent value instead */
  withdrawal: number;
  withdrawalInCents: number;
  /** @deprecated use the cent value instead */
  lastWithdrawal: number;
  lastWithdrawalInCents: number;
  /** @deprecated use the cent value instead */
  deposit: number;
  depositInCents: number;
  /** @deprecated use the cent value instead */
  lastDeposit: number;
  lastDepositInCents: number;
}

export interface AllResidentBalanceDto {
  balanceInCents: number;
}

export interface SummaryReportDto {
  balanceInCents: number;
  withdrawalInCents: number;
  depositInCents: number;
}

export class ReportingDto {
  residentData!: ReportingRowDto[];
}

export class GetMonthlyReportingDto {
  @IsNotEmpty()
  @IsDate()
  date!: Date;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;
}

export class MonthlyReportingDto {
  residentData!: ReportingRowDto[];
  totalAmount!: ReportingTotal;
}
