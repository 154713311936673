// eslint-disable-next-line max-classes-per-file
import type { FeatureFlag } from '../feature-flags';
import type { NursingHomeDto } from '../nursing-home';
import {
  IsEmail,
  IsLocale,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export interface NursingHomeProfileDto extends NursingHomeDto {
  isSupervisor: boolean;

  // Feature Flags set on the nursing home valid for all employees
  featureFlags?: FeatureFlag[];
}

export class UpdateUserProfileDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;
  @IsNotEmpty()
  @IsString()
  firstName!: string;
  @IsNotEmpty()
  @IsString()
  lastName!: string;
  @IsNotEmpty()
  @IsEmail()
  eMail!: string;
  @IsOptional()
  @IsLocale()
  locale?: string;
}

export class UpdateUserProfileOptionalInfoDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  @IsOptional()
  @IsEmail()
  eMail?: string;

  @IsOptional()
  @IsLocale()
  locale?: string;
}

export interface UserProfileInterface {
  featureFlags: FeatureFlag[];
  user: {
    id: string;
    firstName: string;
    lastName: string;
    eMail: string;
    locale: string | undefined;
    createdAt: Date | undefined;
  };
}

/** @deprecated use EmployeeProfileDto or FamilyMemberProfileDto */
export class UserProfileDto implements UserProfileInterface {
  featureFlags!: FeatureFlag[];
  nursingHome!: NursingHomeProfileDto | undefined;
  user!: {
    id: string;
    firstName: string;
    lastName: string;
    eMail: string;
    locale: string | undefined;
    createdAt: Date | undefined;
  };
}

export class EmployeeProfileDto implements UserProfileInterface {
  featureFlags!: FeatureFlag[];
  nursingHomes!: NursingHomeProfileDto[];
  user!: {
    id: string;
    firstName: string;
    lastName: string;
    eMail: string;
    locale: string | undefined;
    createdAt: Date | undefined;
  };
}

export class EmployeeProfileWithoutNursingHomeDto
  implements UserProfileInterface
{
  featureFlags!: FeatureFlag[];
  user!: {
    id: string;
    firstName: string;
    lastName: string;
    eMail: string;
    locale: string | undefined;
    createdAt: Date | undefined;
  };
}

export class FamilyMemberProfileDto implements UserProfileInterface {
  featureFlags!: FeatureFlag[];
  user!: {
    id: string;
    firstName: string;
    lastName: string;
    eMail: string;
    locale: string | undefined;
    createdAt: Date | undefined;
  };
}
