import { IsNotEmpty, IsNumber } from 'class-validator';

export class Coin {
  @IsNotEmpty()
  @IsNumber()
  factor!: number;

  @IsNotEmpty()
  @IsNumber()
  amount!: number;
}

export class GetCashManagementDto {
  cash_list_id!: string;
  /** @deprecated Use totalInCents instead */
  total!: number;
  totalInCents!: number;
  coins!: Coin[];
  bankAccountAmount!: number;
  currency!: 'euro' | 'dollar';
}

export class GetCashManagementWithTransactionDto extends GetCashManagementDto {
  cashTransactionId!: string;
  cashTransactionGroupId!: string;
}
