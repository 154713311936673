import { useTranslation } from 'react-i18next';

interface UsePasswordValidationReturn {
  criteria: Array<[string, boolean]>;
}

export const usePasswordValidation = (
  password: string,
  passwordConfirmation: string
): UsePasswordValidationReturn => {
  const { t } = useTranslation();

  const errors: Record<string, boolean> = {
    [t('authentication.errors.password-length')]: password.length >= 8,
    [t('authentication.errors.password-uppercase')]: /[A-Z]/.test(password),
    [t('authentication.errors.password-lowercase')]: /[a-z]/.test(password),
    [t('authentication.errors.password-digit')]: /[0-9]/.test(password),
    [t('authentication.errors.password-mismatch')]:
      password.length > 0 && password === passwordConfirmation,
  };

  return {
    criteria: Object.entries(errors),
  };
};
