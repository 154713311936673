import fc from 'fast-check';

export { makeReceiptArb } from './receipt';
export { makeCreateResidentDtoArb, genderArb } from './resident';
export { makeCreateResidentFamilyMemberArb } from './familyMember';
export { makePaymentStatusResultArb } from './payments';
export { makeNursingHomeArb } from './nursingHome';
export { makeEmployeeArb } from './employee';
export { makePayoutArb } from './payouts';
export {
  receiptTypeArb,
  receiptSourceTypeArb,
  makeTransactionWithReceiptArb,
  depositSourceTypeArb,
} from './transactions';
export { makeResidentListItemDtoArb } from './ResidentListItemDtoArb';

interface GetOneOpts {
  seed?: number;
}
export const arbGetOne = <T>(arb: fc.Arbitrary<T>, opts?: GetOneOpts): T => {
  return fc.sample(arb, {
    numRuns: 1,
    ...opts,
  })[0];
};
