import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

export interface RecurringItemsChargeParams {
  params: {
    residentId: string;
  };
  body: {
    data: Array<{
      recurring_item_id: string;
      charge_full_period: boolean;
    }>;
  };
}

export interface ISettlementPhoenixApi extends Api {
  recurringItemsCharge(dataIn: RecurringItemsChargeParams): Promise<void>;
}

export class SettlementPhoenixApi implements ISettlementPhoenixApi {
  baseUrl: string;

  recurringItemsCharge: ISettlementPhoenixApi['recurringItemsCharge'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.recurringItemsCharge = modify({
      authContext,
      url: ({ residentId }) =>
        `${this.baseUrl}/settlement/${residentId}/recurring_items_charge`,
      method: 'PUT',
    });
  }
}
