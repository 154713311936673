import { MAX_SERVICE_PROVIDER_STRIPE_PAYMENT_CENT_AMOUNT } from './payment-constants';

export const MAX_BANK_ACCOUNT_CHANGE = 9999999;
export const MAX_TRANSACTION_AMOUNT = 99999.99;
export const MAX_TRANSACTION_AMOUNT_IN_CENTS = Math.round(
  MAX_TRANSACTION_AMOUNT * 100
);

export const MAX_RECEIPT_BATCH_AMOUNT_IN_CENTS =
  MAX_SERVICE_PROVIDER_STRIPE_PAYMENT_CENT_AMOUNT;

export const MAX_ENTRY_TOTAL_AMOUNT_FOR_RECEIPT_GENERATION_IN_CENTS = 40000;
