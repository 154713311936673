import {
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsUUID,
  Min,
} from 'class-validator';

export class PendingTransferReversalDto {
  @IsNotEmpty()
  @IsNumber()
  @Min(0)
  amount!: number;
  @IsNotEmpty()
  @IsDate()
  createdOn!: Date;
  @IsNotEmpty()
  @IsUUID(4)
  @IsString()
  disputeTransactionId!: string;
  @IsNotEmpty()
  @IsUUID(4)
  @IsString()
  nursingHomeId!: string;
  @IsNotEmpty()
  @IsUUID()
  @IsString()
  residentId!: string;
  @IsNotEmpty()
  @IsString()
  stripeTransferId!: string; // This is an id from stripe, not from our DB.
}
