/* eslint-disable class-methods-use-this,@typescript-eslint/no-unused-vars */
import type { ApiError, IPaymentApi } from './api';
import type {
  DirectDebitStatusDto,
  FamilyMemberInitiatedPaymentDto,
  GetPayoutsResultEntryDto,
  MoneyFlowType,
  PaymentDashboardStatisticsDto,
  PaymentDto,
  PaymentInfo,
  PaymentInitiationDto,
  PaymentMoneyFlowEntry,
  PaymentStatusResult,
  PayoutReconciliationEntryDto,
  PendingCharge,
  PendingChargePaymentStatus,
  PendingTransferReversalDto,
  ReceiptBatchPaymentStatus,
  ResidentBalanceGraphDatapointDto,
  ResidentBalanceInfo,
  ServiceProviderPayment,
  ServiceProviderPaymentListQuery,
  SetupIntentPayload,
  StripeAccount,
} from '@pflegenavi/shared/api';
import {
  DirectDebitStatus,
  StripePaymentMethodStatus,
} from '@pflegenavi/shared/api';
import * as fc from 'fast-check';
import { arbGetOne, makePaymentStatusResultArb } from '@pflegenavi/arbitraries';
import type {
  PaginatedResultSet,
  RangeDateFilter,
} from '@pflegenavi/shared/utils';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

type ResidentId = string;
type FamilyMemberId = string;

export class TestPaymentApi implements IPaymentApi {
  payments: Map<ResidentId, PendingCharge> = new Map();
  directDebit: Map<ResidentId, Map<FamilyMemberId, DirectDebitStatusDto>> =
    new Map();
  paymentStatus: Map<ResidentId, PaymentStatusResult> = new Map();

  constructor(public authContext: AuthenticationContext) {}

  initialize(
    residentId: ResidentId,
    paymentStatus: PendingChargePaymentStatus
  ): void {
    if (!this.testGetPaymentStatus(residentId)) {
      this.setPaymentStatus(residentId, paymentStatus);
    }
  }

  testGetPaymentStatus(
    residentId: ResidentId
  ): PendingChargePaymentStatus | undefined {
    return this.paymentStatus.get(residentId)?.status;
  }

  setPaymentStatus(
    residentId: ResidentId,
    paymentStatus: PendingChargePaymentStatus
  ): void {
    this.paymentStatus.set(
      residentId,
      arbGetOne(
        makePaymentStatusResultArb({
          status: fc.constant(paymentStatus),
          residentId: fc.constant(residentId),
        })
      )
    );
  }

  deleteDirectDebitMandate(residentId: string): Promise<void> {
    this.directDebit.delete(residentId);
    return Promise.resolve();
  }

  deleteDirectDebitMandateForNursingHome(nursingHomeId: string): Promise<void> {
    this.directDebit.delete(nursingHomeId);
    return Promise.resolve();
  }

  deletePendingChargeByResidentId(
    residentId: string,
    notes: string
  ): Promise<void> {
    this.payments.delete(residentId);
    return Promise.resolve();
  }

  getPaymentInfoForResidentFamilyMember(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<PaymentInfo> {
    return Promise.resolve({} as PaymentInfo);
  }

  getPaymentInfos(): Promise<PaymentInfo[]> {
    return Promise.resolve([]);
  }

  getPaymentInfoByResidentId(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<PaymentInfo> {
    return Promise.resolve({} as PaymentInfo);
  }

  getDirectDebitStatusForNursingHome(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<DirectDebitStatusDto> {
    return Promise.resolve({} as DirectDebitStatusDto);
  }

  getPaymentInfoByResidentIdAsFamilyMember(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<PaymentInfo> {
    return Promise.resolve({} as PaymentInfo);
  }

  getStripeAccountDetails(): Promise<StripeAccount> {
    return Promise.resolve({} as StripeAccount);
  }

  createStripeAccountForNursingHome(): Promise<{
    accountId: string;
  }> {
    return Promise.resolve({} as { accountId: string });
  }

  setup(dataIn: { body: undefined }): Promise<SetupIntentPayload | ApiError> {
    return Promise.resolve({} as SetupIntentPayload);
  }

  setupConfirm(dataIn: { body: undefined }): Promise<{
    success: boolean;
  }> {
    return Promise.resolve({ success: false });
  }

  setupConfirmPaymentIntent(dataIn: {
    body: {
      paymentIntentId: string;
    };
    params: {
      residentId: string;
    };
  }): Promise<{
    success: boolean;
  }> {
    return Promise.resolve({ success: false });
  }

  getAccountingDashboard(): Promise<PaymentDashboardStatisticsDto> {
    return Promise.resolve({} as PaymentDashboardStatisticsDto);
  }

  getAccountingResidentBalanceGraph(): Promise<
    ResidentBalanceGraphDatapointDto[]
  > {
    return Promise.resolve([] as ResidentBalanceGraphDatapointDto[]);
  }

  getAccountingPayouts(): Promise<
    PaginatedResultSet<GetPayoutsResultEntryDto>
  > {
    return Promise.resolve({} as PaginatedResultSet<GetPayoutsResultEntryDto>);
  }

  getAccountingPayments(): Promise<PaginatedResultSet<PaymentDto>> {
    return Promise.resolve({} as PaginatedResultSet<PaymentDto>);
  }

  getPayoutReconciliation(dataIn: {
    params: {
      payoutId: string;
    };
  }): Promise<PayoutReconciliationEntryDto[]> {
    return Promise.resolve([] as PayoutReconciliationEntryDto[]);
  }

  getMoneyFlow(dataIn: {
    params: {
      nursingHomeIds: string[];
      dateRange?: RangeDateFilter<Date>;
      type?: MoneyFlowType;
    };
  }): Promise<PaymentMoneyFlowEntry[]> {
    return Promise.resolve([] as PaymentMoneyFlowEntry[]);
  }

  getTransfer(): Promise<number> {
    return Promise.resolve(0);
  }

  submitDirectDebitManualPayment(
    residentId: string,
    payment: FamilyMemberInitiatedPaymentDto
  ): Promise<SetupIntentPayload> {
    return Promise.resolve({
      intentId: '',
      paymentIntent: '',
      ephemeralKey: '',
      customer: '',
      publishableKey: '',
    });
  }

  setPaymentInitiation(dataIn: { body: PaymentInitiationDto }): Promise<{
    success: boolean;
  }> {
    return Promise.resolve({ success: true });
  }

  getResidentBalanceInfo(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<ResidentBalanceInfo> {
    return Promise.resolve({} as ResidentBalanceInfo);
  }

  getPendingReversals(): Promise<PendingTransferReversalDto[]> {
    return Promise.resolve([
      {
        amount: 4,
        createdOn: new Date(),
        disputeTransactionId: '',
        nursingHomeId: '',
        residentId: '',
        stripeTransferId: '',
      },
    ]);
  }

  setupConfirmNursingHome(dataIn: {
    body: undefined;
    params: {
      nursingHomeId: string;
    };
  }): Promise<{
    success: boolean;
  }> {
    return Promise.resolve({ success: false });
  }

  setupDirectDebitForNursingHome(
    nursingHomeId: string,
    body: {
      email: string;
      name: string;
      iban: string;
    }
  ): Promise<SetupIntentPayload | ApiError> {
    return Promise.reject('Not implemented');
  }

  initiateServiceProviderPayment(dataIn: {
    body: undefined;
    params: {
      receiptBatchId: string;
    };
  }): Promise<ServiceProviderPayment> {
    return Promise.reject('Not implemented');
  }

  getServiceProviderPaymentForReceiptBatch(dataIn: {
    params: {
      receiptBatchId: string;
    };
  }): Promise<ServiceProviderPayment> {
    return Promise.reject('Not implemented');
  }

  getReceiptBatchPaymentStatus(dataIn: {
    params: {
      receiptBatchId: string;
    };
  }): Promise<ReceiptBatchPaymentStatus> {
    return Promise.reject('Not implemented');
  }

  getServiceProviderPayments(dataIn: {
    params: ServiceProviderPaymentListQuery;
  }): Promise<PaginatedResultSet<ServiceProviderPayment>> {
    return Promise.reject('Not implemented');
  }
}
