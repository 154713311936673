export interface StripeAccount {
  url?: string;
  currentlyDue: boolean;
  dueDate?: Date;
  onboardingStarted: boolean;
  accountCreated: boolean;
  status: StripePaymentMethodStatus;
}

export enum StripePaymentMethodStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
}
