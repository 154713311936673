import type { ObjectShape } from 'yup/lib/object';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PHONE_NUMBER_REGEX } from '@pflegenavi/shared/constants';

const notOnlyNumbersRegExp = /[^0-9]+/;
const zipCodeRegExp = /^[0-9]{4,5}$/;

interface UseFamilyMemberValidationSchemaProps {
  requireMangopayFields: boolean;
}

export const useFamilyMemberValidationSchema = (
  props: UseFamilyMemberValidationSchemaProps
): ObjectShape => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      firstName: Yup.string()
        .min(2, t('field.min-length', { length: 2 }))
        .max(255, t('field.max-length', { length: 255 }))
        .required(t('field.required.first-name')),
      lastName: Yup.string()
        .min(2, t('field.min-length', { length: 2 }))
        .max(255, t('field.max-length', { length: 255 }))
        .required(t('field.required.last-name')),
      eMail: Yup.string()
        .email(t('field.valid-email'))
        .required(t('field.required.email')),
      phone: Yup.string().matches(
        PHONE_NUMBER_REGEX,
        t('form.validation.phone-not-valid')
      ),
      address: Yup.string().matches(
        notOnlyNumbersRegExp,
        t('form.validation.please-enter-valid-address')
      ),
      city: Yup.string().matches(
        notOnlyNumbersRegExp,
        t('form.validation.please-enter-valid-city')
      ),
      zipCode: Yup.string().matches(
        zipCodeRegExp,
        t('form.validation.must-be-exactly-4-or-5-digits')
      ),
      country: Yup.string().matches(
        notOnlyNumbersRegExp,
        t('form.validation.please-enter-valid-country')
      ),
      notes: Yup.string().nullable(),
      ...(props.requireMangopayFields
        ? {
            relationshipToResident: Yup.string().required(
              t('field.required.relationship-to-resident')
            ),
          }
        : {}),
    }),
    [t, props.requireMangopayFields]
  );
};
