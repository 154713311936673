import type { ObjectShape } from 'yup/lib/object';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as ibantools from 'ibantools';

const nameWith2WordsRegex = /^\s*[\S]+(\s[\S]+)+\s*$/gms;
const nameWithOnlyLatinLettersRegex =
  /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
export const useSetupDirectDebitValidationSchema = (): ObjectShape => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      name: Yup.string()
        .required(t('field.required.full-name'))
        .matches(nameWith2WordsRegex, t('field.valid.full-name-short'))
        .matches(
          nameWithOnlyLatinLettersRegex,
          t('field.valid.name-with-only-latin-letters')
        ),
      email: Yup.string()
        .email(t('field.valid.email'))
        .required(t('field.required.email')),
      iban: Yup.string()
        .required(t('field.required.iban'))
        .test({
          name: 'check-iban',
          test: (rawIban, context) => {
            const iban = rawIban ?? '';
            const isValidIban =
              ibantools.isValidIBAN(iban) ||
              ibantools.isValidIBAN(ibantools.electronicFormatIBAN(iban) ?? '');
            return (
              isValidIban ||
              context.createError({
                message: t('form.validation.iban-not-valid'),
              })
            );
          },
        }),
    }),
    [t]
  );
};
