import type { i18n } from 'i18next';
import { createInstance, default as defaultI18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  deTranslations,
  enTranslations,
} from '@pflegenavi/frontend/translations';
import type { LocaleStorage } from '../LocaleStorage';
// ----------------------------------------------------------------------

export const initI18N = async (localStorage: LocaleStorage): Promise<void> => {
  const language = await localStorage.getLanguage();
  await initI18NWithLanguage(language || 'de');
};

export const initI18NForTest = async (): Promise<void> => {
  await defaultI18n.use(LanguageDetector).use(initReactI18next).init();
};

export const initI18NWithLanguage = async (language: string): Promise<void> => {
  await defaultI18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: enTranslations },
        de: { translation: deTranslations },
      },
      lng: language,
      fallbackLng: 'de',
      debug: false,
      ns: ['translation'],
      defaultNS: 'translation',
      interpolation: {
        escapeValue: false,
      },
      compatibilityJSON: 'v3',
    });
};

export const initI18NForProvider = (language: string): i18n => {
  const i18n = createInstance({
    resources: {
      en: { translation: enTranslations },
      de: { translation: deTranslations },
    },
    lng: language || 'de',
    fallbackLng: 'de',
    debug: true,
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

  i18n.use(initReactI18next);

  i18n.init({
    initImmediate: false,
  });

  return i18n;
};
