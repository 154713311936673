export function calculateImagePlacement(
  img: { width: number; height: number },
  maxWidth: number,
  maxHeight: number,
  borderTop: number,
  borderBottom: number,
  borderRight: number
): { x: number; y: number; width: number; height: number } {
  const imgRatio = img.width / img.height;
  let newWidth = maxWidth - borderRight;
  let newHeight = maxHeight - borderTop - borderBottom;

  // Adjust width and height while maintaining aspect ratio
  if (imgRatio > 1) {
    newHeight = (maxWidth - borderRight) / imgRatio;
    if (newHeight > maxHeight - borderTop - borderBottom) {
      newHeight = maxHeight - borderTop - borderBottom;
      newWidth = newHeight * imgRatio;
    }
  } else {
    newWidth = (maxHeight - borderTop - borderBottom) * imgRatio;
    if (newWidth > maxWidth - borderRight) {
      newWidth = maxWidth - borderRight;
      newHeight = newWidth / imgRatio;
    }
  }

  // Calculate x and y positions
  const x = maxWidth - newWidth - borderRight; // Align right with border
  const y = borderTop + (maxHeight - borderTop - borderBottom - newHeight) / 2; // Center vertically with borders

  return { x, y, width: newWidth, height: newHeight };
}
