import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import { get, getApiBaseUrl } from '@pflegenavi/shared-frontend/platform';
import type {
  AllResidentBalanceDto,
  ReportingDto,
  SummaryReportDto,
} from '@pflegenavi/shared/api';
import { endpoints } from '@pflegenavi/shared/api';
import type { RangeDateFilter } from '@pflegenavi/shared/utils';

export interface IReportingApi extends Api {
  getAllResidentBalance(dataIn: {
    params: { nursingHomeId: string };
  }): Promise<AllResidentBalanceDto>;
  getBalanceReport(dataIn: {
    params: { nursingHomeId: string; dateRange: RangeDateFilter<Date> };
  }): Promise<ReportingDto>;
  getBalanceSummaryReport(dataIn: {
    params: { nursingHomeId: string; dateRange: RangeDateFilter<Date> };
  }): Promise<SummaryReportDto>;
}

export class ReportingApi implements IReportingApi {
  getAllResidentBalance: IReportingApi['getAllResidentBalance'];
  getBalanceReport: IReportingApi['getBalanceReport'];
  getBalanceSummaryReport: IReportingApi['getBalanceSummaryReport'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getApiBaseUrl(tenantId, apiUrl);

    this.getAllResidentBalance = get({
      authContext,
      url: ({ nursingHomeId }) => {
        return `${baseUrl}/${endpoints.analytics}/${endpoints.analyticsGetAllResidentBalance}?nursingHomeId=${nursingHomeId}`;
      },
    });

    this.getBalanceReport = get({
      authContext,
      url: ({ nursingHomeId, dateRange }) => {
        const queryParams = new URLSearchParams();

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const dateField = dateRange?.[field];
          if (dateField) {
            queryParams.append(`dateRange[${field}]`, dateField.toISOString());
          }
        });

        queryParams.append('nursingHomeId', nursingHomeId);

        return `${baseUrl}/${endpoints.analytics}/${
          endpoints.analyticsGetBalanceReport
        }?${queryParams.toString()}`;
      },
    });

    this.getBalanceSummaryReport = get({
      authContext,
      url: ({ nursingHomeId, dateRange }) => {
        const queryParams = new URLSearchParams();

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const dateField = dateRange?.[field];
          if (dateField) {
            queryParams.append(`dateRange[${field}]`, dateField.toISOString());
          }
        });

        queryParams.append('nursingHomeId', nursingHomeId);

        return `${baseUrl}/${endpoints.analytics}/${
          endpoints.analyticsGetBalanceSummaryReport
        }?${queryParams.toString()}`;
      },
    });
  }
}
