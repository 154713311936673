// eslint-disable-next-line max-classes-per-file
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsEnum,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import type {
  ExpandedResidentReceiptBatchEntryDto,
  ReceiptBatchEntryDto,
} from './receiptBatchEntryDto';
import {
  PaginatedQuery,
  RangeDateFilter,
  RangeIntFilter,
} from '@pflegenavi/shared/utils';
import { Transform } from 'class-transformer';
import type { UpdateReceiptBatchEntryResultDto } from './receiptBatchEntryDto';
import type { ReceiptBatchReceipt } from './receiptBatchReceipt';
import { PaymentStatus } from '../payment';

export class ReceiptBatchCashTransactionGroupDataDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;
  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string;
  @IsNotEmpty()
  @IsDate()
  updateDate!: Date;
  @IsNotEmpty()
  @IsString({ each: true })
  cashTransactionNotes!: string[];
  @IsNotEmpty()
  @IsNumber()
  cashAmount!: number;
  @IsNotEmpty()
  @IsNumber()
  @Min(0)
  linkCount!: number;
  @IsNotEmpty()
  @IsNumber()
  linkedAmount!: number;
}

export type SortOrder = 'asc' | 'desc';
export type SortBy =
  | 'createdOn'
  | 'serviceProviderName'
  | 'receiptDate'
  | 'totalAmount'
  | 'entryCount'
  | 'submissionDate'
  | 'lastUpdatedOn';

export enum ReceiptBatchJobState {
  Pending = 'pending',
  Partial = 'partial',
  Successful = 'successful',
  Failed = 'failed',
}

export enum ReceiptBatchOwner {
  NursingHome = 'nursing_home',
  ServiceProvider = 'service_provider',
}

export class GetReceiptBatchesQueryDto extends PaginatedQuery {
  @IsOptional()
  @Transform(({ value }) => (value === '' ? null : value))
  @ValidateIf((value) => value !== null)
  @IsEnum(ReceiptBatchJobState, { each: true })
  state?: ReceiptBatchJobState[] | null;
  @IsOptional()
  @ValidateNested()
  submissionDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @ValidateNested()
  receiptDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @ValidateNested()
  amount?: RangeIntFilter<number>;
  @IsOptional()
  @ValidateNested()
  receiptCount?: RangeIntFilter<number>;
  @IsOptional()
  @IsUUID(4, { each: true })
  receiptTypeIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  serviceProviderIds?: string[];
  @IsOptional()
  @IsString()
  serviceProviderNameSearch?: string;
  @IsOptional()
  @IsString()
  residentId?: string;
  @IsOptional()
  @IsUUID(4)
  recurringItemId?: string;
  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;
  @IsOptional()
  @IsString()
  sort?: SortBy;
}

export class IdAndNameDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsString()
  name!: string;
}

export class ReceiptBatchDtoBase {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;
  @IsNotEmpty()
  @IsString()
  title!: string;
  @IsNotEmpty()
  @IsDate()
  createdOn!: Date;
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  nursingHomeId!: string;
  @IsOptional()
  @IsDate()
  receiptDate?: Date;
  @IsOptional()
  @IsBoolean()
  serviceProviderRequired?: boolean;
  @IsOptional()
  @IsString()
  @IsUUID(4)
  cashListTransactionGroupId?: string;
  @IsOptional()
  @IsBoolean()
  useIndividualReceiptDates: boolean | undefined;
  @IsOptional()
  @IsString({ each: true })
  @IsUUID(4, { each: true })
  @IsArray()
  receiptImageIds?: string[];
  @IsOptional()
  @IsEnum(ReceiptBatchOwner)
  owner?: ReceiptBatchOwner;
  @IsOptional()
  @IsBoolean()
  submissionLocked?: boolean;
  @IsOptional()
  @IsEnum(ReceiptBatchJobState)
  state?: ReceiptBatchJobState;
  @IsOptional()
  @IsDate()
  submissionDate?: Date;
  @IsOptional()
  @IsDate()
  lastUpdatedOn?: Date;
}

export class ReceiptBatchDto extends ReceiptBatchDtoBase {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  receiptType?: string;
  @IsOptional()
  @IsString()
  /** @deprecated use serviceProviderId and serviceProviderName instead
   * The usage was shared. So this either contains the id or the name of the service provider.
   */
  serviceProvider?: string;
  @IsOptional()
  @IsString()
  serviceProviderId?: string;
  @IsOptional()
  @IsString()
  serviceProviderName?: string;
  @IsOptional()
  @ValidateNested()
  cashListTransactionGroupData?: ReceiptBatchCashTransactionGroupDataDto;
  @IsOptional()
  @IsBoolean()
  generateReceipts?: boolean;
}

export class ReceiptBatchListDto extends ReceiptBatchDto {
  @IsNotEmpty()
  @IsInt()
  numberOfReceipts!: number;
  /** In cents */
  @IsNotEmpty()
  @IsInt()
  totalAmount!: number;
  @IsOptional()
  @IsString()
  receiptTypeName?: string;

  @IsOptional()
  @IsDate()
  minReceiptDate?: Date;

  @IsOptional()
  @IsDate()
  maxReceiptDate?: Date;

  @IsOptional()
  @IsUUID(4)
  recurringItemId?: string;
}

export class ReceiptBatchPayment {
  @IsString()
  id!: string;

  @IsEnum(PaymentStatus)
  status!: PaymentStatus;
}

export class ReceiptBatchPaginatedDto extends ReceiptBatchDtoBase {
  @IsOptional()
  @ValidateNested()
  serviceProviderInfo?: IdAndNameDto;
  @IsOptional()
  @ValidateNested()
  receiptTypeInfo?: IdAndNameDto;

  @IsNotEmpty()
  @IsInt()
  numberOfReceipts!: number;
  @IsNotEmpty()
  @IsInt()
  numberOfInvalidReceipts!: number;
  /** In cents */
  @IsNotEmpty()
  @IsInt()
  totalAmount!: number;

  @IsOptional()
  @ValidateNested()
  payment?: ReceiptBatchPayment;

  @IsOptional()
  @IsDate()
  minReceiptDate?: Date;

  @IsOptional()
  @IsDate()
  maxReceiptDate?: Date;

  @IsOptional()
  @IsUUID(4)
  recurringItemId?: string;

  @IsOptional()
  @IsBoolean()
  generateReceipts?: boolean;
}

export class ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto extends ReceiptBatchDto {
  @IsOptional()
  @IsString()
  receiptTypeName?: string;

  @IsArray()
  @ValidateNested({ each: true })
  receiptBatchEntries!: ExpandedResidentReceiptBatchEntryDto[];
}

export class ReceiptBatchGetDto extends ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto {
  @IsOptional()
  @ValidateNested()
  recurringItem: RecurringItemDto | undefined;
}

export class RecurringItemDto {
  @IsString()
  id!: string;
  @IsString()
  name!: string;
  /** Note that this amount is in cents */
  @IsNumber()
  amount!: number;
  @IsBoolean()
  archived!: boolean;
  @IsBoolean()
  autoAddResidents!: boolean;
  @IsBoolean()
  chargeFullPeriod!: boolean;
  @IsBoolean()
  computeDaily!: boolean;
  @IsOptional()
  @ValidateNested()
  taxCode: TaxCodeDto | undefined;
  @IsBoolean()
  generateReceipts!: boolean;
}

export class TaxCodeDto {
  @IsString()
  id!: string;
  @IsString()
  taxName!: string;
  @IsOptional()
  @IsString()
  taxAbbreviation?: string;
  @IsString()
  taxAmount!: string;
  @IsOptional()
  @IsString()
  explanationShort?: string;
  @IsOptional()
  @IsString()
  explanationLong?: string;
  @IsBoolean()
  displayExplanation!: boolean;
}

export class ReceiptBatchWithEntriesAndReceiptsDto extends ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto {
  @IsArray()
  receipts!: ReceiptBatchReceipt[];
}

export class ReceiptBatchWithReceiptBatchEntriesDto extends ReceiptBatchDto {
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  receiptBatchEntries?: ReceiptBatchEntryDto[];
}

// Backwards compatible with ReceiptBatchWithReceiptBatchEntriesDto
export class UpdateReceiptBatchResultDto extends ReceiptBatchDto {
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  receiptBatchEntries?: UpdateReceiptBatchEntryResultDto[];
}
