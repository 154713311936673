export enum ServiceProviderInviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum ServiceProviderStatus {
  Active = 'active',
  Inactive = 'archived',
}

/**
 * This is a combination of the two enums above to represent the status of a service provider in the system.
 * Internally it is stored separately, but for the UI it is easier to have a single status.
 */
export enum ServiceProviderStatusCombined {
  Active = 'active',
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
}
