import type {
  IReceiptType,
  ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto,
} from '@pflegenavi/shared/api';

// eslint-disable-next-line complexity
export const isReceiptBatchReadyToSubmit = (
  batch: ReceiptBatchWithExpandedResidentReceiptBatchEntriesDto | undefined,
  receiptTypes?: IReceiptType[]
): boolean => {
  const receiptType = receiptTypes?.find(
    (type) => type.id === batch?.receiptType
  );

  return (
    batch !== undefined &&
    batch.receiptBatchEntries.length > 0 &&
    batch.receiptBatchEntries.every((entry) => {
      return (
        entry.resident?.id !== undefined &&
        entry.amount !== undefined &&
        (batch.useIndividualReceiptDates ? entry.receiptDate : true)
      );
    }) &&
    (receiptType?.requiresServiceProvider
      ? batch.serviceProvider !== undefined
      : true) &&
    (!batch.useIndividualReceiptDates
      ? batch.receiptDate !== undefined
      : true) &&
    batch.receiptType !== undefined &&
    batch.submissionLocked !== true
  );
};
