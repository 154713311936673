import { createContext } from 'react';
import {
  makeApiProviderWithoutTenant,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { IFamilyMemberApi } from './api';
import { FamilyMemberApi } from './api';
import type { UseQueryResult } from 'react-query';
import type { FamilyMemberTenantDto } from '@pflegenavi/shared/api';

const ApiContext = createContext<IFamilyMemberApi | undefined>(undefined);
const { useApi: useFamilyMemberApi, ApiProvider: FamilyMemberApiProvider } =
  makeApiProviderWithoutTenant({
    name: 'FamilyMember',
    ApiContext,
    newApi: (auth, apiUrl) => new FamilyMemberApi(auth, apiUrl),
  });

const FAMILY_MEMBER_TENANT_KEY = () => ['family-member-tenant'];

export const useFamilyMemberTenant = (): UseQueryResult<
  FamilyMemberTenantDto[]
> => {
  return useApiQuery(
    // eslint-disable-next-line @grncdr/react-hooks/rules-of-hooks
    () => useFamilyMemberApi(),
    FAMILY_MEMBER_TENANT_KEY(),
    async (api) => {
      return await api.getFamilyMemberTenant();
    }
  );
};

export {
  useFamilyMemberApi,
  FamilyMemberApiProvider,
  ApiContext as FamilyMemberApiContext,
  FamilyMemberApi,
};
