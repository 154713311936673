import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import { get, getApiBaseUrl } from '@pflegenavi/shared-frontend/platform';
import type {
  Analytics,
  IReceiptsByStatus,
  ResidentSummaryDto,
} from '@pflegenavi/shared/api';
import { endpoints } from '@pflegenavi/shared/api';

export interface IAnalyticsApi extends Api {
  get(dataIn: { params: { nursingHomeId: string } }): Promise<Analytics>;

  getByResidentId(data: {
    params: { residentId: string; month?: Date };
  }): Promise<IReceiptsByStatus>;

  getResidentSummary(data: {
    params: { residentId: string };
  }): Promise<ResidentSummaryDto>;
}

export class AnalyticsApi implements IAnalyticsApi {
  get: IAnalyticsApi['get'];
  getByResidentId: IAnalyticsApi['getByResidentId'];
  getResidentSummary: IAnalyticsApi['getResidentSummary'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getApiBaseUrl(tenantId, apiUrl);

    this.get = get({
      authContext: authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.analytics}/${endpoints.analyticsGet}?nursingHomeId=${nursingHomeId}`,
      transform: (data) => ({
        ...data,
        negativeBalance: data.negativeBalance.map((row: any) => ({
          ...row,
          overdueSince: new Date(row.overdueSince),
        })),
        balanceBelowThreshold: data.balanceBelowThreshold.map((row: any) => ({
          ...row,
          overdueSince: new Date(row.overdueSince),
        })),
      }),
    });

    this.getByResidentId = get({
      authContext: authContext,
      url: ({ residentId, month }) => {
        const queryParams = new URLSearchParams();
        if (month) {
          queryParams.set('month', month.toISOString());
        }

        return `${baseUrl}/${
          endpoints.analytics
        }/${endpoints.analyticsGetByResident.replace(
          ':residentId',
          residentId
        )}?${queryParams.toString()}`;
      },
    });

    this.getResidentSummary = get({
      authContext: authContext,
      url: ({ residentId }) =>
        `${baseUrl}/${
          endpoints.analytics
        }/${endpoints.analyticsGetResidentSummary.replace(
          ':residentId',
          residentId
        )}`,
    });
  }
}
