enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other',
}

export interface PendingCharge {
  id: string;
  firstname: string;
  lastname: string;
  gender: Gender;
  resident_id: string;
  pending_charge_status: PendingChargeStatus;
  charge_date: Date;
  amount: number;
  currency: 'eur';
  nursing_home_id: string;
  family_member_id: string | null;
  family_member: string;
  isDirectDebitSetup: boolean;
  error?: string;
  additionalData?: any;
}

export interface PendingChargeWithResident extends PendingCharge {
  id: string;
  firstname: string;
  lastname: string;
  gender: Gender;
  info?: string;
}

export interface PendingChargeWithFamilyMember
  extends PendingChargeWithResident {
  family_member: string;
}

export enum PendingChargeStatus {
  initializing = 'Initializing',
  dataMissing = 'DataMissing',
  accountMissing = 'AccountMissing',
  pending = 'Pending',
  success = 'Success',
  failed = 'Failed',
  frozen = 'Frozen',
  upcoming = 'Upcoming',
  primaryFamilyMemberMissing = 'PrimaryFamilyMemberMissing',
}

export enum PaymentProcessor {
  Stripe = 'stripe',
  Mangopay = 'mangopay',
}

export enum PendingChargePaymentStatus {
  Idle = 'Idle',
  StripeAccountDeactivated = 'StripeAccountDeactivated',
  ResidentNotActive = 'ResidentNotActive',
  PrimaryFamilyMemberMissing = 'PrimaryFamilyMemberMissing',
  DirectDebitMissing = 'DirectDebitMissing',
  AmountTooHigh = 'AmountTooHigh',
  AmountTooLow = 'AmountTooLow',
  Initiating = 'Initiating',
  Upcoming = 'Upcoming',
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
  Frozen = 'Frozen',
  Disabled = 'Disabled',
  DisabledForNursingHome = 'DisabledForNursingHome',
}

export enum PendingPaymentStatusValues {
  ALL = 'All',
  failed = 'failed',
  upcoming = 'upcoming',
  ongoing = 'ongoing',
}

export interface PaymentResident {
  id: string;
  firstName: string;
  lastName: string;
  gender: Gender;
}

export interface PaymentFamilyMember {
  id: string;
  name: string;
}

export interface PaymentStateInitiating<
  FamilyMember extends { id: string } = { id: string }
> {
  // All conditions for triggering a payment fulfilled
  status: PendingChargePaymentStatus.Initiating;
  resident: PaymentResident;
  nursingHomeId: string;
  familyMember: FamilyMember;
  chargeDate: Date;
  chargeAmount: number;
  ibanLast4: string;
  schedule?: PaymentSchedule;
}

export interface PaymentStateUpcoming {
  status: PendingChargePaymentStatus.Upcoming;
  chargeDate: Date;
  chargeAmount: number;
  currency: 'eur';
  resident: PaymentResident;
  familyMember: PaymentFamilyMember;
}

// https://stripe.com/docs/error-codes
// States that are unlikely to appear for us are commented out
export const paymentStateFailedType = {
  // payment_intent_action_required: 'payment_intent_action_required',
  // payment_intent_authentication_failure:
  //   'payment_intent_authentication_failure',
  // payment_intent_incompatible_payment_method:
  //   'payment_intent_incompatible_payment_method',
  // payment_intent_invalid_parameter: 'payment_intent_invalid_parameter',
  payment_intent_mandate_invalid: 'payment_intent_mandate_invalid',
  // payment_intent_payment_attempt_expired:
  //   'payment_intent_payment_attempt_expired',
  payment_intent_payment_attempt_failed:
    'payment_intent_payment_attempt_failed',
  // payment_intent_unexpected_state: 'payment_intent_unexpected_state',
  unknown_error: 'unknown_error',
} as const;

export type PaymentStateFailedType = keyof typeof paymentStateFailedType;

export const paymentStateFailedTypeFromString = (
  type: string
): PaymentStateFailedType => {
  if (Object.keys(paymentStateFailedType).includes(type)) {
    return type as PaymentStateFailedType;
  }
  return 'unknown_error';
};

export interface PaymentStateFailed {
  status: PendingChargePaymentStatus.Failed;
  error: {
    type: PaymentStateFailedType;
  };
  chargeDate: Date;
  chargeAmount: number;
  currency: 'eur';
  resident: PaymentResident;
  familyMember: PaymentFamilyMember;
}

export interface PaymentStateFrozen {
  status: PendingChargePaymentStatus.Frozen;
  info: string;
  chargeDate: Date;
  chargeAmount: number;
  currency: 'eur';
  resident: PaymentResident;
  familyMember: PaymentFamilyMember;
}

export interface PaymentStatePending {
  status: PendingChargePaymentStatus.Pending;
  chargeDate: Date;
  chargeAmount: number;
  currency: 'eur';
  resident: PaymentResident;
  familyMember: PaymentFamilyMember;
}

export function isPaymentInitiating<FamilyMember extends { id: string }>(
  payment: PaymentPreconditions<FamilyMember>
): payment is PaymentStateInitiating<FamilyMember> {
  return payment.status === PendingChargePaymentStatus.Initiating;
}

export interface PaymentStateIdle {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.Idle; // resident is not below threshold
}

export interface PaymentStateStripeAccountDeactivated {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.StripeAccountDeactivated;
  nursingHomeId: string;
  info: string;
  chargeAmount: number;
}

export interface PaymentStatePrimaryFamilyMemberMissing {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.PrimaryFamilyMemberMissing;
  chargeAmount: number;
}

export interface PaymentStateDirectDebitMissing<
  FamilyMember extends { id: string } = { id: string }
> {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.DirectDebitMissing;
  familyMember: FamilyMember;
  info: string;
  chargeAmount: number;
}

export interface PaymentStateAmountTooHigh<
  FamilyMember extends { id: string } = { id: string }
> {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.AmountTooHigh;
  chargeAmount: number;
  currency: 'eur';
  maxAmount: number;
  familyMember: FamilyMember;
}

export interface PaymentStateAmountTooLow<
  FamilyMember extends { id: string } = { id: string }
> {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.AmountTooLow;
  chargeAmount: number;
  currency: 'eur';
  minAmount: number;
  familyMember: FamilyMember;
}

export interface PaymentStateResidentNotActive {
  resident: PaymentResident;
  // All conditions for triggering a payment fulfilled
  status: PendingChargePaymentStatus.ResidentNotActive;
}

export interface PaymentStateDisabledForNursingHome {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.DisabledForNursingHome;
  nursingHomeId: string;
  until?: Date;
  reason?: string;
}

export interface PaymentStateDisabled<
  FamilyMember extends { id: string } = {
    id: string;
    name: string;
  }
> {
  resident: PaymentResident;
  status: PendingChargePaymentStatus.Disabled;
  familyMember: FamilyMember;
  disabledDate: Date;
}

export interface PaymentStateSuccess {
  status: PendingChargePaymentStatus.Success;
  chargeDate: Date;
  chargeAmount: number;
  currency: 'eur';
  resident: PaymentResident;
  familyMember: PaymentFamilyMember;
}

export type PaymentPreconditions<
  FamilyMember extends { id: string } = { id: string }
> =
  | PaymentStateIdle
  | PaymentStateStripeAccountDeactivated
  | PaymentStatePrimaryFamilyMemberMissing
  | PaymentStateDirectDebitMissing<FamilyMember>
  | PaymentStateAmountTooHigh<FamilyMember>
  | PaymentStateAmountTooLow<FamilyMember>
  | PaymentStateInitiating<FamilyMember>
  | PaymentStateResidentNotActive
  | PaymentStateDisabled<FamilyMember>
  | PaymentStateDisabledForNursingHome;

export type PaymentPreconditionsWithoutStripeData<
  FamilyMember extends { id: string } = { id: string }
> =
  | PaymentStateIdle
  | PaymentStateStripeAccountDeactivated
  | PaymentStatePrimaryFamilyMemberMissing
  | PaymentStateDirectDebitMissing<FamilyMember>
  | PaymentStateAmountTooHigh<FamilyMember>
  | PaymentStateAmountTooLow<FamilyMember>
  | Omit<PaymentStateInitiating<FamilyMember>, 'ibanLast4'>
  | PaymentStateResidentNotActive
  | PaymentStateDisabled<FamilyMember>
  | PaymentStateDisabledForNursingHome;

export type PaymentPreconditionsFamilyMemberInitiated =
  | PaymentStateIdle
  | PaymentStateStripeAccountDeactivated
  | PaymentStateResidentNotActive
  | PaymentStatePrimaryFamilyMemberMissing
  | PaymentStateDisabledForNursingHome;

export type PaymentStatusResult<
  FamilyMember extends { id: string } = { id: string }
> =
  | PaymentPreconditions<FamilyMember>
  | PaymentStateUpcoming
  | PaymentStatePending
  | PaymentStateSuccess
  | PaymentStateFrozen
  | PaymentStateFailed;

export type PaymentStatusFamilyMemberInitiated =
  | PaymentPreconditionsFamilyMemberInitiated
  | PaymentStatePending
  | PaymentStateSuccess
  | PaymentStateFrozen
  | PaymentStateFailed;

export type PaymentStatusWithFamilyMember<
  FamilyMember extends { id: string } = { id: string }
> =
  | PaymentStateDirectDebitMissing<FamilyMember>
  | PaymentStateAmountTooHigh<FamilyMember>
  | PaymentStateAmountTooLow<FamilyMember>
  | PaymentStateInitiating<FamilyMember>
  | PaymentStateDisabled<FamilyMember>
  | PaymentStateUpcoming
  | PaymentStatePending
  | PaymentStateSuccess
  | PaymentStateFrozen
  | PaymentStateFailed;

const paymentStatusWithFamilyMemberMap: {
  [key in PaymentStatusWithFamilyMember['status']]: boolean;
} = {
  [PendingChargePaymentStatus.DirectDebitMissing]: true,
  [PendingChargePaymentStatus.AmountTooHigh]: true,
  [PendingChargePaymentStatus.AmountTooLow]: true,
  [PendingChargePaymentStatus.Initiating]: true,
  [PendingChargePaymentStatus.Upcoming]: true,
  [PendingChargePaymentStatus.Pending]: true,
  [PendingChargePaymentStatus.Success]: true,
  [PendingChargePaymentStatus.Frozen]: true,
  [PendingChargePaymentStatus.Failed]: true,
  [PendingChargePaymentStatus.Disabled]: true,
};

export const paymentStatusTypesWithFamilyMember = Object.keys(
  paymentStatusWithFamilyMemberMap
) as unknown as PaymentStatusWithFamilyMember['status'];

const paymentStatusFamilyMemberInitiatedTypeMap: {
  [key in PaymentStatusFamilyMemberInitiated['status']]: boolean;
} = {
  [PendingChargePaymentStatus.Failed]: true,
  [PendingChargePaymentStatus.Frozen]: true,
  [PendingChargePaymentStatus.Idle]: true,
  [PendingChargePaymentStatus.Pending]: true,
  [PendingChargePaymentStatus.ResidentNotActive]: true,
  [PendingChargePaymentStatus.StripeAccountDeactivated]: true,
  [PendingChargePaymentStatus.Success]: true,
  [PendingChargePaymentStatus.PrimaryFamilyMemberMissing]: true,
  [PendingChargePaymentStatus.DisabledForNursingHome]: true,
};

export const paymentStatusFamilyMemberInitiatedTypes = Object.keys(
  paymentStatusFamilyMemberInitiatedTypeMap
) as unknown as PaymentStatusFamilyMemberInitiated['status'];

export enum PaymentInitiatedBy {
  System = 'system',
  FamilyMember = 'familyMember',
  Disabled = 'disabled',
  Employee = 'employee',
}

export enum PaymentSchedule {
  Threshold = 'threshold',
  Monthly = 'monthly',
}

export type PaymentScheduleData =
  | PaymentScheduleThreshold
  | PaymentScheduleMonthly;

export interface PaymentScheduleThreshold {
  schedule: PaymentSchedule.Threshold;
}

export interface PaymentScheduleMonthly {
  schedule: PaymentSchedule.Monthly;
  dayOfMonth: number; // 1-31
  maxAmount?: number; // in cents
}
