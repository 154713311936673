// an enum that contains the app names
export enum PflegenaviAppName {
  NURSING_HOME = 'Nursing Home',
  FAMILY_MEMBER = 'Family Member',
  SERVICE_PROVIDER = 'Service Provider',
}

// a function that returns the app name based on the window location
export function getPflegenaviAppName(): PflegenaviAppName {
  const { href } = window.location;
  if (href.includes('family-member') || href.includes('localhost:3001')) {
    return PflegenaviAppName.FAMILY_MEMBER;
  } else if (
    href.includes('service-provider') ||
    href.includes('localhost:3003')
  ) {
    return PflegenaviAppName.SERVICE_PROVIDER;
  } else {
    return PflegenaviAppName.NURSING_HOME;
  }
}
