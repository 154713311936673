export enum ResidentServiceError {
  DUPLICATE_RESIDENT_ACCOUNTING_ID = 'DUPLICATE_RESIDENT_ACCOUNTING_ID',
  RESIDENT_ACCOUNTING_ID_ALREADY_SET = 'RESIDENT_ACCOUNTING_ID_ALREADY_SET',
  FAMILY_MEMBER_EMAIL_ALREADY_EXISTS = 'FAMILY_MEMBER_EMAIL_ALREADY_EXISTS',
  CONFLICTING_FAMILY_MEMBER_EMAIL = 'CONFLICTING_FAMILY_MEMBER_EMAIL',
}

export enum SettlementServiceError {
  START_DATE_AFTER_EXIT_DATE = 'START_DATE_AFTER_EXIT_DATE',
}

export enum ImportFamilyMemberError {
  /** Only 50 family members are allowed */
  TOO_MANY_FAMILY_MEMBERS = 'TOO_MANY_FAMILY_MEMBERS',
  /** The same resident is listed twice */
  DUPLICATE_RESIDENT_ID = 'DUPLICATE_RESIDENT_ID',
  /** The resident has a family member specified that is in conflict with the current family member (Import is a noop if the family member was already added) */
  CONFLICTING_FAMILY_MEMBER_EMAIL = 'CONFLICTING_FAMILY_MEMBER_EMAIL',
  RESIDENT_NOT_FOUND = 'RESIDENT_NOT_FOUND',
}
