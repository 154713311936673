import type {
  FamilyMemberDatabaseDto,
  FamilyMemberWithIdDto,
} from './FamilyMemberDto';
import type { Gender } from './Gender';
import type { ResidentPaymentInfo } from './resident.dto';

export enum ResidentState {
  active = 'Active',
  awaitingSettlement = 'AwaitingSettlement',
  exited = 'Exited',
  archived = 'Archived',
}

/**
 * @deprecated Use ResidentListItemV2Dto instead
 */
export interface ResidentListItemDto {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  familyMembers?: FamilyMemberDatabaseDto[];
  birthdate?: Date;
  gender: Gender;
  balance: number;
  lowBalance: boolean;
  threshold_due_date?: Date;
  negative_due_date?: Date;
  entry_date: Date;
  state: ResidentState;
  exit_date?: Date;
  settlement_date?: Date;
  notes?: string;
  residentAccountingId?: string;
  pendingReceiptCount?: { draft: number; incomplete: number };
  paymentInfo?: ResidentPaymentInfo;
  cashPayer: boolean;
}

export interface ResidentListItemV2Dto {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  familyMembers?: FamilyMemberDatabaseDto[];
  birthdate?: Date;
  gender: Gender;
  balanceInCents: number;
  lowBalance: boolean;
  thresholdInCents: number;
  thresholdDueDate?: Date;
  negativeDueDate?: Date;
  entryDate: Date;
  state: ResidentState;
  exitDate?: Date;
  settlementDate?: Date;
  notes?: string;
  residentAccountingId?: string;
  pendingReceiptCount?: { draft: number; incomplete: number };
  cashPayer: boolean;
}

export interface Resident {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  familyMembers: FamilyMemberWithIdDto[];
  birthdate?: Date;
  gender: Gender;
  /** @deprecated Use balanceInCents instead */
  balance: number;
  balanceInCents: number;
  lowBalance: boolean;
  threshold_due_date?: Date;
  negative_due_date?: Date;
  entry_date: Date;
  created_date: Date;
  state: ResidentState;
  exit_date?: Date;
  settlement_date?: Date;
  notes?: string;
  nursingHome: {
    id: string;
    name: string;
  };
  residentAccountingId?: string;
  pendingReceiptCount?: { draft: number; incomplete: number };
  cashPayer: boolean;
}

export interface ResidentConfiguration {
  nursingHomeId: string;
  residentId: string;
  threshold: number;
  topUpAmount: number;
}
