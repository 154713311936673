import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';
import { FamilyMemberDto } from './FamilyMemberDto';

export class UpdateFamilyMemberDto extends FamilyMemberDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  residentId?: string;
}
