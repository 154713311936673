import fc from 'fast-check';

export const phone = fc
  .stringOf(fc.constantFrom('0', '1', '2', '3', '4', '5', '6', '7', '8', '9'), {
    minLength: 6,
    maxLength: 9,
  })
  .noBias();

export const zip = fc
  .stringOf(fc.constantFrom('0', '1', '2', '3', '4', '5', '6', '7', '8', '9'), {
    minLength: 4,
    maxLength: 5,
  })
  .noBias();
