import type { PaymentQueryFilters } from './Payment';
import { PaymentStatus } from './Payment';
import type { SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL } from '../errors';
import { PaginatedQuery, RangeDateFilter } from '@pflegenavi/shared/utils';
import {
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';

export interface ServiceProviderPayment {
  id: string;
  amount: number;
  currency: string;
  last4Iban: string | null;
  statementDescriptor: string | null;
  created: Date;
  updated: Date;
  canceled: Date | null;
  completedAt: Date | null;
  status: PaymentStatus;
  serviceProvider: {
    id: string;
    globalServiceProviderId: string;
    name: string;
    receiptType: {
      id: string;
      name: string;
    };
  };
  nursingHomeId: string;
  paymentType: string | null;
  receiptBatchId: string | null;
  employee: {
    id: string;
    firstName: string | undefined;
    lastName: string | undefined;
  } | null;
}

export class ServiceProviderPaymentListQuery
  extends PaginatedQuery
  implements PaymentQueryFilters
{
  @IsEnum(PaymentStatus, { each: true })
  @IsOptional()
  states?: PaymentStatus[];

  @IsOptional()
  @ValidateNested()
  created?: RangeDateFilter<Date>;

  @IsOptional()
  @ValidateNested()
  completedAt?: RangeDateFilter<Date>;

  @IsOptional()
  @IsUUID(4)
  globalServiceProviderId?: string;

  @IsNotEmpty()
  @IsUUID(4)
  nursingHomeId!: string;

  @IsOptional()
  @IsString()
  @IsIn(['created', 'completedAt', 'updated'])
  sortBy?: 'created' | 'completedAt' | 'updated';

  @IsOptional()
  @IsString()
  @IsIn(['asc', 'desc'])
  sortOrder?: 'asc' | 'desc';
}

export enum ReceiptBatchPaymentState {
  PAYMENT_BLOCKED = 'PAYMENT_BLOCKED',
  PAYMENT_POSSIBLE = 'PAYMENT_POSSIBLE',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
}

export type ReceiptBatchPaymentStatus =
  | ReceiptBatchPaymentStatusBlocked
  | ReceiptBatchPaymentStatusPossible
  | ReceiptBatchPaymentStatusInitiated;

interface ReceiptBatchPaymentStatusInitiated {
  status: ReceiptBatchPaymentState.PAYMENT_INITIATED;
  payment: ServiceProviderPayment;
}

interface ReceiptBatchPaymentStatusBlocked {
  status: ReceiptBatchPaymentState.PAYMENT_BLOCKED;
  reasons: SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL[];
}

interface ReceiptBatchPaymentStatusPossible {
  status: ReceiptBatchPaymentState.PAYMENT_POSSIBLE;
}
