import { IsBoolean, IsNotEmpty, IsUUID } from 'class-validator';

export class ResidentSettlementChargesDto {
  @IsNotEmpty()
  @IsUUID()
  recurring_item_id!: string;
  @IsNotEmpty()
  @IsBoolean()
  prorate!: boolean;
}
