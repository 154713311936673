import type { Gender } from '../resident';
import { PaginatedQuery, RangeDateFilter } from '@pflegenavi/shared/utils';
import {
  IsEnum,
  IsIn,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';

interface BaseDto {
  id: string;
  /** The amount of the receipt batch or the actual receipts */
  amountInCents: number;
  createdAt: Date;
  submittedAt?: Date;
  receiptDate?: Date;

  serviceProvider?: {
    id: string;
    name: string;
  };
  receiptType?: {
    id: string;
    name: string;
  };
}

export interface ReceiptItemDto extends BaseDto {
  type: 'receipt';
  resident: {
    id: string;
    firstName: string;
    lastName: string;
    gender: Gender;
  };
}

export interface ReceiptBatchItemDto extends BaseDto {
  type: 'receipt-batch';
  amountReceiptBatchInCents: number;
  amountReceiptsInCents: number;

  numberOfReceipts: number;
  minReceiptDate: Date | undefined;
  maxReceiptDate: Date | undefined;
  useIndividualDates: boolean;
}

export type ReceiptsAndReceiptBatchesDto = ReceiptItemDto | ReceiptBatchItemDto;

export enum ReceiptsAndReceiptBatchesQuerySortBy {
  createdAt = 'createdAt',
  receiptDate = 'receiptDate',
  submittedAt = 'submittedAt',
}

type SortOrder = 'asc' | 'desc';

export class ReceiptsAndReceiptBatchesQueryParams extends PaginatedQuery {
  @IsOptional()
  @IsString()
  @IsEnum(['submitted', 'draft'])
  state?: 'submitted' | 'draft';

  @IsOptional()
  @ValidateNested()
  submissionDate?: RangeDateFilter<Date>;

  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;

  @IsOptional()
  @IsEnum(ReceiptsAndReceiptBatchesQuerySortBy)
  sort?: ReceiptsAndReceiptBatchesQuerySortBy;
}
