import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';

export class ImportFamilyMemberDto {
  @IsNotEmpty()
  @ValidateNested({ each: true })
  data!: FamilyMemberImportEntryDto[];
}

export class FamilyMemberImportEntryDto {
  @IsNotEmpty()
  @IsUUID(4)
  residentId!: string;

  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  @IsEmail()
  email!: string;
}

export class ImportFamilyMemberResponseDto {
  data!: FamilyMemberImportResponseEntryDto[];
}

export class FamilyMemberImportResponseEntryDto {
  @IsNotEmpty()
  @IsUUID(4)
  residentId!: string;

  @IsNotEmpty()
  @IsBoolean()
  success!: boolean;

  @IsNumber()
  @IsOptional()
  errorCode?: number;

  @IsString()
  @IsOptional()
  errorMessage?: string;
}

export interface ImportFamilyMemberPhoenix {
  data: FamilyMemberImportEntryPhoenix[];
  nursing_home_id: string;
}

export interface FamilyMemberImportEntryPhoenix {
  resident_id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface ImportFamilyMemberResponsePhoenix {
  data: FamilyMemberImportResponseEntryPhoenix[];
}

export interface FamilyMemberImportResponseEntryPhoenix {
  resident_id: string;
  success: boolean;
  error?: string;
}
