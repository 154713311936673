import type { FamilyMemberWithIdDto } from './FamilyMemberDto';
import type { Gender } from './Gender';
import { IsEnum, IsOptional, IsString, IsUUID } from 'class-validator';
import { ResidentState } from './resident.interface';
import type { RangeIntFilter } from '@pflegenavi/shared/utils';
import type {
  DirectDebitStatus,
  PaymentInitiatedBy,
  PaymentSchedule,
} from '../payment';

export class ResidentDto {
  firstName!: string;
  lastName!: string;
  birthdate?: Date;
  gender!: Gender;
  balance!: number;
}

export class ResidentWithIdDto extends ResidentDto {
  id!: string;
}

export class ResidentWithFamilyMembersDto extends ResidentWithIdDto {
  familyMembers!: FamilyMemberWithIdDto[];
}

export type ResidentPaymentInfo =
  | ResidentPaymentInfoSystemInitiated
  | ResidentPaymentInfoFamilyMemberInitiated
  | ResidentPaymentInfoDisabled;

export enum ResidentPendingChargePaymentStatus {
  Idle = 'Idle',
  Upcoming = 'Upcoming',
  Pending = 'Pending',
  Failed = 'Failed',
}

export interface ResidentPaymentInfoFamilyMemberInitiated {
  initiatedBy: PaymentInitiatedBy.FamilyMember;
  status: ResidentPendingChargePaymentStatus;
  topUpAmountInCents: number;
}

export interface ResidentPaymentInfoSystemInitiated {
  initiatedBy: PaymentInitiatedBy.System;
  status: ResidentPendingChargePaymentStatus;
  directDebit: DirectDebitStatus;
  schedule: PaymentSchedule;
  topUpAmountInCents: number;
  residentMaxAmount: number;
}

export interface ResidentPaymentInfoDisabled {
  initiatedBy: PaymentInitiatedBy.Disabled;
  status: ResidentPendingChargePaymentStatus;
}

export class GetAllResidentsDto {
  @IsOptional()
  @IsEnum(ResidentState, { each: true })
  states!: ResidentState[];

  @IsOptional()
  additionalFields?: {
    pendingReceiptCount?: boolean;
    /**
     * Specifying this field will return data similar to PaymentInfo. The difference
     * being that preconditions will not be computed.
     */
    residentPaymentInfo?: boolean;
  };

  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;
}

export enum BalanceState {
  belowThreshold = 'belowThreshold',
  negative = 'negative',
  atOrAboveThreshold = 'atOrAboveThreshold',
}

interface GetResidentsQueryOptions {
  additionalFields?: {
    pendingReceiptCount?: boolean;
    familyMembers?: boolean;
  };
}

export enum GetResidentsSearchField {
  firstName = 'firstName',
  lastName = 'lastName',
  familyMembersFirstName = 'familyMembers.firstName',
  familyMembersLastName = 'familyMembers.lastName',
}

export interface GetResidentsQueryParams {
  nursingHomeId?: string;

  states?: ResidentState[];

  familyMemberIds?: string[];

  balance?: RangeIntFilter<number>;
  balanceState?: BalanceState;

  search?: {
    fields: GetResidentsSearchField[];
    value: string;
  };

  options?: GetResidentsQueryOptions;
}
