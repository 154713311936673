import { useMemo } from 'react';
import { useLocale } from './context/localProvider';

const transformToNonBreakingCharacters = (str: string): string => {
  return str.replace(' ', '&nbsp;').replace('-', '\u2011');
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeFunctions = (locale: string) => {
  const numberInputToNumberType = (number: string | number): number => {
    let adjustedNumber: number;
    if (typeof number === 'number') {
      adjustedNumber = number;
    } else {
      adjustedNumber = Number.isInteger(number)
        ? parseInt(number)
        : parseFloat(number);
    }

    return adjustedNumber;
  };

  const fNumber = (
    number: number,
    minimumFractionDigits = 0,
    options: Intl.NumberFormatOptions = {}
  ): string => {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits,
      ...options,
    }).format(number);
  };

  const fCurrency = (
    number: string | number,
    {
      minimumFractionDigits = 2,
      useNonBreakingCharacters = true,
    }: {
      minimumFractionDigits?: number;
      useNonBreakingCharacters?: boolean;
    } = {}
  ): string => {
    const adjustedNumber = numberInputToNumberType(number);
    let formatted = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits,
    }).format(adjustedNumber);

    if (useNonBreakingCharacters) {
      formatted = transformToNonBreakingCharacters(formatted);
    }

    return formatted;
  };

  const fCurrencyCents = (
    number: string | number,
    {
      minimumFractionDigits = 2,
      useNonBreakingCharacters = true,
    }: {
      minimumFractionDigits?: number;
      useNonBreakingCharacters?: boolean;
    } = {}
  ): string => {
    const adjustedNumber = numberInputToNumberType(number);
    let formatted = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits,
    }).format(adjustedNumber / 100);

    if (useNonBreakingCharacters) {
      formatted = transformToNonBreakingCharacters(formatted);
    }

    return formatted;
  };

  // looks like Excel uses by default German number format (if you have German locale)
  const fCsvNumber = (number: number): string => {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: false,
    }).format(number);
  };

  const fPercent = (
    number: string | number,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2
  ): string => {
    const adjustedNumber = numberInputToNumberType(number);

    return new Intl.NumberFormat(locale, {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(adjustedNumber);
  };

  const getPercentage = (
    now: number | undefined,
    before: number | undefined
  ): number => {
    if (before === 0 || before === undefined || now === undefined) {
      return 0;
    }

    return (now - before) / Math.abs(before);
  };

  const fCountPercent = (now: number, before: number): string => {
    return fPercent(getPercentage(now, before));
  };

  const fCurrencyShort = (number: string | number): string => {
    const adjustedNumber = numberInputToNumberType(number);
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    }).format(adjustedNumber);
  };

  const fThousands = (number: string | number): string => {
    const adjustedNumber = numberInputToNumberType(number);
    return new Intl.NumberFormat(locale).format(adjustedNumber);
  };

  return {
    fCountPercent,
    fPercent,
    fCsvNumber,
    getPercentage,
    fThousands,
    fNumber,
    fCurrency,
    fCurrencyShort,
    fCurrencyCents,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormatting = () => {
  const locale = useLocale();

  return useMemo(() => {
    return makeFunctions(locale);
  }, [locale]);
};
