import type { FC, PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { ClientStoredResourcesContext } from './clientStoredResourcesContext';

interface ClientStoredResourcesProps {
  storage: {
    setItem: (key: string, value: string) => Promise<void> | void;
    getItem: (key: string) => Promise<string | null> | string | null;
    removeItem(key: string): void;
  };
}
export const ClientStoredResourcesProvider: FC<
  PropsWithChildren<ClientStoredResourcesProps>
> = ({ children, storage }) => {
  const [storedKeys, setStoredKeys] = useState<Set<string>>(new Set());
  const storedKeysRef = useRef(storedKeys);
  storedKeysRef.current = storedKeys;

  const set = useCallback(
    async (key: string, value: string) => {
      await storage.setItem(key, value);
    },
    [storage]
  );

  const get = useCallback(
    (key: string) => {
      return storage.getItem(key);
    },
    [storage]
  );

  const remove = useCallback(
    (key: string) => {
      storage.removeItem(key);
    },
    [storage]
  );

  const registerResource = useCallback((name: string) => {
    setStoredKeys((keys) => {
      const newKeys = new Set(keys);
      newKeys.add(name);
      return newKeys;
    });
  }, []);

  const clearAll = useCallback(async () => {
    const keys = Array.from(storedKeysRef.current.values());
    await Promise.all(keys.map((key) => storage.removeItem(key)));
  }, [storage, storedKeysRef]);

  const value = useMemo(
    () => ({
      set,
      get,
      remove,
      registerResource,
      clearAll,
    }),
    [clearAll, get, registerResource, remove, set]
  );

  return (
    <ClientStoredResourcesContext.Provider value={value}>
      {children}
    </ClientStoredResourcesContext.Provider>
  );
};
