// Please sync with packages/frontend/feature-flags/src/FeatureFlagModal.tsx
export enum FeatureFlag {
  /** Permanent (for now) */
  CoinList = 'CoinList' /** Only exists for Drescher */,
  DevTools = 'DevTools',

  /** Feature flag to be enabled */
  PaymentStatusBadge = 'PaymentStatusBadge',
  AddFamilyMember = 'AddFamilyMember',
  AccountingPaymentsNew = 'AccountingPaymentsNew' /** Keep for Caritas until autumn */,
  dev_ServiceProviderApp = 'dev_ServiceProviderApp' /** Keep for Caritas until autumn */,
  ServiceProviderPayments = 'ServiceProviderPayments' /** Keep for Caritas until autumn */,
  ResidentsPaymentOverview = 'ResidentsPaymentOverview' /** Keep for Caritas until autumn */,

  /** Still in development */
  ImportFamilyMembers = 'ImportFamilyMembers',
  EmployeeManagement = 'EmployeeManagement',

  /** Development */
  dev_NursingHomeReceiptForServiceProvider = 'dev_NursingHomeReceiptForServiceProvider',

  /** Feature to be removed (Keep for Drescher) */
  AccountingPayments = 'AccountingPayments',

  dev_ServiceProviderPhoenixApi = 'dev_ServiceProviderPhoenixApi',
  dev_StripeVisualization = 'dev_StripeVisualization',

  dev_LegalEntities = 'dev_LegalEntities',
}

export enum FeatureFlagStatus {
  Disabled,
  Enabled,
}
