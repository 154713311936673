import { differenceInCalendarDays, startOfDay } from 'date-fns';

export function getDiffInDaysUntilDayOfMonth(
  date: Date,
  dayOfMonth: number
): number {
  const originalDate = startOfDay(new Date(date)); // cloned, so that we can safely modify the time (we ignore the time)

  const compareDate = startOfDay(
    new Date(originalDate.getFullYear(), originalDate.getMonth(), dayOfMonth)
  );

  // If date is already in the past, we need to check the next month instead
  if (compareDate < originalDate) {
    compareDate.setMonth(compareDate.getMonth() + 1);
  }

  // If the day is a different one, it means that the day does not exist in the month
  // Therefore we want to use the first day of the month
  if (compareDate.getDate() !== dayOfMonth) {
    compareDate.setDate(1);
  }

  return differenceInCalendarDays(compareDate, originalDate);
}
