import {
  differenceInCalendarDays,
  getDaysInMonth,
  isAfter,
  startOfMonth,
} from 'date-fns';

interface ComputeAmountEndDateResult {
  sum: number;
  numDays: number;
}

// eslint-disable-next-line complexity
export const computeAmountEndDate = (
  amountInCents: number,
  chargeFullPeriod: boolean,
  computeDaily: boolean,
  startDate: Date | undefined,
  endDate: Date
): ComputeAmountEndDateResult => {
  // See recurring-item-v2.service
  let monthStart = startOfMonth(endDate);
  if (startDate && isAfter(startDate, monthStart)) {
    monthStart = startDate;
  }

  const daysInMonth = getDaysInMonth(endDate);
  const numDays = chargeFullPeriod
    ? daysInMonth
    : differenceInCalendarDays(endDate, monthStart) + 1;

  if (computeDaily) {
    return { sum: numDays * amountInCents, numDays };
  } else if (chargeFullPeriod) {
    return { sum: amountInCents, numDays };
  } else {
    return { sum: (numDays * amountInCents) / daysInMonth, numDays };
  }
};
