import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import { createContext } from 'react';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { INursingHomePhoenixApi, TenantInformation } from './api';
import { NursingHomePhoenixApi } from './api';
import type { BaseData, LegalEntity } from '@pflegenavi/shared/api';

const ApiContext = createContext<INursingHomePhoenixApi | undefined>(undefined);
const {
  useApi: useNursingHomePhoenixApi,
  ApiProvider: NursingHomePhoenixApiProvider,
} = makeApiProvider({
  name: 'NursingHomePhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new NursingHomePhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useNursingHomePhoenixApi,
  NursingHomePhoenixApiProvider,
  ApiContext as NursingHomePhoenixApiContext,
};

const TENANT_INFORMATION_KEY = 'TENANT_INFORMATION_KEY';
const LEGAL_ENTITIES_KEY = 'LEGAL_ENTITIES_KEY';
const CREATE_LEGAL_ENTITY_KEY = 'CREATE_LEGAL_ENTITY_KEY';
const UPDATE_LEGAL_ENTITY_KEY = 'UPDATE_LEGAL_ENTITY_KEY';

export const useGetTenantInformation = (
  options?: UseQueryOptions<
    {
      data: TenantInformation;
    },
    unknown,
    TenantInformation
  >
): UseQueryResult<TenantInformation> => {
  return useApiQuery(
    useNursingHomePhoenixApi,
    TENANT_INFORMATION_KEY,
    (api) => {
      return api.getTenantInformation();
    },
    {
      ...options,
      select: (data) => data.data,
    }
  );
};

export const useGetLegalEntities = (
  options?: UseQueryOptions<
    {
      meta: {
        total_count: number;
      };
      data: LegalEntity[];
    },
    unknown,
    {
      meta: {
        total_count: number;
      };
      data: LegalEntity[];
    }
  >
): UseQueryResult<{
  meta: {
    total_count: number;
  };
  data: LegalEntity[];
}> => {
  return useApiQuery(
    useNursingHomePhoenixApi,
    LEGAL_ENTITIES_KEY,
    (api) => {
      return api.getLegalEntities();
    },
    {
      ...options,
    }
  );
};

export const useCreateLegalEntityPhoenix = (): UseMutationResult<
  {
    data: LegalEntity;
  },
  unknown,
  BaseData
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: LegalEntity;
    },
    unknown,
    BaseData
  >(
    CREATE_LEGAL_ENTITY_KEY,
    (base_data) =>
      api.createLegalEntity({
        body: {
          data: {
            base_data,
          },
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(LEGAL_ENTITIES_KEY);
      },
    }
  );
  return result;
};

export const useUpdateLegalEntityPhoenix = ({
  legalEntityId,
}: {
  legalEntityId: string;
}): UseMutationResult<
  {
    data: LegalEntity;
  },
  unknown,
  BaseData
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: LegalEntity;
    },
    unknown,
    BaseData
  >(
    UPDATE_LEGAL_ENTITY_KEY,
    (base_data) =>
      api.updateLegalEntity({
        params: {
          legalEntityId,
        },
        body: {
          data: {
            id: legalEntityId,
            base_data,
          },
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(LEGAL_ENTITIES_KEY);
      },
    }
  );
  return result;
};

const DELETE_LEGAL_ENTITY_CONFLICT_ERROR_MESSAGE =
  'Legal entity is still associated with nursing homes';

export const isDeleteLegalEntityConflictException = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): boolean => {
  return (
    'errors' in error &&
    'detail' in error.errors &&
    error.errors.detail &&
    error.errors.detail === DELETE_LEGAL_ENTITY_CONFLICT_ERROR_MESSAGE
  );
};

export const useDeleteLegalEntityPhoenix = (): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    legalEntityId: string;
  }
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      legalEntityId: string;
    }
  >(
    UPDATE_LEGAL_ENTITY_KEY,
    ({ legalEntityId }) =>
      api.deleteLegalEntity({
        params: {
          legalEntityId,
        },
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(LEGAL_ENTITIES_KEY);
      },
    }
  );
  return result;
};
