// eslint-disable-next-line max-classes-per-file
import {
  IsArray,
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { PaginatedQuery, RangeDateFilter } from '@pflegenavi/shared/utils';
import {
  TransactionInitiatedBy,
  TransactionSourceType,
  TransactionType,
} from './GetTransactionsWithReceiptDto';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import type { SortOrder } from './receiptBatchDto';

export enum GetTransactionsSearchField {
  notes = 'notes',
}

export class GetTransactionsSearch {
  @IsEnum(GetTransactionsSearchField, { each: true })
  @IsNotEmpty()
  @IsArray()
  fields!: GetTransactionsSearchField[];

  @IsString()
  @IsOptional()
  value?: string;
}

export enum GetTransactionsPaginatedQuerySortBy {
  submissionDate = 'submissionDate',
  receiptDate = 'receiptDate',
  amount = 'amount',
}

export class GetTransactionsPaginatedQueryParams extends PaginatedQuery {
  @IsOptional()
  @IsEnum(TransactionSourceType, { each: true })
  @IsArray()
  transactionSourceTypes?: TransactionSourceType[];
  @IsOptional()
  @IsEnum(TransactionType, { each: true })
  transactionTypes?: TransactionType[];
  @IsOptional()
  @ValidateNested()
  submissionDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @ValidateNested()
  receiptDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  residentIds?: string[];
  @IsOptional()
  @IsEnum(TransactionInitiatedBy, { each: true })
  @IsArray()
  initiators?: TransactionInitiatedBy[];
  @IsOptional()
  @ValidateNested()
  search?: GetTransactionsSearch;
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  serviceProviderIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  receiptTypeIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  creatorIds?: string[];

  @IsOptional()
  @IsEnum(GetTransactionsPaginatedQuerySortBy)
  sortBy?: GetTransactionsPaginatedQuerySortBy;

  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;
}

export class GetTotalResidentBalanceQueryParams {
  @IsOptional()
  @ValidateNested()
  submissionDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  residentIds?: string[];
}

// Same as GetTransactionsPaginatedQueryParams, but without pagination
export class GetFilteredBalanceChangesQueryParams {
  @IsOptional()
  @IsEnum(TransactionSourceType, { each: true })
  @IsArray()
  transactionSourceTypes?: TransactionSourceType[];
  @IsOptional()
  @IsEnum(TransactionType, { each: true })
  transactionTypes?: TransactionType[];
  @IsOptional()
  @ValidateNested()
  submissionDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @ValidateNested()
  receiptDateRange?: RangeDateFilter<Date>;
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  residentIds?: string[];
  @IsOptional()
  @IsEnum(TransactionInitiatedBy, { each: true })
  @IsArray()
  initiators?: TransactionInitiatedBy[];
  @IsOptional()
  @ValidateNested()
  search?: GetTransactionsSearch;
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  serviceProviderIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  receiptTypeIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  creatorIds?: string[];

  @IsOptional()
  @IsEnum(GetTransactionsPaginatedQuerySortBy)
  sortBy?: GetTransactionsPaginatedQuerySortBy;

  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;
}

export class TotalResidentBalanceResultDto {
  currentTotal!: number;
  totalPeriodEnd!: number;
  totalPeriodStart!: number;
}

export class FilteredTransactionChangesResultDto {
  deposits!: number;
  withdrawals!: number;
}
