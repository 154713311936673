export const NOTES_MAX_FIELD_LENGTH = 255;
export const ROWS_PER_PAGE = [5, 10, 15, 20, 25];
export const ROWS_PER_PAGE_DEFAULT = 5;

export enum ENDPOINT_GROUP {
  version = 'version',
  healthcheck = 'healthcheck',
  nhMobile = 'nursing-home-mobile-app',
  nhWeb = 'nursing-home-web-app',
  fmMobile = 'family-member-mobile-app',
  fmWebApp = 'family-member-web-app',
  adminWebApp = 'admin-web-app',
  spWebApp = 'service-provider-web-app',
  spMobile = 'service-provider-mobile-app',
}
