import type {
  LocaleStorage,
  SupportedLanguages,
} from '@pflegenavi/frontend/localization';
import { useToggleLocale } from '@pflegenavi/frontend/localization';
import {
  useUserProfileApi,
  useUserProfileWithContext,
} from '@pflegenavi/frontend/api-nursing-home';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface UseSyncLocaleWithServerProps {
  localeStorage: LocaleStorage;
}

export const useSyncLocaleWithServer = ({
  localeStorage,
}: UseSyncLocaleWithServerProps): void => {
  const { i18n } = useTranslation();
  const toggleLocale = useToggleLocale();
  const api = useUserProfileApi(true);

  const { data: userProfile } = useUserProfileWithContext({
    enabled: api !== undefined,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // eslint-disable-next-line complexity
    (async () => {
      const serverLocale = userProfile?.user?.locale;
      const browserLanguage = await localeStorage.getLanguage();

      if (serverLocale === undefined) {
        return;
      }

      if (browserLanguage === undefined && serverLocale) {
        await localeStorage.setLocale(
          serverLocale === 'en' ? 'en-GB' : 'de-AT'
        );
        await localeStorage.setLanguage(serverLocale as SupportedLanguages);
        await i18n.changeLanguage(serverLocale as SupportedLanguages);
      }
      if (serverLocale && browserLanguage) {
        if (serverLocale === browserLanguage) {
          return;
        } else {
          toggleLocale();
        }
      }
    })();
    // TODO: not sure if we should add other dependencies
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, [userProfile?.user?.locale]);
};
