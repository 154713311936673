import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTranslateAndSortReceiptTypes() {
  const { t } = useTranslation();
  const translateAndSortReceiptTypes = useCallback(
    ({
      transactionTypes,
    }: {
      transactionTypes: Array<{ id: string; name: string }> | undefined;
    }): Array<{ value: string; label: string }> => {
      const receiptTypes = transactionTypes ? [...transactionTypes] : [];

      // Remove the "Andere" receipt type from the list if present
      const receiptTypeOtherIndex = receiptTypes.findIndex(
        (type) => type.name === 'Andere'
      );

      let receiptTypeOther = null;
      if (receiptTypeOtherIndex >= 0) {
        const spliceResult = receiptTypes.splice(receiptTypeOtherIndex, 1);
        receiptTypeOther =
          spliceResult.length > 0 ? spliceResult[0] : receiptTypeOther;
      }

      // Translate the others and sort the translated list alphabetically
      const translatedReceiptTypes = receiptTypes.map(({ id, name }) => ({
        value: id,
        label: t(`receiptType.${name}`),
      }));

      translatedReceiptTypes.sort((a, b) => a.label.localeCompare(b.label));

      if (receiptTypeOther) {
        translatedReceiptTypes.push({
          value: receiptTypeOther.id,
          label: t(`receiptType.${receiptTypeOther.name}`),
        });
      }
      return translatedReceiptTypes;
    },
    [t]
  );
  return {
    translateAndSortReceiptTypes,
  };
}
