import {
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';
import { FamilyMemberDto } from './FamilyMemberDto';
import { Gender } from './Gender';

export class createResidentFamilyMemberDto extends FamilyMemberDto {
  @IsOptional()
  @IsString()
  username?: string;

  @IsOptional()
  @IsString()
  password?: string;
}

/** @deprecated Use CreateResidentDto instead */
export class CreateResidentWithoutNursingHomeDto {
  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsOptional()
  @IsDate()
  birthdate?: Date;

  @IsEnum(Gender)
  gender!: Gender;

  /** @deprecated Exists for backwards compatibility */
  @IsOptional()
  @IsNumber()
  initial_balance?: number;

  // Either specify initial_balance_in_cent or initial_balance_coins or initial_balance_bank_account.
  @IsOptional()
  @IsInt()
  initial_balance_in_cent?: number;

  // Either specify initial_balance_in_cent or initial_balance_coins or initial_balance_bank_account.
  @IsOptional()
  @IsInt({ each: true })
  initial_balance_coins?: number[];

  // Additional note when providing coins
  @IsOptional()
  @IsString()
  initial_balance_notes?: string;

  // Either specify initial_balance_in_cent or initial_balance_coins or initial_balance_bank_account.
  @IsOptional()
  @IsNumber()
  initial_balance_bank_account?: number;

  @IsOptional()
  @IsUUID(4)
  cashListId?: string;

  @IsNotEmpty()
  @IsDate()
  entryDate!: Date;

  @IsOptional()
  @IsString()
  familyMemberEmail?: string;

  @IsOptional()
  @IsString()
  residentAccountingId?: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => createResidentFamilyMemberDto)
  familyMember?: createResidentFamilyMemberDto;

  @IsOptional()
  @IsUUID('4', { each: true })
  assignedGlobalServiceProviderIds?: string[];
}

/** @deprecated Use CreateResidentDto instead */
export class CreateResidentWithOptionalNursingHomeDto extends CreateResidentWithoutNursingHomeDto {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;
}

export class CreateResidentDto extends CreateResidentWithoutNursingHomeDto {
  @IsString()
  @IsUUID(4)
  nursingHomeId!: string;
}
