export type CashTransactionGroupType =
  | 'Adjustment'
  | 'Deposit'
  | 'ReceiptWithdrawal'
  | 'ResidentDeposit'
  | 'ResidentWithdrawal'
  | 'Transfer';

export const CashTransactionGroupType: {
  [key in CashTransactionGroupType]: key;
} = {
  Adjustment: 'Adjustment',
  Deposit: 'Deposit',
  ReceiptWithdrawal: 'ReceiptWithdrawal',
  ResidentDeposit: 'ResidentDeposit',
  ResidentWithdrawal: 'ResidentWithdrawal',
  Transfer: 'Transfer',
};
