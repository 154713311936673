import { useLocale } from './context/localProvider';
import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormatDate = () => {
  const locale = useLocale();

  return useMemo(() => {
    return (date: Date, format: Intl.DateTimeFormatOptions) => {
      return date.toLocaleDateString(locale, format);
    };
  }, [locale]);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormatTime = () => {
  const locale = useLocale();

  return useMemo(() => {
    return (date: Date, format: Intl.DateTimeFormatOptions) => {
      return date.toLocaleTimeString(locale, format);
    };
  }, [locale]);
};
