import { IsNotEmpty, IsUUID, ValidateIf } from 'class-validator';

export class GetCashTransactionDto {
  @ValidateIf((o) => !o.transactionId)
  @IsNotEmpty()
  @IsUUID(4)
  id?: string;

  @ValidateIf((o) => !o.id)
  @IsNotEmpty()
  @IsUUID(4)
  transactionId?: string;
}
