import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Coin } from '@pflegenavi/shared/api';
import { addMissingFactorsAndSort } from '../cash-management';

export interface CreateCashListTransactionGroupParams {
  body: {
    data: {
      coins?: Coin[];
      notes?: string;
      bank_account_amount?: number;
      type: 'resident deposit';
      resident_id: string;
    };
  };
  params: {
    cashListId: string;
  };
}

export interface ICashManagementPhoenixApi extends Api {
  createCashListTransactionGroup(
    dataIn: CreateCashListTransactionGroupParams
  ): Promise<{
    id: string;
  }>;
}

export class CashManagementPhoenixApi implements ICashManagementPhoenixApi {
  public createCashListTransactionGroup: ICashManagementPhoenixApi['createCashListTransactionGroup'];
  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.createCashListTransactionGroup = modify({
      authContext,
      method: 'POST',
      transformBody: ({ data }) => {
        if (data.coins) {
          return {
            data: {
              ...data,
              coins: addMissingFactorsAndSort(data.coins).map(
                (coin) => coin.amount
              ),
            },
          };
        } else {
          return { data };
        }
      },
      url: ({ cashListId }) =>
        `${baseUrl}/cash-list/${cashListId}/cash-transaction-group`,
    });
  }
}
