import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import type { Coin } from '../GetCashManagementDto';

export class CashListTransferAmountDto {
  @IsOptional()
  @ValidateNested({ each: true })
  coins?: Coin[];

  @IsOptional()
  @IsNumber()
  bankAccountAmount?: number;

  @IsOptional()
  @IsString()
  note?: string;
}

/**
 * This will transfer the specified amount of coins and/or bank account amount.
 *
 * The amount specified by from and to need to be the same amount, but they don't need to be the same type.
 * E.g. it is possible to transfer 5x1 Euro coins to 500 (cents) bank account amount.
 */
export class CashListTransferDto {
  @ValidateNested()
  @IsNotEmpty()
  from!: CashListTransferAmountDto;

  @ValidateNested()
  @IsNotEmpty()
  to!: CashListTransferAmountDto;
}
