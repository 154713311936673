import { IsNotEmpty, IsUUID, IsOptional, IsBoolean } from 'class-validator';

export class PostCashTransactionGroupLinkDto {
  @IsNotEmpty()
  @IsUUID(4)
  cashTransactionGroupId!: string;
  @IsOptional()
  @IsUUID(4)
  /** @deprecated use receiptIds instead */
  receiptId?: string;
  @IsOptional()
  @IsUUID(4)
  /** @deprecated use transactionIds instead */
  transactionId?: string;
  @IsOptional()
  @IsUUID(4, { each: true })
  transactionIds?: string[];
  @IsOptional()
  @IsUUID(4, { each: true })
  receiptIds?: string[];
}

export class PostCashTransactionGroupLinkResultDto {
  @IsOptional()
  @IsBoolean()
  success?: boolean;
}
