import { CashTransactionGroupType } from './CashTransactionGroupType';
import {
  ArrayNotEmpty,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsUUID,
} from 'class-validator';

export class PostCashListTransactionGroupDto {
  @IsEnum(CashTransactionGroupType)
  type!: CashTransactionGroupType;

  @IsOptional()
  @ArrayNotEmpty()
  @IsNumber({}, { each: true })
  coins?: number[];

  @IsOptional()
  @IsNumber()
  bankAccountAmount?: number;

  @IsOptional()
  notes?: string;

  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string;
}
