// eslint-disable-next-line max-classes-per-file
import {
  IsArray,
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
  Max,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import { Type } from 'class-transformer';

export class ReceiptBatchWithoutResident {
  @IsOptional()
  @IsNumber()
  @IsPositive()
  @Max(1000000)
  amount?: number | null;

  @IsOptional()
  @IsString()
  @MaxLength(255)
  notes?: string | null;

  @IsOptional()
  @IsArray()
  receiptImageIds?: string[] | null;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;

  @IsOptional()
  @ValidateNested({ each: true })
  lineItems?: LineItemDto[];
}

export class LineItemDto {
  @IsUUID(4)
  @IsOptional()
  id?: string;

  @IsNumber()
  @IsPositive()
  amountPerItem!: number;

  @IsNumber()
  @IsPositive()
  count!: number;

  @IsString()
  @IsOptional()
  name?: string | undefined;
}

export class CreateReceiptBatchEntryDto extends ReceiptBatchWithoutResident {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  residentId?: string | null; // uuid
}

export class CreateOrUpdateReceiptBatchEntryDto extends CreateReceiptBatchEntryDto {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  id?: string;
}

export class ReceiptBatchEntryDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  residentId?: string; // uuid

  @IsOptional()
  @IsNumber()
  @IsPositive()
  amount?: number;

  @IsOptional()
  @IsString()
  @MaxLength(255)
  notes?: string;

  @IsOptional()
  @IsArray()
  receiptImageIds?: string[];

  @IsOptional()
  @IsDate()
  receiptDate?: Date;
}

export class ExpandedResidentDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  @IsString()
  gender!: string;

  @IsNotEmpty()
  @IsString()
  state!: string;
}

export class ExpandedResidentReceiptBatchEntryDto extends ReceiptBatchWithoutResident {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => ExpandedResidentDto)
  resident?: ExpandedResidentDto;
}

export class UpdateReceiptBatchEntryResultDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  residentId?: string; // uuid

  @IsOptional()
  @IsNumber()
  @IsPositive()
  amount?: number | null;

  @IsOptional()
  @IsString()
  @MaxLength(255)
  notes?: string | null;

  @IsOptional()
  @IsArray()
  receiptImageIds?: string[] | null;

  @ValidateNested()
  @IsOptional()
  @Type(() => ExpandedResidentDto)
  resident?: ExpandedResidentDto;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;
}
