// eslint-disable-next-line max-classes-per-file
import { Type } from 'class-transformer';
import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import type { Gender } from '../Gender';
import { RecurringItemTaxCodeDto } from './taxCode';

export enum RecurringItemCadenceV2 {
  Monthly,
  Weekly,
}

export enum RecurringItemResidentState {
  Active = 'Active',
  Deleting = 'Deleting',
  Settlement = 'Settlement',
}

export class RecurringItemBaseV2Dto {
  @IsString()
  @IsNotEmpty()
  name!: string;

  @IsUUID()
  nursingHomeId!: string;

  @IsBoolean()
  chargeFullPeriod!: boolean;

  @IsBoolean()
  computeDaily!: boolean;

  @IsBoolean()
  autoAddResidents!: boolean;

  @IsBoolean()
  generateReceipts!: boolean;

  @IsEnum(RecurringItemCadenceV2)
  cadence!: RecurringItemCadenceV2;

  /** @deprecated */
  @IsInt()
  amount!: number;

  @IsInt()
  amountInCents!: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => RecurringItemTaxCodeDto)
  taxCode?: RecurringItemTaxCodeDto;
}
export class CreateRecurringItemV2Dto extends RecurringItemBaseV2Dto {}

export class CreateRecurringItemWithResidentsV2Dto extends CreateRecurringItemV2Dto {
  residents!: RecurringItemResidentV2Dto[];
}

export class DeleteRecurringItemV2Dto {
  id!: string;
}

export class SelectRecurringItemsV2Dto {
  nursingHomeId!: string;
}

export class SelectRecurringItemsByResidentV2Dto {
  residentId!: string;
}

export class SelectRecurringItemResidentsV2Dto {
  recurringItemId!: string;
}

export class RecurringItemV2Dto extends RecurringItemBaseV2Dto {
  id!: string;
  numResidents!: number;
}

export class RecurringItemV2WithResidentV2Dto extends RecurringItemBaseV2Dto {
  id!: string;
  resident!: RecurringItemResidentWithoutDetailsV2Dto;
}

export class RecurringItemWithResidentsV2Dto extends RecurringItemBaseV2Dto {
  id!: string;
  residents!: RecurringItemResidentV2Dto[];
}

export class RecurringItemResidentV2Dto {
  residentId!: string;
  firstName!: string;
  lastName!: string;
  entryDate!: Date;
  gender!: Gender;
  startDate!: Date;
  endDate: Date | undefined;
  state!: RecurringItemResidentState;
}

export type RecurringItemResidentWithoutDetailsV2Dto = Omit<
  RecurringItemResidentV2Dto,
  'firstName' | 'lastName' | 'gender' | 'entryDate'
>;

export class AddRecurringItemResidentV2Dto {
  recurringItemId!: string;
  residentId!: string;
  startDate!: Date;
  endDate?: Date;
}

export class UpdateRecurringItemResidentV2Dto {
  recurringItemId!: string;
  residentId!: string;
  startDate?: Date;
  endDate?: Date;
}

export class RemoveRecurringItemResidentV2Dto {
  recurringItemId!: string;
  residentId!: string;
  chargeResident!: boolean;
  computationDate?: Date;
  endDate?: Date;
}

export class RecurringItemTransactionDto {
  transaction!: {
    id: string;
    creationDate: Date;
    /** @deprecated */
    amount: number;
    amountInCents: number;
  };
  recurringItem!: {
    id: string;
    name: string;
  };
  resident!: {
    id: string;
    firstName: string;
    lastName: string;
    gender: string;
  };
  startDate!: Date;
  endDate!: Date;
}

interface RecurringItemTransactionsQueryFiltersInterface {
  /**
   * If not set, it starts at the start of the current month
   */
  dateFrom?: Date;

  /**
   * If not set, it will end at the end of the current month
   */
  dateEnd?: Date;
}

export class RecurringItemTransactionsQueryFilters
  implements RecurringItemTransactionsQueryFiltersInterface
{
  @IsOptional()
  @IsDate()
  @Type(() => Date)
  dateFrom?: Date;

  @IsOptional()
  @IsDate()
  @Type(() => Date)
  dateEnd?: Date;
}
