import {
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';

export enum TransactionType {
  ALL = 'All',
  INITIAL = 'Initial',
  WITHDRAWAL = 'Withdrawal',
  DEPOSIT = 'Deposit',
  CORRECTION = 'Correction',
}

export enum TransactionSourceType {
  InitialDeposit = 'Initial deposit',
  ManualDeposit = 'Manual deposit',
  DirectDebit = 'Direct debit',
  RecurringItem = 'Recurring item',
  Receipt = 'Receipt',
  CancelledReceipt = 'Cancelled receipt',
  Dispute = 'Dispute',
  FinalSettlement = 'Final Settlement',
  CancelledManualDeposit = 'Cancelled manual deposit',
}

export enum TransactionInitiatedBy {
  System = 'system',
  FamilyMember = 'familyMember',
  Employee = 'employee',
}

export class TransactionRecurringItemTransactionDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsString()
  name!: string;
}

export class TransactionResidentDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;
}

export class GetTransactionsWithReceiptDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsEnum(TransactionType)
  type!: TransactionType;

  sourceType!: TransactionSourceType;
  @IsNotEmpty()
  @ValidateNested()
  resident!: TransactionResidentDto;

  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  receiptId!: string;

  @IsOptional()
  @IsString()
  receiptType?: string;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;

  @IsOptional()
  @IsDate()
  receiptCreatedOn?: Date;

  @IsOptional()
  @IsString()
  serviceProvider?: string;

  /** @deprecated */
  @IsNotEmpty()
  @IsNumber({ maxDecimalPlaces: 2 })
  @IsPositive()
  amount!: number;

  @IsNotEmpty()
  @IsNumber()
  @IsPositive()
  amountInCent!: number;

  /** Submission date */
  @IsNotEmpty()
  @IsDate()
  date!: Date;

  @IsNotEmpty()
  @IsString()
  creatorName!: string;

  @IsOptional()
  @IsString({ each: true })
  @IsUUID(4, { each: true })
  receiptImageIds?: string[];

  receipt_not_available!: boolean;

  @IsOptional()
  @IsString()
  notes?: string;

  @IsBoolean()
  cancelled!: boolean;

  @IsBoolean()
  disputed!: boolean;

  @IsOptional()
  @ValidateNested()
  recurringItem?: TransactionRecurringItemTransactionDto;

  @IsOptional()
  @IsDate()
  cancelledSubmissionDate?: Date;

  @IsEnum(TransactionInitiatedBy)
  initiatedBy!: TransactionInitiatedBy;

  @IsOptional()
  @IsString()
  ibanLast4?: string;

  @IsString()
  sequenceID!: string;
}
