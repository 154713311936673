export function makeSafeFilename(filename: string): string {
  // Replace Umlauts
  filename = filename
    .replace(/ä/g, 'ae')
    .replace(/Ä/g, 'Ae')
    .replace(/ö/g, 'oe')
    .replace(/Ö/g, 'Oe')
    .replace(/ü/g, 'ue')
    .replace(/Ü/g, 'Ue')
    .replace(/ß/g, 'ss');

  // Remove non-ASCII and special characters
  // eslint-disable-next-line no-control-regex
  filename = filename.replace(/[^\x00-\x7F]|[/\\:*?"<>|]/g, '');

  // Remove '.' if it is the first character
  if (filename[0] === '.') {
    filename = filename.substring(1);
  }

  return filename;
}
