import type { ICashListConfigurationContext } from './CashListConfigurationContext';
import { CashListConfigurationContext } from './CashListConfigurationContext';
import type { FC, PropsWithChildren } from 'react';
import { useMemo, useState } from 'react';
import { useGetCashListConfiguration } from './index';
import { CashListAccountType } from '@pflegenavi/shared/api';

interface CashListConfigurationProviderProps {
  useNursingHomeContext: () => {
    selectedNursingHome?: {
      id: string;
    };
  };
}

export const CashListConfigurationProvider: FC<
  PropsWithChildren<CashListConfigurationProviderProps>
> = ({ children, useNursingHomeContext }) => {
  const { selectedNursingHome } = useNursingHomeContext();
  const { data } = useGetCashListConfiguration(selectedNursingHome?.id);

  const [selectedCashListId, setSelectedCashListId] = useState<
    string | undefined
  >(undefined);

  const value = useMemo<ICashListConfigurationContext>(() => {
    const operationalCashListId = data?.cashListConfigurations?.find(
      (cashList) => cashList.type === CashListAccountType.Operational
    )?.cashListId;
    return {
      // Fall back to operationalCashListId if none was selected
      selectedCashListId: selectedCashListId ?? operationalCashListId,
      cashLists:
        data?.cashListConfigurations?.map((cashList) => ({
          id: cashList.cashListId,
          name: cashList.name,
          type: cashList.type,
          storageType: cashList.storageType,
          totalInCent: cashList.totalInCent,
          bankAccountAmountInCent: cashList.bankAccountAmountInCent,
          coins: cashList.coins,
          currency: cashList.currency,
        })) ?? [],
      setSelectedCashListId,
      payoutTargetCashListId: data?.payoutTargetCashListId,
      payinTargetCashListId: data?.payinTargetCashListId,
      serviceProviderPaymentTargetCashListId:
        data?.serviceProviderPaymentTargetCashListId,
    };
  }, [
    data?.cashListConfigurations,
    data?.payoutTargetCashListId,
    data?.serviceProviderPaymentTargetCashListId,
    selectedCashListId,
  ]);

  return (
    <CashListConfigurationContext.Provider value={value}>
      {children}
    </CashListConfigurationContext.Provider>
  );
};
