import type {
  QueryClient,
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import type { ApiProviderProps } from '@pflegenavi/shared-frontend/platform';
import {
  makeApiProvider,
  useApiQuery,
  usePrefetchApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  CountryDto,
  ExitResidentDto,
  GetResidentsQueryParams,
  NursingHomeDto,
  PutResidentServiceProvidersDto,
  RecurringItemTaxCodeDto,
  RecurringItemV2Dto,
  RecurringItemV2WithResidentV2Dto,
  RecurringItemWithResidentsV2Dto,
  Resident,
  ResidentConfiguration,
  ResidentListItemDto,
  ResidentListItemV2Dto,
  ServiceProviderListDto,
  SettlementDto,
  SettlementFormDataDto,
  UpdateNursingHomeDto,
  UpdateNursingHomePaymentSettingsDto,
  UpdateResidentCashPayerStateDto,
  UpdateResidentConfigurationDto,
  UpdateResidentDto,
} from '@pflegenavi/shared/api';
import { ResidentState } from '@pflegenavi/shared/api';
import type { FC } from 'react';
import { createContext, useCallback } from 'react';
import type { IResidentApi } from './api';
import { ResidentApi } from './api';
import isEqual from 'lodash.isequal';
import { parseISO } from 'date-fns';
import { getTransactionsKey, TRANSACTIONS_PAGINATED_KEY } from '../transaction';
import type { PaginatedResultSubset } from '@pflegenavi/shared/utils';
import { invalidateAllResidentBalance } from '../reporting';
import { USER_PROFILE_KEY } from '../user-profile';
import { useAuthentication } from '@pflegenavi/frontend/authentication';
import { invalidateCashList } from '../cash-management';

export {
  isDuplicateResidentAccountingIdError,
  isStartDateAfterExitDateError,
} from './api';

export type { IResidentApi } from './api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const invalidateResidentsKeys = (
  client: QueryClient,
  nursingHomeId: string | undefined
) => {
  return Promise.all([
    client.invalidateQueries(RESIDENTS_KEY(nursingHomeId)),
    client.invalidateQueries(RESIDENTS_KEY2(nursingHomeId)),
  ]);
};

const ApiContext = createContext<IResidentApi | undefined>(undefined);
const { useApi: useResidentApi, ApiProvider } = makeApiProvider({
  name: 'Resident',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => new ResidentApi(tenantId, auth, apiUrl),
});

const ResidentApiProvider: FC<ApiProviderProps> = ({ children, ...props }) => {
  return <ApiProvider {...props}>{children}</ApiProvider>;
};

export {
  useResidentApi,
  ResidentApiProvider,
  ApiContext as ResidentApiContext,
  ResidentApi,
};

export const invalidatePendingReceiptCount = (
  queryClient: QueryClient
): Promise<void> => {
  return queryClient.invalidateQueries({
    // eslint-disable-next-line complexity
    predicate: (query) => {
      const pendingReceiptCount =
        Array.isArray(query.queryKey) &&
        query.queryKey[2] !== undefined &&
        typeof query.queryKey[2] === 'object' &&
        'pendingReceiptCount' in query.queryKey[2] &&
        query.queryKey[2]?.['pendingReceiptCount'];
      return (
        query.queryKey[0] === RESIDENTS_KEY('')[0] &&
        pendingReceiptCount === true
      );
    },
  });
};

export const RESIDENTS_KEY = (
  nursingHomeId: string | undefined,
  states?: { [key in ResidentState]?: boolean },
  additionalFields?: {
    [key: string]: any;
  }
): QueryKey => {
  return [
    'residents-list',
    nursingHomeId,
    {
      ...(states ? { states } : {}),
      ...(additionalFields ? { additionalFields } : {}),
    },
  ];
};

export const RESIDENTS_KEY2 = (
  nursingHomeId: string | undefined,
  additionalFields?: {
    [key: string]: any;
  }
): QueryKey => {
  return ['residents2-list', nursingHomeId, { ...additionalFields }];
};

export const getResidentKey = (id: string): string[] => ['residents', id];
const UPDATE_RESIDENT_KEY = 'update-resident';
const EXIT_RESIDENT_KEY = 'exit-resident';
const ARCHIVE_RESIDENT_KEY = 'archive-resident';

const GET_RECURRING_ITEMS = 'get-recurring-items';
export const GET_RECURRING_ITEMS_KEY = (
  nursingHomeId: string | undefined
): QueryKey => [GET_RECURRING_ITEMS, nursingHomeId];

const GET_COUNTRIES_KEY = 'get-countries';
const GET_TAX_CODES_BY_COUNTRY = (countryId: string | undefined) => [
  'get-tax-codes-by-country',
  countryId,
];

export const GET_RECURRING_ITEM_RESIDENTS_KEY = (
  recurringItemId: string
): QueryKey => `get-recurring-item-residents-${recurringItemId}`;

const GET_RECURRING_ITEMS_BY_RESIDENT_ID_KEY = (residentId: string) =>
  `get-recurring-items-${residentId}`;
const REMOVE_RECURRING_ITEM_KEY = 'remove-recurring-charge';
const ADD_RESIDENT_RECURRING_ITEM_KEY = 'add-resident-recurring-item';
const UPDATE_RESIDENT_START_DATE_KEY =
  'recurring-item-update-resident-start-date';
const getResidentConfigurationKey = (id: string) =>
  `get-resident-configuration-${id}`;
const UPDATE_RESIDENT_CONFIGURATION_KEY = 'update-resident-configuration';

const UPDATE_RESIDENT_CASH_PAYER_STATUS_KEY =
  'update-resident-cash-payer-status';

const NURSING_HOME_KEY = (nursingHomeId: string | undefined) => [
  'nursing-home',
  nursingHomeId,
];
const PATCH_NURSING_HOME_PAYMENT_SETTINGS_KEY = (nursingHomeId?: string) => [
  'patch-nursing-home-payment-settings',
  nursingHomeId,
];
export const RESIDENT_SETTLEMENT_KEY = (id: string): QueryKey =>
  `resident-settlement-${id}`;

const UPDATE_NURSING_HOME_KEY = 'update-nursing-home';

const PROCEED_TO_AWAITING_SETTLEMENT_KEY = (residentId: string) =>
  `proceed-to-awaiting-settlement-${residentId}`;
const GO_BACK_TO_EXITED_KEY = (residentId: string) =>
  `go-back-to-exited-${residentId}`;

const RESIDENT_SERVICE_PROVIDERS_LIST_KEY = (id: string) =>
  `resident-service-providers-list-${id}`;
const PUT_RESIDENT_SERVICE_PROVIDERS_LIST_KEY = `put-resident-service-providers-list`;

/**
 * Invalidate all resident lists manually. The RESIDENTS_KEY and RESIDENTS_KEY2 key generators don't
 * properly work when the nursing home is undefined, since it will create ['residents-list', undefined] and that matches no key.
 * RESIDENTS_KEY and RESIDENTS_KEY2 was not modified to avoid unnecessary invalidations, as currently at startup
 * nursing_home_id is undefined and we don't want to trigger invalidations at startup.
 */
const invalidateAllResidentLists = (client: QueryClient) => {
  return Promise.all([
    client.invalidateQueries('residents-list'),
    client.invalidateQueries('residents2-list'),
  ]);
};

export const invalidateResident = async (
  client: QueryClient,
  id: string,
  nursingHomeId: string | undefined
): Promise<void> => {
  await Promise.all([
    client.invalidateQueries(getResidentKey(id)),
    // Also invalidate resident lists
    ...(nursingHomeId
      ? [invalidateResidentsKeys(client, nursingHomeId)]
      : [invalidateAllResidentLists(client)]),
  ]);
};

const selectResidents = (
  data: ResidentListItemDto[]
): ResidentListItemDto[] => {
  // eslint-disable-next-line complexity
  return data.map((resident) => ({
    ...resident,
    threshold_due_date:
      resident.threshold_due_date && new Date(resident.threshold_due_date),
    negative_due_date:
      resident.negative_due_date && new Date(resident.negative_due_date),
    entry_date: resident.entry_date && new Date(resident.entry_date),
    exit_date: resident.exit_date && new Date(resident.exit_date),
    settlement_date:
      resident.settlement_date && new Date(resident.settlement_date),
  }));
};

const selectResidentsV2 = (
  data: PaginatedResultSubset<ResidentListItemV2Dto>
): PaginatedResultSubset<ResidentListItemV2Dto> => {
  // eslint-disable-next-line complexity
  const newData = data.data.map((resident) => ({
    ...resident,
    birthdate: resident.birthdate && new Date(resident.birthdate),
    entryDate: resident.entryDate && new Date(resident.entryDate),
    exitDate: resident.exitDate && new Date(resident.exitDate),
    negativeDueDate:
      resident.negativeDueDate && new Date(resident.negativeDueDate),
    thresholdDueDate:
      resident.thresholdDueDate && new Date(resident.thresholdDueDate),
    settlementDate:
      resident.settlementDate && new Date(resident.settlementDate),
  }));
  return {
    ...data,
    data: newData,
  };
};

const RESIDENT_STALE_TIME = 1000 * 60 * 5; // 5 minutes

/**
 * Heavily based on useResidents. Since we need to add additionalFields without breaking other queries
 * @param nursingHomeId
 * @param options
 */
export const useAllResidents = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<ResidentListItemDto[]>
): UseQueryResult<ResidentListItemDto[]> => {
  const result = useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(
      nursingHomeId,
      {},
      {
        additionalFields: {
          residentPaymentInfo: true,
        },
      }
    ),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId, [], { residentPaymentInfo: true });
    },
    {
      enabled: nursingHomeId !== undefined,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select: selectResidents,
    }
  );

  return result;
};

export const useResidents = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<ResidentListItemDto[]>
): UseQueryResult<ResidentListItemDto[]> => {
  const result = useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId, []);
    },
    {
      enabled: nursingHomeId !== undefined,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select: selectResidents,
    }
  );

  return result;
};

export const useResidentsPaginated = (
  data: GetResidentsQueryParams,
  page: number,
  pageSize: number,
  options?: UseQueryOptions<PaginatedResultSubset<ResidentListItemV2Dto>>
): UseQueryResult<PaginatedResultSubset<ResidentListItemV2Dto>> => {
  return useApiQuery(
    useResidentApi,
    RESIDENTS_KEY2(data.nursingHomeId, {
      ...data,
      page,
      pageSize,
    }),
    (api) => {
      if (!data.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getPaginated(data, page, pageSize);
    },
    {
      enabled: data.nursingHomeId !== undefined,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select: selectResidentsV2,
    }
  );
};

export const useActiveResidents = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<ResidentListItemDto[]>
): UseQueryResult<ResidentListItemDto[]> => {
  const select = useCallback((rows: ResidentListItemDto[]) => {
    const states = [ResidentState.active];
    return selectResidents(rows.filter((row) => states.includes(row.state)));
  }, []);

  return useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId);
    },
    {
      enabled: nursingHomeId !== undefined,
      refetchOnWindowFocus: false,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select,
    }
  );
};

export const useInactiveResidents = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<ResidentListItemDto[]>
): UseQueryResult<ResidentListItemDto[]> => {
  return useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId, { [ResidentState.archived]: true }),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId, [ResidentState.archived]);
    },
    {
      enabled: nursingHomeId !== undefined,
      staleTime: RESIDENT_STALE_TIME * 2,
      ...options,
      select: selectResidents,
    }
  );
};

export const useActiveAndExitedResidents = (
  nursingHomeId: string | undefined,
  options?: UseQueryOptions<ResidentListItemDto[]>,
  additionalFields?: {
    pendingReceiptCount?: boolean;
  }
): UseQueryResult<ResidentListItemDto[]> => {
  const select = useCallback((rows: ResidentListItemDto[]) => {
    const states = [ResidentState.active, ResidentState.exited];
    return selectResidents(rows.filter((row) => states.includes(row.state)));
  }, []);

  return useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId, undefined, additionalFields),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId, [], additionalFields);
    },
    {
      enabled: nursingHomeId !== undefined,
      refetchOnWindowFocus: false,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select,
    }
  );
};

export const useResident = (
  id: string,
  options?: UseQueryOptions<Resident | undefined>
): UseQueryResult<Resident | undefined> => {
  const select = useCallback((data: Resident | undefined) => {
    if (!data) {
      return undefined;
    }
    return {
      ...data,
      threshold_due_date:
        data.threshold_due_date && new Date(data.threshold_due_date),
      negative_due_date:
        data.negative_due_date && new Date(data.negative_due_date),
      // TODO We need an intermediate type where dates have not been converted
      exit_date: parseISO(data.exit_date as unknown as string),
      entry_date: parseISO(data.entry_date as unknown as string),
      created_date: parseISO(data.created_date as unknown as string),
    };
  }, []);

  return useApiQuery(
    useResidentApi,
    getResidentKey(id),
    (api) => api.getOne(id),
    {
      ...options,
      select,
    }
  );
};

export const usePrefetchResident = (residentId: string | undefined): void => {
  const getResident = useCallback(
    (api: IResidentApi) => {
      return api.getOne(residentId ?? '');
    },
    [residentId]
  );
  return usePrefetchApiQuery(
    useResidentApi,
    getResidentKey(residentId ?? ''),
    getResident,
    residentId !== undefined
  );
};

export const useResidentFromList = (
  id: string,
  nursingHomeId: string | undefined
): UseQueryResult<ResidentListItemDto | undefined> => {
  const select = useCallback(
    (data: ResidentListItemDto[]) => {
      const resident = data?.find((resident) => resident.id === id);
      if (resident) {
        return selectResidents([resident])[0];
      }
      return undefined;
    },
    [id]
  );

  return useApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId);
    },
    {
      select,
      staleTime: RESIDENT_STALE_TIME,
      enabled: nursingHomeId !== undefined,
      refetchOnWindowFocus: false,
    }
  );
};

export const usePrefetchResidents = (
  nursingHomeId: string | undefined
): void => {
  const getResident = useCallback(
    (api: IResidentApi) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get(nursingHomeId);
    },
    [nursingHomeId]
  );
  return usePrefetchApiQuery(
    useResidentApi,
    RESIDENTS_KEY(nursingHomeId),
    getResident,
    nursingHomeId !== undefined
  );
};

export const useUpdateResident = (): UseMutationResult<
  Resident,
  unknown,
  UpdateResidentDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<Resident, unknown, UpdateResidentDto>(
    UPDATE_RESIDENT_KEY,
    (data) => api.update(data),
    {
      onSuccess: (_result, data) => {
        return Promise.all([
          invalidateResidentsKeys(queryClient, _result.nursingHome.id),
          invalidateResident(
            queryClient,
            data.residentId,
            _result.nursingHome.id
          ),
        ]);
      },
    }
  );
};

export const useRecurringItems = (
  nursingHomeId: string | undefined
): UseQueryResult<RecurringItemV2Dto[]> => {
  return useApiQuery(
    useResidentApi,
    GET_RECURRING_ITEMS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getRecurringItems({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useRecurringItemResidents = (
  recurringItemId: string
): UseQueryResult<RecurringItemWithResidentsV2Dto> => {
  const select = useCallback((result: RecurringItemWithResidentsV2Dto) => {
    return {
      ...result,
      residents: result.residents.map((row: any) => ({
        ...row,
        startDate: parseISO(row.startDate),
        endDate: parseISO(row.endDate),
        entryDate: parseISO(row.entryDate),
      })),
    };
  }, []);

  return useApiQuery(
    useResidentApi,
    GET_RECURRING_ITEM_RESIDENTS_KEY(recurringItemId),
    (api) => api.getRecurringItemResidents({ params: { recurringItemId } }),
    {
      refetchOnWindowFocus: false,
      select,
    }
  );
};

export const useRecurringItemsByResident = (
  residentId: string
): UseQueryResult<RecurringItemV2WithResidentV2Dto[]> => {
  return useApiQuery(
    useResidentApi,
    GET_RECURRING_ITEMS_BY_RESIDENT_ID_KEY(residentId),
    (api) => api.getRecurringItemsByResident({ params: { residentId } }),
    {
      isDataEqual: isEqual,
    }
  );
};

export const useRecurringItemFromList = (
  id: string,
  nursingHomeId: string | undefined
): UseQueryResult<RecurringItemV2Dto | undefined> => {
  const select = useCallback(
    (data: RecurringItemV2Dto[]) => {
      return data.find((row) => row.id === id);
    },
    [id]
  );
  return useApiQuery(
    useResidentApi,
    GET_RECURRING_ITEMS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getRecurringItems({ params: { nursingHomeId } });
    },
    {
      select,
      enabled: nursingHomeId !== undefined,
      refetchOnWindowFocus: false,
    }
  );
};

export const useDeleteRecurringItem = (): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    recurringItemId: string;
  }
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      recurringItemId: string;
    }
  >(
    REMOVE_RECURRING_ITEM_KEY,
    (data) =>
      api.deleteRecurringItem({
        params: { recurringItemId: data.recurringItemId },
      }),
    {
      onSuccess: async () => {
        return await queryClient.invalidateQueries(GET_RECURRING_ITEMS);
      },
    }
  );
};

export const useUpdateResidentStartDate = (): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    recurringItemId: string;
    residentId: string;
    startDate: Date;
  }
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      recurringItemId: string;
      residentId: string;
      startDate: Date;
    }
  >(
    UPDATE_RESIDENT_START_DATE_KEY,
    (data) =>
      api.updateResidentStartDate({
        params: {
          recurringItemId: data.recurringItemId,
          residentId: data.residentId,
        },
        body: {
          startDate: data.startDate,
        },
      }),
    {
      onSuccess: (_data, props) => {
        return Promise.all([
          queryClient.invalidateQueries(GET_RECURRING_ITEMS),
          queryClient.invalidateQueries(
            GET_RECURRING_ITEM_RESIDENTS_KEY(props.recurringItemId)
          ),
          queryClient.invalidateQueries(
            GET_RECURRING_ITEMS_BY_RESIDENT_ID_KEY(props.residentId)
          ),
        ]);
      },
    }
  );
};

export const useAddResidentToRecurringItem = (): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    recurringItemId: string;
    residentId: string;
    startDate: Date;
    endDate?: Date;
  }
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      recurringItemId: string;
      residentId: string;
      startDate: Date;
      endDate?: Date;
    }
  >(
    ADD_RESIDENT_RECURRING_ITEM_KEY,
    (data) =>
      api.addResidentToRecurringItem({
        params: {
          recurringItemId: data.recurringItemId,
        },
        body: {
          residentId: data.residentId,
          startDate: data.startDate,
          endDate: data.endDate,
        },
      }),
    {
      onSuccess: async (_data, props) => {
        await queryClient.invalidateQueries(GET_RECURRING_ITEMS);

        await queryClient.invalidateQueries(
          GET_RECURRING_ITEM_RESIDENTS_KEY(props.recurringItemId)
        );
      },
    }
  );
};

export const useGetCountries = (
  options?: UseQueryOptions<{
    data: CountryDto[];
  }>
): UseQueryResult<{
  data: CountryDto[];
}> => {
  return useApiQuery(
    useResidentApi,
    GET_COUNTRIES_KEY,
    (api) => {
      return api.getCountries();
    },
    options
  );
};

export const useGetTaxCodesByCountry = (
  countryId: string | undefined
): UseQueryResult<{
  data: RecurringItemTaxCodeDto[];
}> => {
  return useApiQuery(
    useResidentApi,
    GET_TAX_CODES_BY_COUNTRY(countryId),
    (api) => {
      if (!countryId) {
        throw new Error('countryId is required');
      }
      return api.getTaxCodesByCountry({ params: { countryId } });
    },
    {
      enabled: countryId !== undefined,
    }
  );
};

export const useResidentConfiguration = (
  id: string
): UseQueryResult<ResidentConfiguration | undefined> => {
  return useApiQuery(useResidentApi, getResidentConfigurationKey(id), (api) =>
    api.getConfiguration(id)
  );
};

export const useUpdateResidentConfiguration = (): UseMutationResult<
  ResidentConfiguration,
  unknown,
  UpdateResidentConfigurationDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    ResidentConfiguration,
    unknown,
    UpdateResidentConfigurationDto
  >(
    UPDATE_RESIDENT_CONFIGURATION_KEY,
    (data) => api.updateConfiguration(data),
    {
      onSuccess: (_result, data) => {
        return Promise.all([
          invalidateResident(
            queryClient,
            data.resident_id,
            _result.nursingHomeId
          ),
          queryClient.invalidateQueries(
            getResidentConfigurationKey(data.resident_id)
          ),
        ]);
      },
    }
  );
};

export const useUpdateResidentCashPayerStatus = (
  residentId: string,
  nursingHomeId?: string
): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  UpdateResidentCashPayerStateDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    UpdateResidentCashPayerStateDto
  >(
    UPDATE_RESIDENT_CASH_PAYER_STATUS_KEY,
    (data) => {
      if (!nursingHomeId) {
        throw Error('nursingHomeId is undefined');
      }
      return api.updateResidentCashPayerStatus(residentId, data);
    },
    {
      onSuccess: () => {
        return Promise.all([
          invalidateResident(queryClient, residentId, nursingHomeId),
          invalidateResidentsKeys(queryClient, nursingHomeId),
        ]);
      },
    }
  );
};

export const useNursingHome = (
  nursingHomeId: string | undefined
): UseQueryResult<NursingHomeDto> => {
  return useApiQuery(
    useResidentApi,
    NURSING_HOME_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw Error('nursingHomeId is undefined');
      }

      return api.getNursingHome(nursingHomeId);
    },
    {
      enabled: nursingHomeId !== undefined,
      select: (data) => ({
        ...data,
        paymentsDisabledUntil:
          data.paymentsDisabledUntil && new Date(data.paymentsDisabledUntil),
      }),
    }
  );
};

export const usePatchNursingHomePaymentSettings = (
  nursingHomeId: string | undefined
): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  UpdateNursingHomePaymentSettingsDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  const { user } = useAuthentication();

  return useMutation<
    {
      success: boolean;
    },
    unknown,
    UpdateNursingHomePaymentSettingsDto
  >(
    PATCH_NURSING_HOME_PAYMENT_SETTINGS_KEY(nursingHomeId),
    (data) => {
      if (!nursingHomeId) {
        throw Error('nursingHomeId is undefined');
      }
      return api.patchNursingHomePaymentSettings({
        params: {
          nursingHomeId: nursingHomeId,
        },
        body: data,
      });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(NURSING_HOME_KEY(nursingHomeId));
        // We need to invalidate the user profile because it fetches the nursing home data, and e.g. the indicator on the
        // side menu (that shows if payments are deactivated) depends on that nursing home data it fetches.
        await queryClient.invalidateQueries(
          USER_PROFILE_KEY(user?.userId ?? '')
        );
      },
    }
  );
};

export const useUpdateNursingHome = (): UseMutationResult<
  NursingHomeDto,
  unknown,
  UpdateNursingHomeDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<NursingHomeDto, unknown, UpdateNursingHomeDto>(
    UPDATE_NURSING_HOME_KEY,
    (data) => api.updateNursingHome(data),
    {
      onSuccess: (result) => {
        return queryClient.invalidateQueries(NURSING_HOME_KEY(result.id));
      },
    }
  );
};

export const useProceedToAwaitingSettlement = (
  residentId: string
): UseMutationResult<void, unknown, void> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, void>(
    PROCEED_TO_AWAITING_SETTLEMENT_KEY(residentId),
    () => api.proceedToAwaitingSettlement(residentId),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(RESIDENT_SETTLEMENT_KEY(residentId)),
          invalidateResident(queryClient, residentId, undefined),
        ]);
      },
    }
  );
};

export const useGoBackToExited = (
  residentId: string
): UseMutationResult<void, unknown, void> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, void>(
    GO_BACK_TO_EXITED_KEY(residentId),
    () => api.goBackToExited(residentId),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(RESIDENT_SETTLEMENT_KEY(residentId)),
          invalidateResident(queryClient, residentId, undefined),
        ]);
      },
    }
  );
};

export const useExitResident = (
  residentId: string
): UseMutationResult<void, unknown, ExitResidentDto> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, ExitResidentDto>(
    EXIT_RESIDENT_KEY,
    (data) =>
      api.exitResident({
        body: data,
        params: { residentId },
      }),
    {
      onSuccess: async () => {
        return await Promise.all([
          queryClient.invalidateQueries(RESIDENT_SETTLEMENT_KEY(residentId)),
          invalidateResident(queryClient, residentId, undefined),
        ]);
      },
    }
  );
};

export const useResidentSettlement = (
  residentId: string,
  options?: UseQueryOptions<SettlementDto>
): UseQueryResult<SettlementDto> => {
  return useApiQuery(
    useResidentApi,
    RESIDENT_SETTLEMENT_KEY(residentId),
    (api) =>
      api.getResidentSettlement({
        params: { residentId },
        body: undefined,
      }),
    options
  );
};

export const useArchiveResident = (
  residentId: string,
  nursingHomeId?: string
): UseMutationResult<void, unknown, SettlementFormDataDto> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, SettlementFormDataDto>(
    ARCHIVE_RESIDENT_KEY,
    async (body) => {
      await api.archiveResident({
        body,
        params: { residentId },
      });
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(RESIDENT_SETTLEMENT_KEY(residentId)),
          queryClient.invalidateQueries(getTransactionsKey(residentId)),
          queryClient.invalidateQueries(
            TRANSACTIONS_PAGINATED_KEY({ nursingHomeId: nursingHomeId ?? '' })
          ),
          invalidateAllResidentBalance(queryClient, nursingHomeId),
          invalidateCashList(queryClient, nursingHomeId),
          invalidateResident(queryClient, residentId, nursingHomeId),
        ]);
      },
    }
  );
};

export const useGetServiceProviderListForResident = (
  residentId: string,
  options?: UseQueryOptions<ServiceProviderListDto[]>
): UseQueryResult<ServiceProviderListDto[]> => {
  return useApiQuery(
    useResidentApi,
    RESIDENT_SERVICE_PROVIDERS_LIST_KEY(residentId),
    (api) =>
      api.getServiceProviderListForResident({
        params: { residentId },
      }),
    options
  );
};

export const usePutResidentServiceProvidersList = ({
  residentId,
}: {
  residentId: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  PutResidentServiceProvidersDto
> => {
  const api = useResidentApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    PutResidentServiceProvidersDto
  >(
    PUT_RESIDENT_SERVICE_PROVIDERS_LIST_KEY,
    (data) =>
      api.putServiceProviderListForResident({
        params: {
          residentId,
        },
        body: data,
      }),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(
          RESIDENT_SERVICE_PROVIDERS_LIST_KEY(residentId)
        );
      },
    }
  );
};

export type { Resident };
