import { CashManagementTransactionType } from './CashManagementTransactionType';
import {
  IsArray,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class PostCashManagementDto {
  @IsOptional()
  @IsArray()
  @IsInt({ each: true })
  coins?: number[];
  @IsOptional()
  @IsInt()
  bankAccountAmount?: number;
  @IsOptional()
  @IsUUID(4)
  resident_id?: string;
  @IsEnum(CashManagementTransactionType)
  type!: CashManagementTransactionType;
  @IsOptional()
  @IsString()
  notes?: string;
  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string;
}
