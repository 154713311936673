import {
  IsArray,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  MaxLength,
} from 'class-validator';

export class CancelManualDepositDto {
  @IsOptional()
  @IsString()
  @MaxLength(255)
  note?: string;
  @IsOptional()
  @IsArray()
  @IsNumber({}, { each: true })
  @Max(0, { each: true })
  coins?: number[];
  @IsOptional()
  @IsNumber()
  @Max(0)
  bankAccountAmount?: number;
  /**
   * The id of the cash list if it needs to be created
   * e.g. when the manual deposit was not created with a cash transaction.
   *
   * Usually, when cancelling the cash list is determined based on the
   * cash transaction group that is associated with the deposit.
   */
  @IsOptional()
  @IsUUID(4)
  cashListId: string | undefined;
}
