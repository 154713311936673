import { createContext, useContext } from 'react';

interface ClientStoredResourcesContextProps {
  set: (key: string, value: string) => Promise<void> | void;
  get: (key: string) => string | Promise<string | null> | null;
  remove: (key: string) => void;
  /**
   * Register a resource to be cleared when clearAll is called.
   * please make sure this is called, otherwise the resource will not be cleared.
   * Be aware that code splitting might cause issues with this, by code not being run.
   * @param name: the name of the resource to be cleared.
   */
  registerResource: (name: string) => void;
  clearAll: () => Promise<void>;
}

export const ClientStoredResourcesContext = createContext<
  ClientStoredResourcesContextProps | undefined
>(undefined);

export const useClientStoredResourcesContext =
  (): ClientStoredResourcesContextProps => {
    const context = useContext(ClientStoredResourcesContext);
    if (!context) {
      throw new Error('Missing Client stored resources!');
    }
    return context;
  };
