import type { ReceiptBatchEntryDto } from './receiptBatchEntryDto';
import {
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';

export class ReceiptBatchJob {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsString()
  status!: 'pending' | 'partial' | 'successful' | 'failed';

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  pending?: ReceiptBatchEntryDto[];

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  successful?: ReceiptBatchEntryDto[];

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  errors?: ReceiptBatchEntryDto[];

  @IsOptional()
  @IsString()
  error?: string;
}

/**
 * Used to define if receipts are only created or also submitted
 *
 * @see CreateReceiptBatchJobHandler
 */
export enum CreateReceiptBatchJobAction {
  Submit = 'submit',
}

export class CreateReceiptBatchJobParams {
  /**
   * By default, receipts are only created, but not submitted.
   * If set to `submit`, the receipts are also submitted.
   */
  @IsOptional()
  @IsEnum(CreateReceiptBatchJobAction)
  action?: CreateReceiptBatchJobAction = CreateReceiptBatchJobAction.Submit;
}
