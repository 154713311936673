// eslint-disable-next-line max-classes-per-file
import {
  IsDate,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class InviteEmployeeDto {
  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @IsNotEmpty()
  @IsUUID()
  assignedNursingHomeId!: string;
}

export enum EmployeeState {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
}

export class EmployeeListItemDto {
  @IsNotEmpty()
  @IsUUID()
  id!: string;

  @IsNotEmpty()
  @IsString()
  firstName?: string;

  @IsNotEmpty()
  @IsString()
  lastName?: string;

  @IsNotEmpty()
  @IsEmail()
  email?: string;

  @IsNotEmpty()
  @IsEnum(EmployeeState)
  state!: EmployeeState;

  @IsNotEmpty()
  @IsUUID()
  assignedNursingHomeId!: string;

  @IsNotEmpty()
  @IsDate()
  creationDate!: Date;

  @IsNotEmpty()
  @IsDate()
  lastUpdateDate!: Date;
}

export class PatchEmployeeDto {
  @IsOptional()
  @IsString()
  firstName?: string;

  @IsOptional()
  @IsString()
  lastName?: string;

  @IsOptional()
  @IsEmail()
  email?: string;
}

export class SyncKeycloakEmployeeDataDto {
  @IsNotEmpty()
  @IsUUID()
  id!: string;

  @IsNotEmpty()
  @IsUUID()
  nursingHomeId!: string;

  @IsNotEmpty()
  @IsString()
  firstName!: string;

  @IsNotEmpty()
  @IsString()
  lastName!: string;

  @IsNotEmpty()
  @IsEmail()
  email!: string;
}

export class UpdateEmployeeState {
  @IsNotEmpty()
  @IsEnum(['Active', 'Inactive'])
  state!: 'Active' | 'Inactive';

  @IsNotEmpty()
  @IsUUID()
  nursingHomeId!: string;
}
