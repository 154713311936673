import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  defaultHeaders,
  get,
  getPhoenixApiTenantUrl,
  modify,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

export interface ReceiptBatchEntry {
  id: string;
  resident: {
    id: string;
    firstname: string;
    lastname: string;
    gender: string;
    state: string;
  };
  receipt_image_ids: string[];
  receipt_date?: Date;
  amount: number;
  notes: string;
}

export interface ReceiptBatch {
  id: string;
  created_on: Date;
  updated_on: Date;
  receipt_date?: Date;
  receipt_image_ids?: string[];
  receipt_type: string;
  service_provider: string;
  cash_list_transaction_group_id?: string;
  receipt_batch_entries: ReceiptBatchEntry[];
  nursing_home_id: string;
  owner: 'nursing_home' | 'service_provider';
  submission_locked: boolean;
  use_individual_dates: boolean;
}

export interface UpdateReceiptBatchEntry {
  id?: string;
  resident_id?: string | undefined;
  receipt_image_ids?: string[];
  receipt_date?: Date;
  amount?: number;
  notes?: string;
  line_items?: Array<{
    id: string | undefined;
    amount_per_item: number;
    name: string | undefined;
  }>;
}
export interface UpdateReceiptBatch {
  id: string;
  receipt_date?: Date;
  receipt_image_ids?: string[];
  receipt_batch_entries?: UpdateReceiptBatchEntry[];
  nursing_home_id?: string;
  cash_list_transaction_group_id?: string;
  receipt_type?: string;
  service_provider?: string;
  use_individual_dates?: boolean;
}

export interface ReceiptBatchResult {
  data: ReceiptBatch;
}

export interface IReceiptBatchPhoenixApi extends Api {
  getReceiptBatch(dataIn: {
    params: { batchId: string };
  }): Promise<ReceiptBatchResult>;

  postReceiptBatchPreview(dataIn: {
    params: { batchId: string };
  }): Promise<Blob>;

  postReceiptBatch(dataIn: {
    body: Omit<UpdateReceiptBatch, 'id'>;
  }): Promise<ReceiptBatchResult>;

  patchReceiptBatch(dataIn: {
    params: { batchId: string };
    body: UpdateReceiptBatch;
  }): Promise<ReceiptBatchResult>;

  deleteReceiptBatch(dataIn: {
    params: { batchId: string };
  }): Promise<{ status: 'OK' }>;

  postReceiptBatchEntry(dataIn: {
    params: { batchId: string };
    body: { data: UpdateReceiptBatchEntry[] };
  }): Promise<ReceiptBatchResult>;

  postReceiptBatchEntryPreview(dataIn: {
    params: { batchId: string; entryId: string };
  }): Promise<Blob>;

  patchReceiptBatchEntry(dataIn: {
    params: { batchId: string; entryId: string };
    body: UpdateReceiptBatchEntry;
  }): Promise<ReceiptBatchResult>;

  deleteReceiptBatchEntry(dataIn: {
    params: {
      batchId: string;
      entryId: string;
    };
  }): Promise<ReceiptBatchResult>;

  submitReceiptBatch(dataIn: { params: { batchId: string } }): Promise<void>;
}

export class ReceiptBatchPhoenixApi implements IReceiptBatchPhoenixApi {
  baseUrl: string;

  getReceiptBatch: IReceiptBatchPhoenixApi['getReceiptBatch'];
  postReceiptBatch: IReceiptBatchPhoenixApi['postReceiptBatch'];
  patchReceiptBatch: IReceiptBatchPhoenixApi['patchReceiptBatch'];
  postReceiptBatchEntry: IReceiptBatchPhoenixApi['postReceiptBatchEntry'];
  patchReceiptBatchEntry: IReceiptBatchPhoenixApi['patchReceiptBatchEntry'];
  deleteReceiptBatch: IReceiptBatchPhoenixApi['deleteReceiptBatch'];
  deleteReceiptBatchEntry: IReceiptBatchPhoenixApi['deleteReceiptBatchEntry'];
  postReceiptBatchPreview: IReceiptBatchPhoenixApi['postReceiptBatchPreview'];
  postReceiptBatchEntryPreview: IReceiptBatchPhoenixApi['postReceiptBatchEntryPreview'];
  submitReceiptBatch: IReceiptBatchPhoenixApi['submitReceiptBatch'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getReceiptBatch = get({
      authContext,
      url: (params) => `${this.baseUrl}/receipt-batch/${params.batchId}`,
    });

    this.postReceiptBatchPreview = async (dataIn) => {
      const finalUrl = `${this.baseUrl}/receipt-batch/${dataIn.params.batchId}/preview`;

      return await superFetch(authContext, finalUrl, {
        method: 'POST',
        headers: defaultHeaders(),
      }).then((res) => res.blob());
    };

    this.postReceiptBatchEntryPreview = async (dataIn) => {
      const finalUrl = `${this.baseUrl}/receipt-batch/${dataIn.params.batchId}/entry/${dataIn.params.entryId}/preview`;

      return await superFetch(authContext, finalUrl, {
        method: 'POST',
        headers: defaultHeaders(),
      }).then((res) => res.blob());
    };

    this.postReceiptBatch = modify({
      method: 'POST',
      authContext,
      url: `${this.baseUrl}/receipt-batch`,
    });

    this.patchReceiptBatch = modify({
      method: 'PATCH',
      authContext,
      url: (params) => `${this.baseUrl}/receipt-batch/${params.batchId}`,
    });

    this.postReceiptBatchEntry = modify({
      method: 'POST',
      authContext,
      url: (params) => `${this.baseUrl}/receipt-batch/${params.batchId}/entry`,
    });

    this.patchReceiptBatchEntry = modify({
      method: 'PATCH',
      authContext,
      url: (params) =>
        `${this.baseUrl}/receipt-batch/${params.batchId}/entry/${params.entryId}`,
    });

    this.deleteReceiptBatch = modify({
      method: 'DELETE',
      authContext,
      url: (params) => `${this.baseUrl}/receipt-batch/${params.batchId}`,
    });

    this.deleteReceiptBatchEntry = modify({
      method: 'DELETE',
      authContext,
      url: (params) =>
        `${this.baseUrl}/receipt-batch/${params.batchId}/entry/${params.entryId}`,
    });

    this.submitReceiptBatch = modify({
      method: 'POST',
      authContext,
      url: (params) => `${this.baseUrl}/receipt-batch/${params.batchId}/submit`,
    });
  }
}
