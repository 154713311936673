export const DEFAULT_LOCALE = 'de-AT';
export const DE_LOCALE = 'de-DE';

// 07 April
export const MONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: '2-digit',
};

// 7 April
export const MONTH_SHORTDAY_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
};

export const NUMERICMONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
};

// 2022/07
export const YEAR_MONTH_SHORT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
};

// April 2022
export const YEAR_MONTH_FORMAT: Intl.DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
};

export const YEAR_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
};

// 07 April 2022
export const YEAR_MONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
};

// 07 Apr 2022
export const YEAR_SHORTMONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

// 7 April 2022
export const YEAR_MONTH_SHORTDAY_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

// 07/04/2022
export const YEAR_MONTH_DAY_SHORT_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const SHORT_YEAR_MONTH_DAY_FORMAT: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};

// 15:09
export const TIME_FORMAT: Intl.DateTimeFormatOptions = {
  year: undefined,
  hour: '2-digit',
  minute: '2-digit',
};

const now = new Date();
export const CURRENT_YEAR = now.getFullYear();
