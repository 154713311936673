export enum CashTransactionGroupLinkError {
  ReceiptBatchWithDifferentCashLinkError = 'ReceiptBatchWithDifferentCashLinkError',
  ReceiptBatchReceiptWithDifferentCashLink = 'ReceiptBatchReceiptWithDifferentCashLink',
}

export interface ReceiptBatchWithDifferentCashLinkError extends Error {
  statusCode: 409;
  message: 'Conflict';
  description: string;
  errorCode: CashTransactionGroupLinkError.ReceiptBatchWithDifferentCashLinkError;
}

export function isReceiptBatchWithDifferentCashLinkError(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  val: any
): val is ReceiptBatchWithDifferentCashLinkError {
  return (
    val.errorCode ===
    CashTransactionGroupLinkError.ReceiptBatchWithDifferentCashLinkError
  );
}

export interface ReceiptBatchReceiptWithDifferentCashLinkError extends Error {
  statusCode: 409;
  message: 'Conflict';
  receiptId: string;
  description: string;
  errorCode: CashTransactionGroupLinkError.ReceiptBatchReceiptWithDifferentCashLink;
}

export function isReceiptBatchReceiptWithDifferentCashLinkError(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  val: any
): val is ReceiptBatchReceiptWithDifferentCashLinkError {
  return (
    val.errorCode ===
    CashTransactionGroupLinkError.ReceiptBatchReceiptWithDifferentCashLink
  );
}
