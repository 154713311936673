import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext, useCallback, useState } from 'react';
import type { ICashManagementApi } from './api';
import { addMissingFactorsAndSort, CashManagementApi } from './api';
import type {
  QueryClient,
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQueryClient } from 'react-query';
import type {
  Coin,
  CreateCashTransactionGroupTransactionResultDto,
  DeleteCashTransactionGroupLinkDto,
  DeleteCashTransactionGroupLinkResultDto,
  GetCashListNursingHomeConfigurationDto,
  GetCashManagementTransactionDto,
  GetCashManagementWithTransactionDto,
  GetCashTransactionGroupListResponseClient,
  GetCashTransactionGroupResultDto,
  GetCashTransactionGroupsDto,
  PostCashManagementDto,
  PostCashTransactionGroupLinkDto,
  PostCashTransactionGroupLinkResultDto,
  PostCashTransactionGroupTransactionDto,
} from '@pflegenavi/shared/api';
import {
  CashManagementTransactionType,
  headerConstants,
} from '@pflegenavi/shared/api';
import { invalidateResident } from '../resident';
import { getTransactionsKey, TRANSACTIONS_PAGINATED_KEY } from '../transaction';
import { PAYMENT_DASHBOARD_STATISTICS_KEY, TRANSFER_KEY_ALL } from '../payment';
import { invalidateAllResidentBalance } from '../reporting';
import * as Sentry from '@sentry/react';

export { addMissingFactorsAndSort } from './api';
export type { ICashManagementApi } from './api';

const ApiContext = createContext<ICashManagementApi | undefined>(undefined);
const { useApi: useCashManagementApi, ApiProvider: CashManagementApiProvider } =
  makeApiProvider({
    name: 'CashManagement',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new CashManagementApi(tenantId, auth, apiUrl),
  });

export {
  useCashManagementApi,
  CashManagementApiProvider,
  ApiContext as CashManagementApiContext,
};

let lastCashTransactionGroupListModificationTimestamp: number | undefined =
  undefined;

export const CASH_LIST_CONFIGURATION_KEY = (
  nursingHomeId: string | undefined
): QueryKey => ['cash-list-configuration', nursingHomeId];

export const invalidateCashList = async (
  client: QueryClient,
  nursingHomeId?: string
): Promise<void> => {
  await Promise.all([
    client.invalidateQueries(CASH_LIST_CONFIGURATION_KEY(nursingHomeId)),
  ]);
};

const CASH_LIST_TRANSACTIONS_KEY = 'cash-list-transactions';
const CASH_TRANSACTIONS_KEY = (
  by: 'id' | 'transactionId',
  id: string | undefined
) => [
  CASH_LIST_TRANSACTIONS_KEY,
  {
    by,
    id,
  },
];

const CASH_LIST_TRANSACTION_ADD_KEY = 'cash-list-transactions-add';

export const CASH_TRANSACTION_GROUP_LIST_KEY = 'cash-transaction-group-list';
export const CASH_TRANSACTION_GROUP_KEY = (groupId: string): string[] => [
  `cash-transaction-group`,
  groupId,
];
const MAKE_CASH_TRANSACTION_GROUP_LIST_KEY = (
  cashListId: string | undefined,
  params: object
) => [CASH_TRANSACTION_GROUP_LIST_KEY, cashListId, params];

const CASH_LIST_TRANSACTIONS_KEY_FOR_DATE = (
  cashListId: string | undefined,
  dateFrom?: Date,
  dateTo?: Date
) => [
  CASH_LIST_TRANSACTIONS_KEY,
  cashListId,
  {
    dateFrom,
    dateTo,
  },
];

export const useGetCashListConfiguration = (
  nursingHomeId: string | undefined
): UseQueryResult<GetCashListNursingHomeConfigurationDto> => {
  return useApiQuery(
    useCashManagementApi,
    CASH_LIST_CONFIGURATION_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getCashListConfiguration({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useCashManagementTransactionById = (
  id: string | undefined
): UseQueryResult<GetCashManagementTransactionDto> => {
  const select = useCallback((row: GetCashManagementTransactionDto) => {
    return {
      ...row,
      date: new Date(row.date),
    };
  }, []);

  return useApiQuery(
    useCashManagementApi,
    CASH_TRANSACTIONS_KEY('id', id),
    async (api) => {
      if (!id) {
        throw new Error('id is required');
      }
      return await api.getTransactionById({
        params: { id },
      });
    },
    {
      select,
      enabled: id !== undefined,
    }
  );
};

export const useCashManagementTransactions = ({
  cashListId,
  dateFrom,
  dateTo,
}: {
  cashListId: string;
  dateFrom?: Date;
  dateTo?: Date;
}): UseQueryResult<GetCashManagementTransactionDto[]> => {
  const select = useCallback((rows: GetCashManagementTransactionDto[]) => {
    return rows.map((row) => ({
      ...row,
      date: new Date(row.date),
    }));
  }, []);

  return useApiQuery(
    useCashManagementApi,
    CASH_LIST_TRANSACTIONS_KEY_FOR_DATE(cashListId, dateFrom, dateTo),
    (api) => {
      if (!cashListId) {
        throw new Error('cashListId is required');
      }

      return api.getTransactions({
        params: {
          cashListId,
          dateFrom: dateFrom ?? new Date(),
          dateTo: dateTo ?? new Date(),
        },
      });
    },
    {
      select,
      enabled:
        cashListId !== undefined &&
        dateFrom !== undefined &&
        dateTo !== undefined,
    }
  );
};

type AddTransaction = Omit<PostCashManagementDto, 'coins'> & {
  coins?: Coin[];
};

interface UseCashManagementAddTransactionOptions {
  /**
   * Runs before the query is invalidated. Can be used to close modals.
   */
  onBeforeInvalidateCashList?: () => void;
  nursingHomeId: string | undefined;
}

export const useCashManagementAddTransaction = (
  opts: UseCashManagementAddTransactionOptions
): UseMutationResult<
  GetCashManagementWithTransactionDto,
  unknown,
  AddTransaction
> => {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    GetCashManagementWithTransactionDto,
    unknown,
    AddTransaction
  >(
    CASH_LIST_TRANSACTION_ADD_KEY,
    // eslint-disable-next-line complexity
    ({ coins, cashListId, ...data }) => {
      if (!cashListId) {
        throw new Error('cashList is not initialized yet');
      }
      const shouldRemoveCoinsFactors =
        data.bankAccountAmount &&
        data.bankAccountAmount !== 0 &&
        data.type !== CashManagementTransactionType.Adjustment;

      const allCoins = shouldRemoveCoinsFactors
        ? undefined
        : coins
        ? addMissingFactorsAndSort(coins).map((coin) => coin.amount)
        : undefined;
      const body = {
        ...data,
        coins: allCoins,
        cashListId,
      };
      return api
        .addTransaction({ body })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data);
    },
    {
      onSuccess: async (_result, data) => {
        await Promise.all([
          queryClient.invalidateQueries(CASH_LIST_TRANSACTIONS_KEY),
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
          ...(data.type === CashManagementTransactionType.StripeTransfer
            ? [
                queryClient.invalidateQueries(TRANSFER_KEY_ALL),
                queryClient.invalidateQueries(PAYMENT_DASHBOARD_STATISTICS_KEY),
              ]
            : []),
          ...(data.resident_id
            ? [
                invalidateAllResidentBalance(queryClient, opts.nursingHomeId),
                invalidateResident(
                  queryClient,
                  data.resident_id,
                  opts.nursingHomeId
                ),
                queryClient.invalidateQueries(
                  getTransactionsKey(data.resident_id)
                ),
              ]
            : []),
          queryClient.invalidateQueries(
            TRANSACTIONS_PAGINATED_KEY({ nursingHomeId: opts.nursingHomeId })
          ),
        ]);

        // PNA-1820 Called before invalidating cash list to avoid flickering in the modal
        opts?.onBeforeInvalidateCashList?.();

        await invalidateCashList(queryClient, opts.nursingHomeId);
      },
    }
  );
  return result;
};

export function useCashTransactionGroupAddLink(): UseMutationResult<
  PostCashTransactionGroupLinkResultDto,
  unknown,
  PostCashTransactionGroupLinkDto
> {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    PostCashTransactionGroupLinkResultDto,
    unknown,
    PostCashTransactionGroupLinkDto
  >(
    // TODO: When do we need to share mutation keys?
    'cash-transaction-group-add-link',
    (data) =>
      api
        .postCashTransactionGroupLink({
          params: {
            cashTransactionGroupId: data.cashTransactionGroupId,
          },
          body: {
            transactionId: data.transactionId,
            receiptId: data.receiptId,
            transactionIds: data.transactionIds,
            receiptIds: data.receiptIds,
          },
        })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data),
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
        ]);
      },
    }
  );
  return result;
}

export function useCashTransactionGroupRemoveLink(): UseMutationResult<
  DeleteCashTransactionGroupLinkResultDto,
  unknown,
  DeleteCashTransactionGroupLinkDto
> {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    DeleteCashTransactionGroupLinkResultDto,
    unknown,
    DeleteCashTransactionGroupLinkDto
  >(
    'cash-transaction-group-remove-link',
    (data) =>
      api
        .deleteCashTransactionGroupLink({
          params: {
            cashTransactionGroupId: data.cashTransactionGroupId,
            transactionId: data.transactionId,
            receiptId: data.receiptId,
            transactionIds: data.transactionIds,
          },
        })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data),
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
        ]);
      },
    }
  );
  return result;
}

const MAX_POLLING_ATTEMPTS = 10;
const POLLING_INTERVAL = 200;

export function useCashTransactionGroups(
  data: GetCashTransactionGroupsDto,
  options?: UseQueryOptions<GetCashTransactionGroupListResponseClient>
): UseQueryResult<GetCashTransactionGroupListResponseClient> {
  const select = useCallback(
    (data: GetCashTransactionGroupListResponseClient) => {
      const newData = data.data.map((row) => ({
        ...row,
        updateDate: row.updateDate && new Date(row.updateDate),
        cashChanged: row.cashChanged ? row.cashChanged / 100 : undefined,
        amountLinked: row.amountLinked ? row.amountLinked / 100 : undefined,
        mismatchConfirmationDate: row.mismatchConfirmationDate
          ? new Date(row.mismatchConfirmationDate)
          : undefined,
      }));
      return {
        ...data,
        data: newData,
      };
    },
    []
  );

  const [pollAttempts, setPollAttempts] = useState(0);

  const query = useApiQuery(
    useCashManagementApi,
    MAKE_CASH_TRANSACTION_GROUP_LIST_KEY(data.cashListId, data),
    (api) => {
      if (pollAttempts < MAX_POLLING_ATTEMPTS) {
        setPollAttempts((prev) => prev + 1);
      } else {
        // Log error, but continue fetching
        // eslint-disable-next-line no-console
        console.error(
          `Failed to fetch query after ${MAX_POLLING_ATTEMPTS} tries`
        );
        Sentry.captureMessage(
          `Failed to fetch query after ${MAX_POLLING_ATTEMPTS} tries`,
          {
            level: 'error',
            extra: {
              cacheKey: MAKE_CASH_TRANSACTION_GROUP_LIST_KEY(
                data.cashListId,
                data
              ),
            },
          }
        );
        // In case of error we reset the polling attempts
        // otherwise we would spam us with errors
        setPollAttempts(0);
      }

      return api.getCashTransactionGroups({ ...data });
    },
    {
      ...options,
      select,
      refetchInterval: (response) => {
        if (
          lastCashTransactionGroupListModificationTimestamp &&
          (response?.meta?.lastRefresh === undefined ||
            response?.meta?.lastRefresh <
              lastCashTransactionGroupListModificationTimestamp)
        ) {
          return POLLING_INTERVAL;
        }

        setPollAttempts(0);
        return false;
      },
    }
  );

  return query;
}

export function useCashTransactionGroup(
  groupId?: string
): UseQueryResult<GetCashTransactionGroupResultDto> {
  const select = useCallback((data: GetCashTransactionGroupResultDto) => {
    const newData = {
      ...data,
      updateDate: data.updateDate && new Date(data.updateDate),
      cashChanged: data.cashChanged,
      amountLinked: data.amountLinked,
      transactions: data.transactions?.map((row) => ({
        ...row,
        date: row.date && new Date(row.date),
      })),
      cashListTransactions: data.cashListTransactions?.map((row) => ({
        ...row,
        amount: row.amount,
        date: row.date && new Date(row.date),
      })),
    };
    return newData;
  }, []);
  return useApiQuery(
    useCashManagementApi,
    CASH_TRANSACTION_GROUP_KEY(groupId ?? ''),
    (api) => {
      if (!groupId) {
        throw new Error('groupId is required');
      }
      return api.getCashTransactionGroup({ params: { groupId } });
    },
    {
      enabled: groupId !== undefined,
      select,
    }
  );
}

export function useCashTransactionGroupAddTransaction(
  nursingHomeId: string | undefined
): UseMutationResult<
  CreateCashTransactionGroupTransactionResultDto,
  unknown,
  PostCashTransactionGroupTransactionDto
> {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    CreateCashTransactionGroupTransactionResultDto,
    unknown,
    PostCashTransactionGroupTransactionDto
  >(
    'cash-transaction-group-add-transaction',
    (data) =>
      api
        .postCashTransactionGroupTransaction({
          params: {
            cashTransactionGroupId: data.cashTransactionGroupId,
          },
          body: {
            coins: data.coins,
            type: data.type,
            notes: data.notes,
            bankAccountAmount: data.bankAccountAmount,
          },
        })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data),
    {
      onSuccess: async (_, props) => {
        return await Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
          queryClient.invalidateQueries(
            CASH_LIST_CONFIGURATION_KEY(nursingHomeId)
          ),
          queryClient.invalidateQueries(
            CASH_TRANSACTION_GROUP_KEY(props.cashTransactionGroupId)
          ),
        ]);
      },
    }
  );
  return result;
}

export function useCashTransactionGroupConfirmMismatch(): UseMutationResult<
  void,
  unknown,
  {
    cashTransactionGroupId: string;
  }
> {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    void,
    unknown,
    {
      cashTransactionGroupId: string;
    }
  >(
    'cash-transaction-group-confirm-mismatch',
    (data) =>
      api
        .confirmMismatch({
          params: {
            cashTransactionGroupId: data.cashTransactionGroupId,
          },
        })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data),
    {
      onSuccess: (_, props) => {
        return Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
          queryClient.invalidateQueries(
            CASH_TRANSACTION_GROUP_KEY(props.cashTransactionGroupId)
          ),
        ]);
      },
    }
  );
  return result;
}

export function useCashTransactionGroupUnconfirmMismatch(): UseMutationResult<
  void,
  unknown,
  {
    cashTransactionGroupId: string;
  }
> {
  const api = useCashManagementApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    void,
    unknown,
    {
      cashTransactionGroupId: string;
    }
  >(
    'cash-transaction-group-unconfirm-mismatch',
    (data) =>
      api
        .unconfirmMismatch({
          params: {
            cashTransactionGroupId: data.cashTransactionGroupId,
          },
        })
        .then(extractLastModifiedFromResponse)
        .then((r) => r.data),
    {
      onSuccess: (_, props) => {
        return Promise.all([
          queryClient.invalidateQueries(CASH_TRANSACTION_GROUP_LIST_KEY),
          queryClient.invalidateQueries(
            CASH_TRANSACTION_GROUP_KEY(props.cashTransactionGroupId)
          ),
        ]);
      },
    }
  );
  return result;
}

function extractLastModifiedFromResponse<
  T extends {
    headers: Headers;
  }
>(response: T) {
  const timestamp = response.headers.get(headerConstants.lastEventHeader);
  if (timestamp) {
    lastCashTransactionGroupListModificationTimestamp = parseInt(timestamp, 10);
  }
  return response;
}

export function useTransferCallback(
  nursingHomeId: string | undefined
): CashManagementApi['transfer'] {
  const query = useQueryClient();
  const api = useCashManagementApi();

  return useCallback(
    async (...args: Parameters<typeof api.transfer>) => {
      const result = await api.transfer(...args);
      await Promise.all([
        query.invalidateQueries(
          MAKE_CASH_TRANSACTION_GROUP_LIST_KEY(
            args[0].params.fromCashListId,
            {}
          )
        ),
        query.invalidateQueries(
          MAKE_CASH_TRANSACTION_GROUP_LIST_KEY(args[0].params.toCashListId, {})
        ),
        invalidateCashList(query, nursingHomeId),
      ]);
      return result;
    },
    [api, nursingHomeId, query]
  );
}
