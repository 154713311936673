import { createContext, useContext } from 'react';

export interface User {
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  attributes?: Record<string, string[]>;
  locale?: string;
}

export interface AuthenticationContext {
  user?: User;
  setUser: (user: User) => void;
  /** @deprecated all necessary user information should be stored in the token */
  updateUserProfile: () => boolean | Promise<boolean>;
  updateToken: () => Promise<boolean>;
  getToken: () => Promise<string | undefined>;
  getTokenImmediate: () => string | undefined;
  logout: () => Promise<void>;
  testMode: boolean;
  setTestMode: (testMode: boolean) => void;
  addTokenListener: (listener: (token: string | undefined) => void) => {
    unlisten: () => void;
  };
}

export const AuthenticationContext = createContext<
  AuthenticationContext | undefined
>(undefined);

export const useAuthentication = (): Omit<
  AuthenticationContext,
  // Hidden methods. They should only be used by internal functions.
  'getToken' | 'getTokenImmediate'
> => {
  const authentication = useContext(AuthenticationContext);
  if (authentication === undefined) {
    throw new Error('AuthenticationProvider is not available');
  }

  return authentication;
};

export const useAuthenticationInternal = (): AuthenticationContext => {
  const authentication = useContext(AuthenticationContext);
  if (authentication === undefined) {
    throw new Error('AuthenticationProvider is not available');
  }

  return authentication;
};

export const useTestMode = (): [boolean, (testMode: boolean) => void] => {
  const authentication = useContext(AuthenticationContext);
  if (!authentication) {
    throw new Error('AuthenticationProvider is not available');
  }
  return [authentication.testMode, authentication.setTestMode];
};
