import {
  IsArray,
  IsDate,
  IsEnum,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { CashTransactionGroupType } from './CashTransactionGroupType';
import { Transform, Type } from 'class-transformer';
import {
  PaginatedQuery,
  type PaginatedResultSet,
} from '@pflegenavi/shared/utils';
import type {
  SortableQuery,
  PaginatedResultSubset,
} from '@pflegenavi/shared/utils';

export class GetCashTransactionGroupsDto
  extends PaginatedQuery
  implements SortableQuery
{
  @IsOptional()
  @IsUUID(4)
  cashListId!: string | undefined;

  @IsOptional()
  @IsEnum(CashTransactionGroupType, { each: true })
  groupType?: CashTransactionGroupType[];

  @IsOptional()
  @IsString()
  sort?: SortBy;

  @IsOptional()
  @IsUUID(4)
  receiptId?: string;

  /** @deprecated use transactionIds instead */
  @IsOptional()
  @IsUUID(4)
  transactionId?: string;

  /** Filter for cash transaction groups containing any of the given transactionIds */
  @IsOptional()
  @IsUUID(4, { each: true })
  transactionIds?: string[];

  // Any string is implicitly converted to "true"
  // Therefore we don't use @IsBoolean() but instead we manually convert the string to boolean
  @IsOptional()
  @Type(() => String)
  @Transform(({ value }) => {
    return value === undefined ? undefined : value === 'true' || value === '1';
  })
  mismatchOnly?: boolean;

  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: 'asc' | 'desc';

  @IsOptional()
  @IsString({ each: true })
  fields?: Fields[];
}

type SortBy = 'update_date' | 'cash_changed';
type Fields =
  | 'transfer_count'
  | 'cash_changed'
  | 'amount_linked'
  | 'notes'
  | 'residents';

export class GetCashTransactionGroupListResultDto {
  @IsNotEmpty()
  @IsEnum(CashTransactionGroupType)
  type!: CashTransactionGroupType;

  @IsNotEmpty()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string; // TODO: Add support on backend

  @IsNotEmpty()
  @IsDate()
  updateDate!: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  cashChanged?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  amountLinked?: number;

  @IsOptional()
  @IsInt()
  receiptLinkCount?: number;

  @IsOptional()
  @IsInt()
  transactionLinkCount?: number;

  @IsOptional()
  @IsInt()
  paymentIntentLinkCount?: number;

  @IsOptional()
  @IsInt()
  payoutLinkCount?: number;

  @IsOptional()
  @IsInt()
  cashListTransactionCount?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  transferCount?: number;

  @IsOptional()
  @IsString()
  notes?: string;

  @IsOptional()
  @IsDate()
  mismatchConfirmationDate?: Date;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  residents?: Resident[];
}

class Resident {
  @IsUUID(4)
  id!: string;

  @IsString()
  firstName!: string;

  @IsString()
  lastName!: string;
}

export type GetCashTransactionGroupListResponseClient = PaginatedResultSubset<
  GetCashTransactionGroupListResultDto,
  { lastRefresh: number | undefined }
>;

export type GetCashTransactionGroupListResponse = PaginatedResultSet<
  GetCashTransactionGroupListResultDto,
  { lastRefresh: number | undefined }
>;
