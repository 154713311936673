import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import { MaxLengthWithTrim, MinLengthWithTrim } from './helpers';
import { Transform } from 'class-transformer';

export class PostServiceProviderDto {
  @IsNotEmpty()
  @IsString()
  @MinLengthWithTrim(1)
  @MaxLengthWithTrim(255)
  @Transform(({ value }) => value?.trim())
  name!: string;
  @IsNotEmpty()
  @IsUUID(4)
  receiptTypeId!: string;
  @IsOptional()
  @IsEmail()
  email?: string;
}
