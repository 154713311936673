import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type {
  FamilyMemberPhoenix,
  FamilyMemberResidentRelationship,
  Gender,
  ImportFamilyMemberPhoenix,
  ImportFamilyMemberResponsePhoenix,
} from '@pflegenavi/shared/api';

export interface CreateResidentResult {
  data: any;
}

export type InitialBalance =
  | {
      cash_list_id: string;
      notes?: string;
      coins: number[];
    }
  | {
      cash_list_id: string;
      notes?: string;
      bank_amount: number;
    }
  | {
      notes?: string;
      amount: number;
    };

export interface UpdateResidentFamilyMemberDto {
  user_id: string;
  first_name: string;
  last_name: string;
  notes?: string;
  phone?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  relationship_to_resident: FamilyMemberResidentRelationship;
}

export interface CreateResidentParams {
  body: {
    nursing_home_id: string;
    data: {
      entry_date: Date;
      firstname: string;
      lastname: string;
      gender: Gender;
      birthdate?: Date;
      resident_accounting_id?: string;
      initial_balance?: InitialBalance;
      service_providers?: Array<{
        global_service_provider_id: string;
      }>;
      family_member?: {
        first_name: string;
        last_name: string;
        email: string;
        notes?: string;
        phone?: string;
        address?: string;
        zip_code?: string;
        city?: string;
        country?: string;
        relationship_to_resident: FamilyMemberResidentRelationship | undefined;
      };
    };
  };
}

export interface IResidentPhoenixApi extends Api {
  createResident(dataIn: CreateResidentParams): Promise<CreateResidentResult>;
  resendFamilyMemberWelcomeEmail(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<void>;

  importFamilyMembers(dataIn: {
    body: ImportFamilyMemberPhoenix;
  }): Promise<ImportFamilyMemberResponsePhoenix>;

  addNewFamilyMember(dataIn: {
    params: {
      residentId: string;
    };
    body: {
      data: FamilyMemberPhoenix;
    };
  }): Promise<{
    status: 'Created';
  }>;

  deleteFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  updateFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
    body: {
      data: UpdateResidentFamilyMemberDto;
    };
  }): Promise<{ data: UpdateResidentFamilyMemberDto }>;
}

export class ResidentPhoenixApi implements IResidentPhoenixApi {
  baseUrl: string;

  createResident: IResidentPhoenixApi['createResident'];
  resendFamilyMemberWelcomeEmail: IResidentPhoenixApi['resendFamilyMemberWelcomeEmail'];
  public importFamilyMembers: IResidentPhoenixApi['importFamilyMembers'];
  public addNewFamilyMember: IResidentPhoenixApi['addNewFamilyMember'];
  public deleteFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['deleteFamilyMemberForGivenResidentPhoenix'];
  public updateFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['updateFamilyMemberForGivenResidentPhoenix'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.createResident = modify({
      authContext,
      url: `${this.baseUrl}/resident`,
      method: 'POST',
    });

    this.resendFamilyMemberWelcomeEmail = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}/resend_welcome`,
      method: 'POST',
    });

    this.importFamilyMembers = modify<
      ImportFamilyMemberPhoenix,
      ImportFamilyMemberResponsePhoenix
    >({
      authContext,
      url: `${this.baseUrl}/resident/family-member/import`,
      method: 'POST',
    });

    this.addNewFamilyMember = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member`,
      method: 'POST',
    });

    this.deleteFamilyMemberForGivenResidentPhoenix = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
        familyMemberId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'DELETE',
    });

    this.updateFamilyMemberForGivenResidentPhoenix = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'PUT',
    });
  }
}
