import {
  IsArray,
  IsBoolean,
  IsDate,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';
import type { TransformFnParams } from 'class-transformer';
import { Transform } from 'class-transformer';
import { ReceiptBatchOwner } from './receiptBatchDto';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export class CreateReceiptBatch {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  id?: string;

  @IsNotEmpty()
  @Transform(({ value }: TransformFnParams) => value?.trim())
  @IsString()
  title!: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  receiptType?: string;

  @IsOptional()
  @IsString()
  serviceProvider?: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  cashListTransactionGroupId?: string;

  @IsOptional()
  @IsString({ each: true })
  @IsUUID(4, { each: true })
  @IsArray()
  receiptImageIds?: string[];

  @IsOptional()
  @IsEnum(ReceiptBatchOwner)
  owner?: ReceiptBatchOwner;

  @IsOptional()
  @IsBoolean()
  submissionLocked?: boolean;
}

export type CreateReceiptBatchWithNursingHome = WithRequired<
  CreateReceiptBatch,
  'nursingHomeId'
>;

export class CreateReceiptBatchResponse {
  @IsNotEmpty()
  @IsString()
  @IsUUID()
  id!: string;

  @IsNotEmpty()
  @IsString()
  @IsUUID()
  nursingHomeId!: string;

  @IsNotEmpty()
  @IsDate()
  createdOn!: Date;
}
