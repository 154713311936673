import {
  IsDefined,
  IsEmail,
  IsEnum,
  IsLocale,
  IsOptional,
  IsString,
  ValidateIf,
} from 'class-validator';
import { ServiceProviderInviteStatus } from './service-provider.dto';

export class PatchServiceProviderInviteStatusDto {
  @IsDefined()
  // this is to cover the case where we want to revoke an invitation, because we send null
  @ValidateIf((object, value) => value !== null)
  @IsEnum(ServiceProviderInviteStatus)
  inviteStatus!: ServiceProviderInviteStatus | null;
  @IsOptional()
  @IsString()
  @IsEmail()
  email?: string;
  @IsOptional()
  @IsString()
  @IsLocale()
  locale?: string;
}
