import {
  IsArray,
  IsDate,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import type { CreateOrUpdateReceiptBatchEntryDto } from './receiptBatchEntryDto';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export class UpdateReceiptBatchDto {
  @IsString()
  title!: string;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  nursingHomeId?: string;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  receiptType?: string;

  @IsOptional()
  @IsString()
  serviceProvider?: string;

  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  receiptBatchEntries?: CreateOrUpdateReceiptBatchEntryDto[];

  @IsOptional()
  @IsString()
  @IsUUID(4)
  cashListTransactionGroupId?: string;

  @IsOptional()
  @IsString({ each: true })
  @IsUUID(4, { each: true })
  @IsArray()
  receiptImageIds?: string[];
}

export type UpdateReceiptBatchWithNursingHome = WithRequired<
  UpdateReceiptBatchDto,
  'nursingHomeId'
>;

export class UpdateReceiptBatchCashTransactionGroupDto {
  @IsOptional()
  @IsString()
  @IsUUID(4)
  cashListTransactionGroupId?: string;
}

export class PatchReceiptBatchDto {
  @IsOptional()
  @IsString()
  title?: string;

  @IsOptional()
  @IsDate()
  receiptDate?: Date;

  @IsOptional()
  @IsString()
  @IsUUID(4)
  receiptType?: string;

  @IsOptional()
  @IsString()
  serviceProviderId?: string;

  @IsOptional()
  @IsString({ each: true })
  @IsUUID(4, { each: true })
  @IsArray()
  receiptImageIds?: string[];
}
