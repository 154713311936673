export const CASH_TRANSACTION_BANK_ACCOUNT_AMOUNT_OR_COINS_MUST_BE_SET =
  'Bank account amount or coins have to be set.';
export const CASH_TRANSACTION_BANK_ACCOUNT_AMOUNT_BANK_ACCOUNT_AND_COINS_ONLY_ADJUSTMENTS =
  'Bank account and coins cannot be set at the same time, except for adjustment.';
export const CASH_TRANSACTION_NOTES_REQUIRED =
  'Notes field has to be set when adjustment does not equate to 0.';

export const SERVICE_PROVIDER_PAYMENT_FAILED_CODE =
  'service_provider_payment_failed';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_NOT_SUBMITTED =
  'receipt_batch_not_submitted_successfully';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_NOT_SUBMITTED_BY_SERVICE_PROVIDER =
  'receipt_batch_not_submitted_by_service_provider';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_CASH_LINK_EXISTS =
  'receipt_batch_cash_link_exists';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_PAYMENT_EXISTS =
  'receipt_batch_payment_exists';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MISSING_STRIPE_ACCOUNT =
  'service_provider_missing_stripe_account';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MISSING_STRIPE_MANDATE =
  'nursing_home_missing_stripe_mandate';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MAX_AMOUNT_EXCEEDED =
  'max_amount_exceeded';
export const SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MIN_AMOUNT_NOT_REACHED =
  'min_amount_not_reached';
export const SERVICE_PROVIDER_PAYMENT_FAILED_IS_NOT_CONNECTED =
  'service_provider_is_not_connected';

export type SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL =
  | SERVICE_PROVIDER_ERRORS
  | NURSING_HOME_ERRORS
  | RECEIPT_BATCH_ERRORS;

export type RECEIPT_BATCH_ERRORS =
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_CASH_LINK_EXISTS
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_PAYMENT_EXISTS
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_NOT_SUBMITTED
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_RECEIPT_BATCH_NOT_SUBMITTED_BY_SERVICE_PROVIDER
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MAX_AMOUNT_EXCEEDED
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MIN_AMOUNT_NOT_REACHED;

export type SERVICE_PROVIDER_ERRORS =
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MISSING_STRIPE_ACCOUNT
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_IS_NOT_CONNECTED;

export type NURSING_HOME_ERRORS =
  | typeof SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL_MISSING_STRIPE_MANDATE;

interface ServiceProviderPaymentError extends Error {
  code: typeof SERVICE_PROVIDER_PAYMENT_FAILED_CODE;
  details?: SERVICE_PROVIDER_PAYMENT_FAILED_DETAIL;
}

export function isServiceProviderPaymentError(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): error is ServiceProviderPaymentError {
  return error.code === SERVICE_PROVIDER_PAYMENT_FAILED_CODE;
}
