import { IsDate, IsOptional, IsString, IsUUID } from 'class-validator';

export enum BalanceType {
  Stripe = 'Stripe',
  StripeAvailable = 'StripeAvailable',
  StripePending = 'StripePending',
  StripeAvailableSoon = 'StripeAvailableSoon',
  Payouts = 'Payouts',
  Transfer = 'Transfer',
  Cash = 'Cash',
  Bank = 'Bank',
  Receipts = 'Receipts',
}

export interface Balance<T extends BalanceType> {
  type: T;
  /** @deprecated Use cent value instead */
  amount: number;
  amountInCents: number;
}

export interface PaymentDashboardStatisticsDto {
  statistics: {
    [key in BalanceType]: Balance<key>;
  };
  /** @deprecated Use cent value instead */
  totalResidentBalance: number;
  totalResidentBalanceInCents: number;
  residents: number;
  date: Date;
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export class GetResidentBalanceGraphDatapointsDto {
  /**
   * If not set, it will start one year ago
   */
  @IsDate()
  @IsOptional()
  dateStart?: Date;

  /**
   * If not set, it will end with the current date
   */
  @IsDate()
  @IsOptional()
  dateEnd?: Date;

  @IsString()
  @IsOptional()
  @IsUUID(4)
  nursingHomeId?: string;
}

export type GetResidentBalanceGraphDatapointsWithNursingHomeIdDto =
  WithRequired<GetResidentBalanceGraphDatapointsDto, 'nursingHomeId'>;

export type ResidentBalanceGraphDatapointDto = [Date, number];
