import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';

export interface CreateRecurringItem {
  nursing_home_id: string;
  name: string;
  amount: number;
  charge_full_period: boolean;
  auto_add_residents: boolean;
  compute_daily: boolean;
  tax_code_id?: string;
  residents: Array<{
    resident_id: string;
    start_date: Date;
  }>;
  generate_receipts: boolean;
}

export interface RecurringItem {
  id: string;
  nursing_home_id: string;
  name: string;
  amount: number;
  charge_full_period: boolean;
  auto_add_residents: boolean;
  compute_daily: boolean;
  tax_code_id?: string;
  generate_receipts: boolean;
  residents: Array<{
    resident_id: string;
    start_date: string;
  }>;
}

export interface IRecurringItemPhoenixApi extends Api {
  createRecurringItem(dataIn: {
    body: CreateRecurringItem;
  }): Promise<RecurringItem>;

  removeResidentFromRecurringItem(dataIn: {
    params: { recurringItemId: string; residentId: string };
    body: {
      charge_resident: boolean;
      end_date: Date;
    };
  }): Promise<RecurringItem>;
}

export class RecurringItemPhoenixApi implements IRecurringItemPhoenixApi {
  baseUrl: string;

  createRecurringItem: IRecurringItemPhoenixApi['createRecurringItem'];
  removeResidentFromRecurringItem: IRecurringItemPhoenixApi['removeResidentFromRecurringItem'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.createRecurringItem = modify({
      authContext,
      url: () => `${this.baseUrl}/recurring-item`,
      method: 'POST',
    });

    this.removeResidentFromRecurringItem = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/recurring-item/${params.recurringItemId}/residents/${params.residentId}`,
      method: 'DELETE',
    });
  }
}
