import type { ValidationArguments, ValidationOptions } from 'class-validator';
import { isString, registerDecorator } from 'class-validator';

export function MinLengthWithTrim(
  min: number,
  validationOptions?: ValidationOptions
) {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (object: unknown, propertyName: string) => {
    registerDecorator({
      name: 'minLengthWithTrim',
      // @ts-expect-error untyped
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate: (value: any): boolean =>
          isString(value) && value.trim().length >= min,
        defaultMessage: (validationArguments?: ValidationArguments): string =>
          `${validationArguments?.property} should have at least ${min} characters after trimming`,
      },
    });
  };
}

export const MaxLengthWithTrim = (
  max: number,
  validationOptions?: ValidationOptions
) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  return (object: unknown, propertyName: string) => {
    registerDecorator({
      name: 'maxLengthWithTrim',
      // @ts-expect-error untyped
      target: object.constructor,
      propertyName,
      options: validationOptions,
      validator: {
        validate: (value: any): boolean =>
          isString(value) && value.trim().length <= max,
        defaultMessage: (validationArguments?: ValidationArguments): string =>
          `${validationArguments?.property} should have at most ${max} characters after trimming`,
      },
    });
  };
};
