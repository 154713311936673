import {
  IsNotEmpty,
  IsUUID,
  IsNumber,
  IsOptional,
  IsIn,
} from 'class-validator';

export class PostCashTransactionGroupTransactionDto {
  @IsNotEmpty()
  @IsUUID(4)
  cashTransactionGroupId!: string;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber({}, { each: true })
  coins?: number[];

  @IsOptional()
  @IsNumber()
  bankAccountAmount?: number;

  @IsOptional()
  notes?: string;

  @IsNotEmpty()
  @IsIn(['withdrawal', 'deposit'])
  type!: 'withdrawal' | 'deposit';
}
