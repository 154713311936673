import {
  IsDate,
  IsEnum,
  IsIn,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Min,
  ValidateNested,
} from 'class-validator';
import { CashTransactionGroupType } from './CashTransactionGroupType';

export enum CashTransactionGroupLinkAction {
  INSERT = 'INSERT',
  DELETE = 'DELETE',
  TRUNCATE = 'TRUNCATE',
}

class LinkHistoryCashTransactionGroupDto {
  @IsNotEmpty()
  @IsEnum(CashTransactionGroupType)
  type!: CashTransactionGroupType;

  @IsNotEmpty()
  @IsUUID(4)
  id!: string;

  @IsNotEmpty()
  @IsDate()
  updateDate!: Date;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  cashChanged!: number;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  amountLinked?: number;

  @IsOptional()
  @IsInt()
  receiptLinkCount?: number;

  @IsOptional()
  @IsInt()
  transactionLinkCount?: number;

  @IsOptional()
  @IsNotEmpty()
  @IsNumber()
  transferCount?: number;

  @IsOptional()
  @IsString()
  notes?: string;
}

export class GetCashTransactionGroupLinkHistoryResultDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  id!: string;
  @IsOptional()
  @IsString()
  @IsUUID(4)
  receiptId?: string;
  @IsOptional()
  @IsString()
  @IsUUID(4)
  transactionId?: string;
  @IsNotEmpty()
  @IsDate()
  creationDatetime!: Date;
  @IsNotEmpty()
  @IsEnum(CashTransactionGroupLinkAction)
  action!: CashTransactionGroupLinkAction;
  @IsNotEmpty()
  @IsDate()
  actionTimestamp!: Date;
  @IsNotEmpty()
  @ValidateNested()
  group!: LinkHistoryCashTransactionGroupDto;
}

type SortOrder = 'asc' | 'desc';
type SortBy = 'action_timestamp' | 'update_date' | 'cash_changed';

export class GetCashTransactionGroupLinkHistoryDto {
  @IsOptional()
  @IsUUID(4)
  receiptId?: string;
  @IsOptional()
  @IsUUID(4)
  transactionId?: string;
  @IsOptional()
  @IsUUID(4)
  cashTransactionGroupId?: string;
  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;
  @IsOptional()
  @IsString()
  sort?: SortBy;
  @IsNotEmpty()
  @IsInt()
  @Min(0)
  page!: number;
  @IsNotEmpty()
  @IsInt()
  @Min(1)
  pageSize!: number;
}
