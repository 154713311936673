import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class UpdateResidentConfigurationDto {
  @IsNotEmpty()
  @IsString()
  @IsUUID(4)
  resident_id!: string;

  @IsOptional()
  @IsNumber()
  threshold!: number;

  @IsOptional()
  @IsNumber()
  topUpAmount!: number;
}
