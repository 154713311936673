import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getApiBaseUrl,
  modify,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import {
  DirectDebitStatusDto,
  endpoints,
  FamilyMemberInitiatedPaymentDto,
  GetPayoutsResultEntryDto,
  GetResidentBalanceGraphDatapointsWithNursingHomeIdDto,
  MoneyFlowType,
  PaymentDashboardStatisticsDto,
  PaymentDto,
  PaymentInfo,
  PaymentInitiationDto,
  PaymentMoneyFlowEntry,
  PaymentQueryFilters,
  PayoutQueryFilters,
  PayoutReconciliationEntryDto,
  PendingTransferReversalDto,
  ReceiptBatchPaymentStatus,
  ResidentBalanceGraphDatapointDto,
  ResidentBalanceInfo,
  ServiceProviderPayment,
  ServiceProviderPaymentListQuery,
  SetupIntentPayload,
  StripeAccount,
} from '@pflegenavi/shared/api';
import { parseISO } from 'date-fns';
import type {
  PaginatedResultSet,
  PaginatedResultSubset,
  RangeDateFilter,
} from '@pflegenavi/shared/utils';

export interface ApiError {
  statusCode: number;
  error: string;
  message: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isApiError = (data: any): data is ApiError => {
  return (
    typeof data?.error === 'string' &&
    typeof data?.statusCode === 'number' &&
    typeof data?.message === 'string'
  );
};

export interface IPaymentApi extends Api {
  setup(dataIn: {
    body: undefined;
    params: {
      residentId: string;
    };
  }): Promise<SetupIntentPayload | ApiError>;

  setupDirectDebitForNursingHome(
    nursingHomeId: string,
    body: {
      email: string;
      name: string;
      iban: string;
    }
  ): Promise<SetupIntentPayload | ApiError>;

  setupConfirm(dataIn: {
    body: undefined;
    params: {
      residentId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  setupConfirmNursingHome(dataIn: {
    body: undefined;
    params: {
      nursingHomeId: string;
      intentId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  setupConfirmPaymentIntent(dataIn: {
    body: {
      paymentIntentId: string;
    };
    params: {
      residentId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  getStripeAccountDetails(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<StripeAccount>;

  createStripeAccountForNursingHome(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<{
    accountId: string;
  }>;

  getPaymentInfoByResidentId(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<PaymentInfo>;

  getPaymentInfoByResidentIdAsFamilyMember(dataIn: {
    params: {
      residentId: string;
    };
  }): Promise<PaymentInfo>;

  getDirectDebitStatusForNursingHome(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<DirectDebitStatusDto>;

  deleteDirectDebitMandate(residentId: string): Promise<void>;

  deleteDirectDebitMandateForNursingHome(nursingHomeId: string): Promise<void>;

  deletePendingChargeByResidentId(
    residentId: string,
    notes: string
  ): Promise<void>;

  getAccountingDashboard(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<PaymentDashboardStatisticsDto>;

  getAccountingResidentBalanceGraph(dataIn: {
    params: GetResidentBalanceGraphDatapointsWithNursingHomeIdDto;
  }): Promise<ResidentBalanceGraphDatapointDto[]>;

  getAccountingPayouts(dataIn: {
    params: {
      nursingHomeId: string;
      page: number;
      pageSize: number;
      filters: PayoutQueryFilters;
    };
  }): Promise<PaginatedResultSet<GetPayoutsResultEntryDto>>;

  getAccountingPayments(dataIn: {
    params: {
      nursingHomeId: string;
      page: number;
      pageSize: number;
      filters: PaymentQueryFilters;
    };
  }): Promise<PaginatedResultSet<PaymentDto>>;

  getPayoutReconciliation(dataIn: {
    params: {
      payoutId: string;
    };
  }): Promise<PayoutReconciliationEntryDto[]>;

  getMoneyFlow(dataIn: {
    params: {
      nursingHomeIds: string[];
      dateRange?: RangeDateFilter<Date>;
      type?: MoneyFlowType;
    };
  }): Promise<PaymentMoneyFlowEntry[]>;

  getTransfer(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<number>;

  submitDirectDebitManualPayment(
    residentId: string,
    payment: FamilyMemberInitiatedPaymentDto
  ): Promise<SetupIntentPayload>;

  setPaymentInitiation(dataIn: {
    body: PaymentInitiationDto;
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  getResidentBalanceInfo: (dataIn: {
    params: {
      residentId: string;
    };
  }) => Promise<ResidentBalanceInfo>;

  getPendingReversals: (dataIn: {
    params: {
      nursingHomeId: string;
    };
  }) => Promise<PendingTransferReversalDto[]>;

  initiateServiceProviderPayment: (dataIn: {
    body: undefined;
    params: { receiptBatchId: string };
  }) => Promise<ServiceProviderPayment>;

  getServiceProviderPaymentForReceiptBatch: (dataIn: {
    params: {
      receiptBatchId: string;
    };
  }) => Promise<ServiceProviderPayment>;

  getReceiptBatchPaymentStatus: (dataIn: {
    params: {
      receiptBatchId: string;
    };
  }) => Promise<ReceiptBatchPaymentStatus>;

  getServiceProviderPayments: (dataIn: {
    params: ServiceProviderPaymentListQuery;
  }) => Promise<PaginatedResultSubset<ServiceProviderPayment>>;
}

export class PaymentApi implements IPaymentApi {
  public setup: IPaymentApi['setup'];
  public setupDirectDebitForNursingHome: IPaymentApi['setupDirectDebitForNursingHome'];
  public setupConfirm: IPaymentApi['setupConfirm'];
  public setupConfirmNursingHome: IPaymentApi['setupConfirmNursingHome'];
  public setupConfirmPaymentIntent: IPaymentApi['setupConfirmPaymentIntent'];
  public getStripeAccountDetails: IPaymentApi['getStripeAccountDetails'];
  public createStripeAccountForNursingHome: IPaymentApi['createStripeAccountForNursingHome'];
  public deleteDirectDebitMandate: IPaymentApi['deleteDirectDebitMandate'];
  public deleteDirectDebitMandateForNursingHome: IPaymentApi['deleteDirectDebitMandateForNursingHome'];
  public deletePendingChargeByResidentId: IPaymentApi['deletePendingChargeByResidentId'];
  public getPaymentInfoByResidentId: IPaymentApi['getPaymentInfoByResidentId'];
  public getPaymentInfoByResidentIdAsFamilyMember: IPaymentApi['getPaymentInfoByResidentIdAsFamilyMember'];
  public getDirectDebitStatusForNursingHome: IPaymentApi['getDirectDebitStatusForNursingHome'];

  public getAccountingDashboard: IPaymentApi['getAccountingDashboard'];
  public getAccountingResidentBalanceGraph: IPaymentApi['getAccountingResidentBalanceGraph'];

  public getAccountingPayouts: IPaymentApi['getAccountingPayouts'];
  public getAccountingPayments: IPaymentApi['getAccountingPayments'];
  public getPayoutReconciliation: IPaymentApi['getPayoutReconciliation'];
  public getMoneyFlow: IPaymentApi['getMoneyFlow'];

  public getTransfer: IPaymentApi['getTransfer'];

  public submitDirectDebitManualPayment: IPaymentApi['submitDirectDebitManualPayment'];

  public setPaymentInitiation: IPaymentApi['setPaymentInitiation'];

  public getResidentBalanceInfo: IPaymentApi['getResidentBalanceInfo'];
  public getPendingReversals: IPaymentApi['getPendingReversals'];

  public initiateServiceProviderPayment: IPaymentApi['initiateServiceProviderPayment'];
  public getServiceProviderPaymentForReceiptBatch: IPaymentApi['getServiceProviderPaymentForReceiptBatch'];
  public getReceiptBatchPaymentStatus: IPaymentApi['getReceiptBatchPaymentStatus'];
  public getServiceProviderPayments: IPaymentApi['getServiceProviderPayments'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getApiBaseUrl(tenantId, apiUrl);

    this.setup = modify<
      undefined,
      SetupIntentPayload,
      SetupIntentPayload,
      {
        residentId: string;
      }
    >({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsStripeSetup.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          params.residentId
        )}`,
    });

    this.setupDirectDebitForNursingHome = async (
      nursingHomeId: string,
      body: {
        email: string;
        name: string;
        iban: string;
      }
    ): Promise<SetupIntentPayload> => {
      const result = await superFetch(
        this.authContext,
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.nursingHomeDirectDebitSetup.replace(
          `:${endpoints.nursingHomeDirectDebitParamName}`,
          nursingHomeId
        )}`,
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(body),
        }
      );
      return result.json();
    };

    this.setupConfirm = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
      }
    >({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsDirectDebitConfirm.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          params.residentId
        )}`,
    });

    this.setupConfirmNursingHome = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        nursingHomeId: string;
        intentId: string;
      }
    >({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.nursingHomeDirectDebitSetupConfirm
          .replace(
            `:${endpoints.nursingHomeDirectDebitParamName}`,
            params.nursingHomeId
          )
          .replace(`:${endpoints.setupIntentIdParamName}`, params.intentId)}`,
    });

    this.setupConfirmPaymentIntent = modify<
      {
        paymentIntentId: string;
      },
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
      }
    >({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsDirectDebitPaymentConfirm.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          params.residentId
        )}`,
    });

    this.getStripeAccountDetails = get({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.paymentPaymentsGetStripeAccountByNursingHome.replace(
          ':nursingHomeId',
          params.nursingHomeId
        )}`,
    });

    this.createStripeAccountForNursingHome = modify({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.paymentPaymentsCreateStripeAccountForNursingHome.replace(
          ':nursingHomeId',
          params.nursingHomeId
        )}`,
      method: 'POST',
    });

    this.deleteDirectDebitMandate = async (residentId: string) => {
      await superFetch(
        this.authContext,
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsDirectDebitDelete.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          residentId
        )}`,
        { method: 'DELETE' }
      );
    };

    this.deleteDirectDebitMandateForNursingHome = async (
      nursingHomeId: string
    ) => {
      await superFetch(
        this.authContext,
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.deleteNursingHomeDirectDebitMandate.replace(
          `:${endpoints.nursingHomeDirectDebitParamName}`,
          nursingHomeId
        )}`,
        { method: 'DELETE' }
      );
    };

    this.deletePendingChargeByResidentId = async (
      residentId: string,
      notes: string
    ) => {
      await superFetch(
        this.authContext,
        `${baseUrl}/payment/pending-charges/${residentId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({ notes }),
        }
      );
    };

    this.getPaymentInfoByResidentId = get({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.paymentPaymentsGetResidentPaymentInfo.replace(
          `:${endpoints.paymentPaymentsResidentIdParamName}`,
          params.residentId
        )}`,
      transform: (data) => ({
        ...data,
        status: {
          ...data.status,
          chargeDate: data.status.chargeDate
            ? parseISO(data.status.chargeDate)
            : undefined,
        },
      }),
    });

    this.getPaymentInfoByResidentIdAsFamilyMember = get({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsGetPaymentInfo.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          params.residentId
        )}`,
      transform: (data) => ({
        ...data,
        status: {
          ...data.status,
          chargeDate: data.status.chargeDate
            ? parseISO(data.status.chargeDate)
            : undefined,
          until: data.status.until ? parseISO(data.status.until) : undefined,
        },
      }),
    });

    this.getDirectDebitStatusForNursingHome = get({
      authContext: this.authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.getNursingHomeDirectDebitStatus.replace(
          `:${endpoints.nursingHomeDirectDebitParamName}`,
          nursingHomeId
        )}`,
    });

    this.getAccountingDashboard = get({
      authContext: this.authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.paymentDashboard}/${endpoints.paymentDashboardGet}?nursingHomeId=${nursingHomeId}`,
    });

    this.getAccountingResidentBalanceGraph = get({
      authContext: this.authContext,
      url: ({ dateStart, dateEnd, nursingHomeId }) => {
        const queryParams = [];
        queryParams.push(`nursingHomeId=${nursingHomeId}`);
        if (dateStart) {
          queryParams.push(`dateStart=${dateStart}`);
        }
        if (dateEnd) {
          queryParams.push(`dateEnd=${dateEnd}`);
        }
        const paramsString =
          queryParams.length === 0 ? '' : `?${queryParams.join('&')}`;
        return `${baseUrl}/${endpoints.paymentDashboard}/${endpoints.paymentDashboardGetResidentsBalanceGraph}${paramsString}`;
      },
    });

    this.getAccountingPayouts = get({
      authContext: this.authContext,
      url: ({ nursingHomeId, page, pageSize, filters: { states } }) => {
        const queryParams = [];
        queryParams.push(`nursingHomeId=${nursingHomeId}`);
        queryParams.push(`page=${page}`);
        queryParams.push(`pageSize=${pageSize}`);

        if (states && states.length > 0) {
          states.forEach((state) => {
            queryParams.push(`states[]=${state}`);
          });
        }

        const paramsString =
          queryParams.length === 0 ? '' : `?${queryParams.join('&')}`;

        return `${baseUrl}/${endpoints.paymentPayouts}/${endpoints.paymentPayoutsGet}${paramsString}`;
      },
    });

    this.getAccountingPayments = get({
      authContext: this.authContext,
      // eslint-disable-next-line complexity
      url: ({
        nursingHomeId,
        page,
        pageSize,
        filters: { states, dateEnd, dateFrom },
      }) => {
        const queryParams = [];
        queryParams.push(`nursingHomeId=${nursingHomeId}`);
        queryParams.push(`page=${page}`);
        queryParams.push(`pageSize=${pageSize}`);

        if (states && states.length > 0) {
          states.forEach((state) => {
            queryParams.push(`states[]=${state}`);
          });
        }
        if (dateFrom) {
          queryParams.push(`dateFrom=${dateFrom.toISOString()}`);
        }
        if (dateEnd) {
          queryParams.push(`dateEnd=${dateEnd.toISOString()}`);
        }

        const paramsString =
          queryParams.length === 0 ? '' : `?${queryParams.join('&')}`;

        return `${baseUrl}/${endpoints.paymentPayments}/${endpoints.paymentPaymentsGet}${paramsString}`;
      },
    });

    this.getMoneyFlow = get({
      authContext: this.authContext,
      url: ({ nursingHomeIds, type, dateRange }) => {
        const queryParams = new URLSearchParams();

        if (type) {
          queryParams.append('type', type);
        }

        nursingHomeIds.forEach((nursingHomeId) => {
          queryParams.append('nursingHomeIds[]', nursingHomeId);
        });

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const dateRangeField = dateRange?.[field];
          if (dateRangeField) {
            queryParams.append(
              `dateRange[${field}]`,
              dateRangeField.toISOString()
            );
          }
        });

        return `${baseUrl}/${endpoints.paymentPayments}/${
          endpoints.paymentMoneyFlowGet
        }?${queryParams.toString()}`;
      },
    });

    this.getPayoutReconciliation = get({
      authContext: this.authContext,
      url: ({ payoutId }) =>
        `${baseUrl}/${
          endpoints.paymentPayouts
        }/${endpoints.paymentPayoutsGetReconciliation.replace(
          ':payoutId',
          payoutId
        )}`,
    });

    this.getTransfer = get({
      authContext: this.authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.paymentTransfer}/${endpoints.paymentTransferGet}?nursingHomeId=${nursingHomeId}`,
    });

    this.submitDirectDebitManualPayment = async (
      residentId: string,
      payment: FamilyMemberInitiatedPaymentDto
    ) => {
      const result = await superFetch(
        this.authContext,
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsDirectDebitPayment.replace(
          `:${endpoints.familyMemberPaymentsResidentIdParamName}`,
          residentId
        )}`,
        {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(payment),
        }
      );
      return result.json();
    };

    this.setPaymentInitiation = modify<
      PaymentInitiationDto,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
        familyMemberId: string;
      }
    >({
      authContext: this.authContext,
      url: (params) =>
        `${baseUrl}/${
          endpoints.paymentPayments
        }/${endpoints.paymentPaymentsSetPaymentInitiation
          .replace(
            `:${endpoints.paymentPaymentsResidentIdParamName}`,
            params.residentId
          )
          .replace(
            `:${endpoints.paymentPaymentsFamilyMemberIdParamName}`,
            params.familyMemberId
          )}`,
      method: 'PUT',
    });

    this.getResidentBalanceInfo = get({
      authContext: this.authContext,
      url: ({ residentId }) =>
        `${baseUrl}/${
          endpoints.familyMemberPayments
        }/${endpoints.familyMemberPaymentsGetBalanceInfo.replace(
          ':residentId',
          residentId
        )}`,
    });

    this.getPendingReversals = get({
      authContext: this.authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.paymentPayments}/${endpoints.paymentPendingReversalsGet}?nursingHomeId=${nursingHomeId}`,
    });

    this.initiateServiceProviderPayment = modify({
      authContext: this.authContext,
      url: ({ receiptBatchId }) =>
        `${baseUrl}/${
          endpoints.serviceProviderPayments
        }/${endpoints.serviceProviderPaymentsPostForReceiptBatch.replace(
          ':receiptBatchId',
          receiptBatchId
        )}`,
      method: 'POST',
    });

    this.getServiceProviderPaymentForReceiptBatch = get({
      authContext: this.authContext,
      url: ({ receiptBatchId }) =>
        `${baseUrl}/${
          endpoints.serviceProviderPayments
        }/${endpoints.serviceProviderPaymentsGetByReceiptBatch.replace(
          ':receiptBatchId',
          receiptBatchId
        )}`,
    });

    this.getReceiptBatchPaymentStatus = get({
      authContext: this.authContext,
      url: ({ receiptBatchId }) =>
        `${baseUrl}/${
          endpoints.serviceProviderPayments
        }/${endpoints.serviceProviderPaymentsGetPaymentStatusByReceiptBatch.replace(
          ':receiptBatchId',
          receiptBatchId
        )}`,
    });

    this.getServiceProviderPayments = get({
      authContext: this.authContext,
      // eslint-disable-next-line complexity
      url: ({
        nursingHomeId,
        page,
        pageSize,
        globalServiceProviderId,
        sortBy,
        sortOrder,
        completedAt,
        states,
        created,
      }) => {
        const queryParams = new URLSearchParams();
        queryParams.append('nursingHomeId', nursingHomeId);
        queryParams.append('page', page.toString());
        queryParams.append('pageSize', pageSize.toString());

        if (states && states.length > 0) {
          states.forEach((state) => {
            queryParams.append('states[]', state);
          });
        }

        if (globalServiceProviderId) {
          queryParams.append(
            'globalServiceProviderId',
            globalServiceProviderId
          );
        }

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const createdField = created?.[field];
          if (createdField) {
            queryParams.append(`created[${field}]`, createdField.toISOString());
          }

          const completedField = completedAt?.[field];
          if (completedField) {
            queryParams.append(
              `completedAt[${field}]`,
              completedField.toISOString()
            );
          }
        });

        if (sortBy) {
          queryParams.append('sortBy', sortBy);
        }

        if (sortOrder) {
          queryParams.append('sortOrder', sortOrder);
        }

        return `${baseUrl}/${endpoints.serviceProviderPayments}/${
          endpoints.serviceProviderPaymentsGet
        }?${queryParams.toString()}`;
      },
    });
  }
}
