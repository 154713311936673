import {
  IsDate,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  ValidateNested,
} from 'class-validator';

class CashTransactionDto {
  @IsOptional()
  @IsInt({ each: true })
  coins?: number[];

  @IsOptional()
  @IsString()
  notes?: string;

  @IsOptional()
  @IsInt()
  bankAccountAmount?: number;

  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string;
}

export class SettlementFormDataDto {
  @IsString()
  @IsOptional()
  /** @deprecated feature was removed in PNA-3499 */
  recipient_name?: string;

  /** @deprecated Exists for backwards compatibility */
  @IsNumber()
  @IsOptional()
  amount?: number;

  @IsInt()
  @IsOptional()
  amountInCent?: number;

  @IsOptional()
  @ValidateNested()
  cashTransaction?: CashTransactionDto;

  @IsString()
  @IsOptional()
  /** @deprecated feature was removed in PNA-3499 */
  receipt_image_id?: string;
  @IsString()
  @IsOptional()
  /** @deprecated feature was removed in PNA-3499 */
  iban?: string;
  @IsDate()
  @IsOptional()
  date?: Date;
  @IsString()
  @IsOptional()
  note?: string;
}
