import { isSameDay, isSameMonth } from 'date-fns';
import {
  NUMERICMONTH_DAY_FORMAT,
  YEAR_MONTH_DAY_SHORT_FORMAT,
  YEAR_MONTH_FORMAT,
} from '@pflegenavi/shared/constants';
import { useFormatDate } from '@pflegenavi/frontend/localization';

export const useGetBatchReceiptDateString = (): {
  getBatchReceiptDateString: (
    useIndividualDates: boolean,
    minReceiptDateOriginal: Date | undefined,
    maxReceiptDateOriginal: Date | undefined,
    receiptDate: Date | undefined
  ) => string;
} => {
  const fDate = useFormatDate();

  // eslint-disable-next-line complexity
  const getBatchReceiptDateString = (
    useIndividualDates: boolean,
    minReceiptDateOriginal: Date | undefined,
    maxReceiptDateOriginal: Date | undefined,
    receiptDate: Date | undefined
  ): string => {
    if (useIndividualDates) {
      const minReceiptDate = minReceiptDateOriginal
        ? minReceiptDateOriginal
        : receiptDate ?? new Date();
      const maxReceiptDate = maxReceiptDateOriginal
        ? maxReceiptDateOriginal
        : receiptDate ?? new Date();
      const sameMonth = isSameMonth(minReceiptDate, maxReceiptDate);
      if (isSameDay(minReceiptDate, maxReceiptDate)) {
        return fDate(minReceiptDate, YEAR_MONTH_DAY_SHORT_FORMAT);
      }
      if (sameMonth) {
        return `${fDate(minReceiptDate, YEAR_MONTH_FORMAT)}`;
      }
      return `${fDate(minReceiptDate, NUMERICMONTH_DAY_FORMAT)} - ${fDate(
        maxReceiptDate,
        NUMERICMONTH_DAY_FORMAT
      )}`;
    }
    if (!receiptDate) {
      return '--';
    }
    return fDate(receiptDate, YEAR_MONTH_DAY_SHORT_FORMAT);
  };

  return {
    getBatchReceiptDateString,
  };
};
