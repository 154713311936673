import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type {
  AddEmployee,
  Employee,
  UpdateEmployee,
} from '@pflegenavi/shared/api';

export interface GetEmployeesPhoenixResponse {
  meta: {
    total_count: number;
  };
  data: Employee[];
}

export interface IEmployeesPhoenixApi extends Api {
  getNursingHomeEmployees(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<GetEmployeesPhoenixResponse>;

  addEmployee(dataIn: {
    params: {
      nursingHomeId: string;
    };
    body: AddEmployee;
  }): Promise<{
    data: Employee;
  }>;

  updateEmployee(dataIn: {
    params: {
      employeeId: string;
      nursingHomeId: string;
    };
    body: UpdateEmployee;
  }): Promise<{
    data: Employee;
  }>;

  resentWelcomeEmail(dataIn: {
    params: {
      employeeId: string;
      nursingHomeId: string;
    };
  }): Promise<{
    success: boolean;
  }>;
}

export class EmployeesPhoenixApi implements IEmployeesPhoenixApi {
  baseUrl: string;

  public getNursingHomeEmployees: IEmployeesPhoenixApi['getNursingHomeEmployees'];
  public addEmployee: IEmployeesPhoenixApi['addEmployee'];
  public updateEmployee: IEmployeesPhoenixApi['updateEmployee'];
  public resentWelcomeEmail: IEmployeesPhoenixApi['resentWelcomeEmail'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getNursingHomeEmployees = get({
      authContext,
      url: ({ nursingHomeId }) =>
        `${this.baseUrl}/nursing-home/${nursingHomeId}/employee`,
    });

    this.addEmployee = modify({
      authContext,
      url: ({ nursingHomeId }) =>
        `${this.baseUrl}/nursing-home/${nursingHomeId}/employee`,
      method: 'POST',
    });

    this.updateEmployee = modify({
      authContext,
      url: ({ employeeId, nursingHomeId }) =>
        `${this.baseUrl}/nursing-home/${nursingHomeId}/employee/${employeeId}`,
      method: 'PATCH',
    });

    this.resentWelcomeEmail = modify({
      authContext,
      url: ({ employeeId, nursingHomeId }) =>
        `${this.baseUrl}/nursing-home/${nursingHomeId}/employee/${employeeId}/resend_welcome`,
      method: 'POST',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }
}
