export { fmAppMainPages } from './lib/fmAppMainPages';
export { nhAppMainPages } from './lib/nhAppMainPages';
export { spAppMainPages } from './lib/spAppMainPages';
import { generatePath as generatePathOriginal } from 'react-router';

export type ExtractRouteOptionalParam<
  T extends string,
  U = string | number | boolean
> = T extends `${infer Param}?`
  ? { [k in Param]?: U }
  : T extends `${infer Param}*`
  ? { [k in Param]?: U }
  : T extends `${infer Param}+`
  ? { [k in Param]: U }
  : { [k in T]: U };

export type ExtractRouteParams<
  T extends string,
  U = string | number | boolean
> = string extends T
  ? { [k in string]?: U }
  : T extends `${infer // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _Start}:${infer ParamWithOptionalRegExp}/${infer Rest}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _RegExp})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> &
        ExtractRouteParams<Rest, U>
  : T extends `${infer // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _Start}:${infer ParamWithOptionalRegExp}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _RegExp})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
  : Record<never, never>;

export function generatePath<S extends string>(
  path: S,
  params?: ExtractRouteParams<S>
): string {
  return generatePathOriginal(path, params as never);
}
