import {
  IsDate,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsUUID,
} from 'class-validator';
import { CashTransactionGroupType } from './CashTransactionGroupType';
import type {
  TransactionInitiatedBy,
  TransactionSourceType,
  TransactionType,
} from '../transaction';
import type { CashManagementTransactionType } from './CashManagementTransactionType';
import type { Coin } from './GetCashManagementDto';

export class GetCashTransactionGroupDto {
  @IsNotEmpty()
  @IsUUID(4)
  groupId!: string;
}

export interface GetCashTransactionGroupTransactions {
  id: string;
  initiator?: {
    id: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
  };
  amount: number;
  type: TransactionType;
  sourceType: TransactionSourceType;
  receiptType?: string;
  resident?: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
  notes?: string;
  date: Date;
  initiatedBy: TransactionInitiatedBy;
  additionalData: Record<string, string>;
}

export interface GetCashTransactionGroupCashTransactions {
  id: string;
  cashListId: string;
  type: CashManagementTransactionType;
  resident?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  amount: number;
  date: Date;
  employee?: {
    id: string;
    firstName?: string;
    lastName?: string;
  };
  status: 'confirmed';
  notes: string;
  coinChange?: Coin[];
  bankAccountAmountChange?: number;
}

export class GetCashTransactionGroupResultDto {
  @IsNotEmpty()
  @IsEnum(CashTransactionGroupType)
  type!: CashTransactionGroupType;
  @IsNotEmpty()
  @IsUUID(4)
  id!: string;
  // TODO: Add support to fetch cashListId from backend
  @IsNotEmpty()
  @IsUUID(4)
  cashListId!: string;
  @IsNotEmpty()
  @IsDate()
  updateDate!: Date;
  @IsNotEmpty()
  @IsNumber()
  cashChanged!: number;
  @IsNotEmpty()
  @IsNumber()
  amountLinked!: number;
  @IsNotEmpty()
  @IsNumber()
  linkCount!: number;
  @IsNotEmpty()
  @IsInt()
  receiptLinkCount!: number;
  @IsNotEmpty()
  @IsInt()
  transactionLinkCount!: number;
  @IsNotEmpty()
  @IsInt()
  payoutLinkCount!: number;
  @IsNotEmpty()
  @IsInt()
  paymentIntentLinkCount!: number;
  @IsNotEmpty()
  @IsInt()
  cashListTransactionCount!: number;

  @IsNotEmpty()
  @IsNumber()
  cashTransfers!: number;

  @IsOptional()
  transactions?: GetCashTransactionGroupTransactions[];

  @IsOptional()
  cashListTransactions?: GetCashTransactionGroupCashTransactions[];

  @IsOptional()
  @IsDate()
  mismatchConfirmationDate?: Date;
}
