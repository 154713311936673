import { IsNotEmpty, IsNumber, IsPositive } from 'class-validator';

export interface SetupIntentPayload {
  intentId: string;
  paymentIntent: string;
  ephemeralKey?: string;
  customer: string;
  publishableKey: string;
}

export class FamilyMemberInitiatedPaymentDto {
  @IsNumber()
  @IsPositive()
  @IsNotEmpty()
  amount!: number;
}
