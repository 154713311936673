import {
  IsEmail,
  IsOptional,
  IsString,
  IsUUID,
  ValidateIf,
} from 'class-validator';
import { MaxLengthWithTrim, MinLengthWithTrim } from './helpers';
import { Transform } from 'class-transformer';

export class PatchServiceProviderDto {
  @IsOptional()
  @IsString()
  @MaxLengthWithTrim(255)
  @MinLengthWithTrim(1)
  @Transform(({ value }) => value?.trim())
  name?: string;
  @IsOptional()
  @IsUUID(4)
  receiptTypeId?: string;
  @IsOptional()
  @ValidateIf((object, value) => value !== null)
  @IsEmail()
  eMail?: string | null;
}
