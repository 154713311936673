import { CountryDto } from './country';
import {
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class RecurringItemTaxCodeDto {
  @IsUUID()
  id!: string;

  @IsString()
  tax_name!: string;

  @IsString()
  tax_amount!: string;

  @IsOptional()
  @IsString()
  tax_abbreviation?: string;

  @IsBoolean()
  display_explanation!: boolean;

  @IsOptional()
  @IsString()
  paragraph?: string;

  @IsOptional()
  @IsString()
  explanation_short?: string | null;

  @IsString()
  explanation_long!: string;

  @IsNotEmpty()
  country!: CountryDto;
}
