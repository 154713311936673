import { IsDate, IsEnum, IsNotEmpty, IsString, IsUUID } from 'class-validator';
import { Gender } from '../resident/';

export class GetServiceProviderResidentsResultDto {
  @IsUUID(4)
  @IsNotEmpty()
  id!: string;
  @IsNotEmpty()
  @IsString()
  firstName!: string;
  @IsNotEmpty()
  @IsString()
  lastName!: string;
  @IsNotEmpty()
  @IsEnum(Gender)
  gender!: Gender;
  @IsNotEmpty()
  @IsDate()
  createdOn!: Date;
}
